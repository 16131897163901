import { useParams, useNavigate } from "react-router-dom";
import IdHash from "@yag/id-hash";
import { getAPI } from "../api/getApi";
import { CircularProgress, Fade } from "@mui/material";
// import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";

export const decodeJWT = (token) => {
  try {
    const decodedToken = jwt_decode(token);
    return decodedToken;
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
};

export default function UrlToken() {
  const hasher = new IdHash();
  const { wtoken } = useParams();
  const nagivate = useNavigate();

  const decoded = hasher.decode(wtoken);
  const token = localStorage.getItem("token");

  const decodeToken =
    (token &&
      token !== "undefined" &&
      decodeJWT(token)["https://hasura.io/jwt/claims"].phone) ||
    "";
  const user_id =
    (token &&
      token !== "undefined" &&
      decodeJWT(token)["https://hasura.io/jwt/claims"]["x-hasura-user-id"]) ||
    "";

  const fetchData = async () => {
    const url =
      "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-4cc9a942-879d-47d3-b045-67c665da1adb/coupons/token";
    const variables = { phone: decoded.toString() };
    const headers = {
      "Content-Type": "application/json",
    };
    const result = await getAPI(url, variables, headers);
    if (result && result?.data?.token) {
      localStorage.setItem("token", result?.data?.token);
      const id = decodeJWT(result?.data?.token)["https://hasura.io/jwt/claims"][
        "x-hasura-user-id"
      ];
      tiggerEvent(id);
      nagivate("/home", { state: { reload: true } });
    } else {
      nagivate("*");
    }
  };

  if (decodeToken !== decoded) {
    fetchData();
  }

  const tiggerEvent = (id) => {
    const url =
      "https://6j8wmnnyoa.execute-api.ap-south-1.amazonaws.com/UserLog";
    const variables = {
      user_id: id,
      action: "WALLET_LOGIN",
    };
    getAPI(url, variables);
  };

  useEffect(() => {
    if (decodeToken == decoded) {
      tiggerEvent(user_id);
      nagivate("/home", { state: { reload: true } });
    }
  }, []);

  return (
    <>
      <div>
        <Fade in>
          <div
            style={{
              width: "100%",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </Fade>{" "}
      </div>
    </>
  );
}
