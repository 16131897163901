export const citiesInIndia = [
    "Mumbai",
    "Delhi",
    "Bangalore",
    "Hyderabad",
    "Ahmedabad",
    "Chennai",
    "Kolkata",
    "Surat",
    "Pune",
    "Jaipur",
    "Lucknow",
    "Kanpur",
    "Nagpur",
    "Indore",
    "Thane",
    "Bhopal",
    "Visakhapatnam",
    "Pimpri-Chinchwad",
    "Patna",
    "Vadodara",
    "Ghaziabad",
    "Ludhiana",
    "Agra",
    "Nashik",
    "Faridabad",
    "Meerut",
    "Rajkot",
    "Varanasi",
    "Srinagar",
    "Aurangabad",
    "Dhanbad",
    "Amritsar",
    "Navi Mumbai",
    "Allahabad",
    "Howrah",
    "Ranchi",
    "Gwalior",
    "Jabalpur",
    "Coimbatore",
    "Vijayawada",
    "Jodhpur",
    "Madurai",
    "Raipur",
    "Kota",
    "Chandigarh",
    "Guwahati",
    "Solapur",
    "Hubli-Dharwad",
    "Mysore",
    "Tiruchirappalli",
    "Bareilly",
    "Aligarh",
    "Tiruppur",
    "Gurgaon",
    "Moradabad",
    "Jalandhar",
    "Bhubaneswar",
    "Salem",
    "Warangal",
    "Guntur",
    "Bhiwandi",
    "Saharanpur",
    "Gorakhpur",
    "Bikaner",
    "Amravati",
    "Noida",
    "Jamshedpur",
    "Bhilai",
    "Cuttack",
    "Firozabad",
    "Kochi",
    "Nellore",
    "Bhavnagar",
    "Dehradun",
    "Durgapur",
    "Asansol",
    "Rourkela",
    "Nanded",
    "Kolhapur",
    "Ajmer",
    "Akola",
    "Gulbarga",
    "Jamnagar",
    "Ujjain",
    "Loni",
    "Siliguri",
    "Jhansi",
    "Ulhasnagar",
    "Jammu",
    "Sangli-Miraj & Kupwad",
    "Mangalore",
    "Erode",
    "Belgaum",
    "Kurnool",
    "Ambattur",
    "Tirunelveli",
    "Malegaon",
    "Gaya",
    "Jalgaon",
    "Udaipur",
    "Maheshtala",
    "Davanagere",
    "Kozhikode",
    "Akbarpur",
    "Kurnool",
    "Rajpur Sonarpur",
    "Bokaro",
    "South Dumdum",
    "Bellary",
    "Patiala",
    "Gopalpur",
    "Agartala",
    "Bhagalpur",
    "Muzaffarnagar",
    "Bhatpara",
    "Panihati",
    "Latur",
    "Dhule",
    "Rohtak",
    "Korba",
    "Bhilwara",
    "Brahmapur",
    "Mathura",
    "Muzaffarpur",
    "Patiala",
    "Darbhanga",
    "Kakinada",
    "Ozhukarai",
    "Baramula",
    "Bijapur",
    "Bihar Sharif",
    "Panipat",
    "Darjeeling",
    "Itanagar",
    "Kashipur",
    "Kavali",
    "Shimoga",
    "Abohar",
    "Adoni",
    "Alappuzha",
    "Bahraich",
    "Bhimavaram",
    "Bhimavaram",
    "Bhind",
    "Bhiwani",
    "Bhusawal",
    "Bihar Sharif",
    "Bijapur",
    "Bikaner",
    "Bodhan",
    "Bulandshahr",
    "Burhanpur",
    "Buxar",
    "Chandannagar",
    "Chapra",
    "Chittaurgarh",
    "Chittoor",
    "Dewas",
    "Dibrugarh",
    "Dindigul",
    "Durg",
    "Erode",
    "Etawah",
    "Fatehpur",
    "Faizabad",
    "Farrukhabad",
    "Fatehabad",
    "Gandhidham",
    "Gangapur",
    "Gangawati",
    "Gonda",
    "Guntakal",
    "Gurdaspur",
    "Haldia",
    "Haldwani",
    "Hazaribagh",
    "Hindupur",
    "Hooghly",
    "Hoshiarpur",
    "Hosur",
    "Howrah",
    "Jabalpur",
    "Jagdalpur",
    "Jaisalmer",
    "Jalandhar",
    "Jalna",
    "Jalpaiguri",
    "Jhunjhunu",
    "Jind",
    "Junagadh",
    "Kadapa",
    "Kalyan-Dombivli",
    "Kanchipuram",
    "Kanpur",
    "Karawal Nagar",
    "Karimnagar",
    "Khammam",
    "Khandwa",
    "Kharagpur",
    "Khopoli",
    "Kirari Suleman Nagar",
    "Kishanganj",
    "Kishangarh",
    "Kollam",
    "Kotdwara",
    "Kottayam",
    "Kozhikode",
    "Kumbakonam",
    "Kurukshetra",
    "Lakhimpur",
    "Lalitpur",
    "Latur",
    "Loni",
    "Lucknow",
    "Ludhiana",
    "Madanapalle",
    "Madhyamgram",
    "Madikeri",
    "Mahbubnagar",
    "Mahuva",
    "Malda",
    "Malerkotla",
    "Malkajgiri",
    "Mandya",
    "Mangalore",
    "Mango",
    "Mathura",
    "Medinipur",
    "Meerut",
    "Mehsana",
    "Mettupalayam",
    "Miryalaguda",
    "Mirzapur",
    "Modinagar",
    "Moga",
    "Morena",
    "Motihari",
    "Munger",
    "Muzaffarnagar",
    "Muzaffarpur",
    "Nabadwip",
    "Nadiad",
    "Nagaon",
    "Nagercoil",
    "Nagpur",
    "Naihati",
    "Nalgonda",
    "Nandyal",
    "Narasaraopet",
    "Nashik",
    "Navsari",
    "Nellore",
    "New Delhi",
    "Nizamabad",
    "Noida",
    "North Dumdum",
    "Ongole",
    "Orai",
    "Ozhukarai",
    "Pali",
    "Palakkad",
    "Palghar",
    "Pallavaram",
    "Palwal",
    "Panchkula",
    "Panihati",
    "Panvel",
    "Parbhani",
    "Patan",
    "Pilibhit",
    "Porbandar",
    "Puducherry",
    "Pune",
    "Puri",
    "Purnia",
    "Raebareli",
    "Raiganj",
    "Raipur",
    "Rajahmundry",
    "Rajapalayam",
    "Rajkot",
    "Rajpur Sonarpur",
    "Ramagundam",
    "Ramgarh",
    "Rampur",
    "Ranchi",
    "Ratlam",
    "Rewa",
    "Rewari",
    "Rohtak",
    "Roorkee",
    "Rourkela",
    "Saharsa",
    "Sambalpur",
    "Sambhal",
    "Sangli",
    "Sasaram",
    "Satara",
    "Satna",
    "Secunderabad",
    "Serampore",
    "Shahjahanpur",
    "Shimla",
    "Shivpuri",
    "Shimoga",
    "Shrirampur",
    "Silchar",
    "Siliguri",
    "Singrauli",
    "Sirsa",
    "Siwan",
    "Solapur",
    "Sonepat",
    "Sonipat",
    "Srikakulam",
    "Srinagar",
    "Sultan Pur Majra",
    "Surat",
    "Surendranagar",
    "Tadepalligudem",
    "Tadipatri",
    "Tenali",
    "Thanjavur",
    "Thiruvananthapuram",
    "Thrissur",
    "Tinsukia",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tirupati",
    "Tirupur",
    "Tiruvannamalai",
    "Titagarh",
    "Tonk",
    "Tumkur",
    "Udaipur",
    "Udgir",
    "Udupi",
    "Ujjain",
    "Unnao",
    "Vadodara",
    "Valsad",
    "Vasai-Virar",
    "Vellore",
    "Veraval",
    "Vidisha",
    "Vijayawada",
    "Visakhapatnam",
    "Vizianagaram",
    "Warangal",
    "Wardha",
    "Yamunanagar",
    "Yavatmal"
  ];