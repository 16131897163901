import axios from "axios";

export const getAPI=async(url, variables, headers)=>{
 const result= await axios.post(url, variables,  {
    headers:headers
  })
  .then((resp) => {
      return resp
  })
  .catch((error) => {
      return error
  })
  return result
}