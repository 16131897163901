import { createElement, useState } from "react";
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import {
    SurveyQuestionElementBase,
    ReactQuestionFactory
} from "survey-react-ui";
import ReactDOMServer from "react-dom/server";
import { TextField } from "@mui/material";

const CUSTOM_TYPE = "coupons_input";

// A model for the new question type
export class QuestionCouponsInputModel extends Question {
    getType() {
        return CUSTOM_TYPE;
    }
    //   get colorPickerType() {
    //     return this.getPropertyValue("colorPickerType");
    //   }
    //   set colorPickerType(val) {
    //     this.setPropertyValue("colorPickerType", val);
    //   }

    //   get disableAlpha() {
    //     return this.getPropertyValue("disableAlpha");
    //   }
    //   set disableAlpha(val) {
    //     this.setPropertyValue("disableAlpha", val);
    //   }
}

// Register `QuestionColorPickerModel` as a model for the `color-picker` type
export function registerCouponsInput() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
        return new QuestionCouponsInputModel(name);
    });
}

// Specify display names for the question type and its properties
// const locale = localization.getLocale("");
// locale.qt[CUSTOM_TYPE] = "CouponsInput";
// locale.pe.colorPickerType = "Color picker type";
// locale.pe.disableAlpha = "Disable alpha channel";

// Register an SVG icon for the question type
const svg = ReactDOMServer.renderToString();
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, svg);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
    CUSTOM_TYPE,
    [
        // {
        //   name: "colorPickerType",
        //   default: "Slider",
        //   choices: ["Slider", "Sketch", "Compact"],
        //   category: "general",
        //   visibleIndex: 2 // After the Name and Title
        // },
        // {
        //   name: "disableAlpha:boolean",
        //   dependsOn: "colorPickerType",
        //   visibleIf: function (obj) {
        //     return obj.colorPickerType === "Sketch";
        //   },
        //   category: "general",
        //   visibleIndex: 3 // After the Name, Title, and Color Picker type
        // }
    ],
    function () {
        return new QuestionCouponsInputModel("");
    },
    "question"
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionCouponsInput extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        this.state = { value: this.question.value };
        console.log(props, 'props');
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question.value;
    }
    get disableAlpha() {
        return this.question.disableAlpha;
    }
    get type() {
        return this.question.colorPickerType;
    }
    handleColorChange = (data) => {
        this.question.value = data;
    };

    // Support the read-only and design modes
    get style() {
        return this.question.getPropertyValue("readOnly") ||
            this.question.isDesignMode
            ? { pointerEvents: "none" }
            : undefined;
    }

    renderElement() {
        return <CouponsInput handleChange={this.handleColorChange} value={this.question.value} />
    }
}

// Register `SurveyQuestionColorPicker` as a class that renders `color-picker` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return createElement(SurveyQuestionCouponsInput, props);
});

// Register the `color-picker` as an editor for properties of the `color` type in the Survey Creator's Property Grid
// PropertyGridEditorCollection.register({
//     fit: function (prop) {
//         return prop.type === "color";
//     },
//     getJSON: function () {
//         return {
//             type: CUSTOM_TYPE,
//             colorPickerType: "Compact"
//         };
//     }
// });

export function CouponsInput({ handleChange, value }) {

    return (
        <div
            style={{
                display: "flex",
                fontSize: "300%",
                width: "100%",
                height: "100%",
                marginTop: 5
            }}
        >
            <TextField
                sx={{ width: '100%', backgroundColor: "#fff" }}
                onChange={(e) => {
                    if (value?.includes('#')) {
                        handleChange(e.target.value)
                    } else {
                        handleChange('#' + e.target.value)
                    }
                }}
                value={value}
            />
        </div >
    )
}