import { gql } from "@apollo/client";

const userRespQuery = gql`
query UserSurveyResponse($phone_number: String = "", $_or: [survey_responses_bool_exp!] = {}) {
    survey_responses(where: {user: {phone_number: {_eq: $phone_number}}, _or: $_or}, order_by: {id: desc}) {
      response_details
     survey_id
}
  }`;

async function getUserUserSurveyResp(client, token, prefillData, phNO) {
    const { data, loading, error } = await client.query({
        context: {
            headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
            },
        },
        query: userRespQuery,
        variables: {
            "phone_number": phNO,
            "_or": prefillData

        },
    });
    if (error) {
        return error;
    }
    return {
        data: data?.survey_responses,
        isLoading: loading,
    };
}

export default getUserUserSurveyResp;
