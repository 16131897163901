import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ProgressBar from "./ProgressBar";

function RedirectDailog({modelOpen, toggleOpen, onSubmit }) {

    return (
        <Dialog
            open={modelOpen}
            // onClose={() => { toggleOpen(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                title
            </DialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <div>
                    Thank you for your valuable feedback.
                    Please rate us in google too...
                </div><br />
                <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                    <ProgressBar />
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={onSubmit} className="skip">
                    skip
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default RedirectDailog;