import { Button, Stack, Fade, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import OtpInput from "react18-otp-input";
import { ChevronRightRounded } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import Countdown from "react-countdown";
import axios from "axios";

const MinuteCounter = ({ onReset }) => {
  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const [isRunning, setIsRunning] = useState(true);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (isRunning && secondsRemaining > 0) {
      const interval = setInterval(() => {
        setSecondsRemaining(prevSeconds => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
    if (secondsRemaining === 0) {
      setVisible(false)
    }
  }, [isRunning, secondsRemaining]);

  const handleRestart = () => {
    onReset()
    setSecondsRemaining(60);
    setIsRunning(true);
    setVisible(true)
  };

  return (
    <div>
      {visible ?
        <p>{Math.floor(secondsRemaining / 60)}:{(secondsRemaining % 60).toString().padStart(2, '0')}</p>
        :
        <button className="resend-btn" variant="text" onClick={handleRestart}>
          Resend OTP
        </button>
      }

    </div>
  );
};

function Otp({ onSubmit, modeOpen, error, phNumber, userdetails }) {
  const [resetCounter, SetResetCounter] = useState(0);
  const [otpError, SetOtpError] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpValid, SetOtpValid] = useState(false);
  const [otpLoading, SetOtpLoading] = useState(false);
  const pathURL = process.env.REACT_APP_ENDPOINT;
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location.state.url,'url')

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <button className="resend-btn" variant="text" onClick={resendOTP}>
          Resend OTP
        </button>
      );
    } else {
      // Render a countdown
      return <span>{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span>;
    }
  };

  function handleOtpChange(val) {
    setOtp(val);
    SetOtpError(false);
    if (/^\d{4}$/.test(val)) {
      SetOtpValid(true);
    } else {
      SetOtpValid(false);
    }
  }

  const resendOTP = () => {
    let rc = resetCounter;
    rc += 1;
    SetResetCounter(rc);

    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    let data = JSON.stringify({
      phone_number: phNumber || location.state.phone_number,
      qr_id: 1,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/get-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        const result = response.data
        userdetails({
          otp_id: result.insert_otp.id,
          user_id: result.user.id
        })
        // localStorage.setItem("otp_id",response.data.insert_otp.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // function CheckOTP(otp) {
  //   const formData = new FormData();
  //   formData.append("phone_number", location.state.phone_number);
  //   formData.append("login_otp", otp);

  //   axios.post(pathURL + "/verify-otp", formData).then(
  //     (response) => {
  //       console.log(response.data);
  //       let res = response.data;
  //       // let d = new Date();
  //       // d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));

  //       if (res.MESSAGE.success === "true") {
  //         if (res.MESSAGE.parameters.access_token) {
  //           localStorage.setItem("token", res.MESSAGE.parameters.access_token);
  //         }
  //         if (res.MESSAGE.parameters.phone_number) {
  //           localStorage.setItem(
  //             "phone_number",
  //             res.MESSAGE.parameters.phone_number
  //           );
  //         }

  //         if (res.MESSAGE.parameters.gender) {
  //           localStorage.setItem("user_name", res.MESSAGE.parameters.user_name);

  //           navigate("/user-home");
  //         } else {
  //           navigate("/user-signup");
  //         }
  //       } else {
  //         SetOtpError(true);
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //       SetOtpError(true);
  //     }
  //   );
  // }

  function verifyOTP(otp) {
    SetOtpLoading(true);
    let data = JSON.stringify({
      otp: otp,
      id: location.state.otp_id,
      user_id: location.state.user_id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/verify-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        SetOtpLoading(false);
      //  console.log(response.data, 'result');
        if (response.data.status) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user_id", location.state.user_id);
          if (
            response.data.user
            // response.data.user.gender &&
            // response.data.user.name
          ) {
            if (location.state.url !== '') {
              navigate(location.state.url)
            } else {
              navigate("/home");
            }
          } else {
            if (location.state.url !== '') {
              navigate(location.state.url)
            } else {
              navigate("/user-signup");
            }
          }
        } else {
          SetOtpError(true);
        }
      })
      .catch(function (error) {
        SetOtpLoading(false);
        SetOtpError(true);
        console.log(error);
      });
  }

  return (
    <div>
      {otpLoading ? (
        <Fade in>
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </Fade>
      ) : (
        <div style={{ marginTop: '-30px', paddingLeft: '20px', paddingRight: '20px' }} className={otpError ? "otp-container wrong" : "otp-container"}>
          <Stack alignItems={"center"}>
            <Stack alignItems={"start"} sx={{ minWidth: '300px', width: '50%' }} >
              <img src='/assets/arrow-left.png' height={32} width={32} onClick={()=>{
                if(modeOpen){
                  modeOpen()
                }else{
                  navigate('/')
                }
              }} />
              <div className="otp-text" style={{ marginBottom: "2em !important" }}>
                Please enter your OTP
              </div>
              <div className="otp-subtext" >
               {location.state && location.state.phone_number ? ` Please enter the OTP which, we have sent to XXXXXX${location.state.phone_number.substring(location.state.phone_number.length - 4)} mobile` 
               :` Please enter the OTP which, we have sent to XXXXXX${phNumber.substring(phNumber.length - 4)} mobile`}
              </div>
            </Stack>
            <Stack justifyContent={"center"} alignItems={"center"} sx={{ marginTop: '45px' }}>
              <OtpInput
                inputStyle={{
                  height: 35,
                  width: 35,
                border:'1px solid #E0E0E0'
                }
                }
                numInputs={4}
                onChange={(value) => handleOtpChange(value)}
                isInputNum={true}
                shouldAutoFocus
                value={otp}

              />
              {error && error.length > 0 && <p className="otpText"> OTP does not match!</p>}

              <div className="otp-resend" style={{ marginTop: "1em" }}>
                {/* <span>Didn't receive it?</span> */}
                {/* <Countdown
                  date={Date.now() + 60 * 1000}
                  // key={resetCounter}
                  renderer={renderer}
                /> */}
                <MinuteCounter onReset={resendOTP} />
              </div>
              <Button
                className="v-btn"
                style={{ marginTop: "2em", width: "100%" }}
                type="submit"
                variant="contained"
                // endIcon={<ChevronRightRounded />}
                disabled={!otpValid}
                onClick={() => {
                  if(onSubmit){
                    onSubmit(otp)
                  }else{
                    verifyOTP(otp)
                  }
                  
                }}
              >
                Verify
              </Button>
            </Stack>
          </Stack>
        </div>
      )}
    </div>
  );
}

export default Otp;
