import { gql } from "@apollo/client";

const GetSurvey = gql`
query GetSurveyDetails($url: String = "", $_gt: date = "") {
  surveys(where: {link: {_eq: $url}}) {
    id
    name
    m_login
    form_details
    qr_id
    message
    message_title
    banner
    metadata
    allow_multiple_responses
    survey_qrs {
      qr {
        spaces_qrs {
          space {
            google_rating_page
            location
            id
          }
        }
        dist_coupon_amount
        id
      }
    }
    survey_coupons(where: {coupon: {expiry_date: {_gt: $_gt}}}) {
      coupon {
        id
        offer_title
        brand_name
        brand_logo
        code_type
      }
    }
    merchant {
      logo
      industry
      id
      merchant_spaces {
        space {
          location
          google_rating_page
        }
      }
    }
  }
}
`;

export const newSurveyQuery=gql`
query newGetSurveyDetails($url: String = "") {
  surveys(where: {link: {_eq: $url}}) {
    id
    name
    m_login
    form_details
    message
    message_title
    banner
    metadata
    merchant {
      logo
      industry
      id
      merchant_spaces {
        space {
          location
          google_rating_page
        }
      }
    }
  }
}
`
export default GetSurvey;
