import {
  Card,
  Box,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Stack,
  IconButton,
  Chip,
  Collapse,
  Tooltip,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Barcode from "react-barcode";
import { useState, useCallback } from "react";
import {
  ChevronRight,
  Close,
  DownloadDone,
  LocationOn,
} from "@mui/icons-material";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import { Download } from "@mui/icons-material";
import parse from "html-react-parser";
import moment from "moment";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";

export function CouponCard(props) {
  const [open, setOpen] = useState(props.dialogOpen);
  const [logoError, SetlogoError] = useState(false);
  const [checked, SetChecked] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function validURL(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  // function toHex(d) {
  //   return ("0" + Number(d).toString(16)).slice(-2).toUpperCase();
  // }

  const c_id = parseInt(props.coupon_id);

  // ---------------------------------------------- Download Coupon -------------------------------------------
  const handleDownloadCoupon = useCallback(async () => {
    const coupon1 = document.querySelector("#coupon-dw > div > div > div");
    // console.log("coupon1", coupon1);
    const canvas = await html2canvas(coupon1, {
      useCORS: true,
      allowTaint: true,
    });
    //console.log("canvas", canvas);
    const dataURL = canvas.toDataURL("image/jpg");
    downloadjs(dataURL, "Extraa Coupon.jpg", "image/jpg");
    // console.log("dataURL", dataURL);
  }, []);

  // const [copyText, setCopyText] = useState("");

  //   const handleCopyText = (e) => {
  //       setCopyText(e.target.value);
  //   };

  const copyToClipboard = (e) => {
    e.stopPropagation();
    SetChecked(true);
    copy(props.code);
    setTimeout(() => SetChecked(false), 1500);
    // console.log(`test "${props.code}"`);
  };

  // const handleCopyClick = async () => {
  //   try {
  //     await copy(props.code);
  //     setIsCopied(true);
  //   } catch (error) {
  //     console.error('Failed to copy text: ', error);
  //   }
  // };

  return (
    <div
      //className={props.shadow?"cards coupon":"coupon"}
      style={{
        borderRadius: "8px",
      }}
    >
      <Card
        sx={{
          borderRadius: "8px",
          width: 260,
          marginLeft: 1,
          marginRight: 1,
          position: "relative",
        }}
        elevation={props.validity && moment(props.validity).isAfter() ? 4 : 1}
        onClick={
          props.validity &&
          moment(props.validity).isAfter() &&
          // !props.redeem &&
          handleClickOpen
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background:
              props.validity &&
                moment(props.validity).isAfter() &&
                !props.redeem
                ? "#fff"
                : "#eeeeee",
            //  alignItems: "start",
            height: "100%",
          }}
        >
          {/* ------------------------------ Left Column ---------------------------- */}
          <Stack
            sx={{
              background:
                props.validity &&
                  moment(props.validity).isAfter() &&
                  !props.redeem
                  ? props.coupon_color
                  : "grey",
              color: "white",
              // height: "100%",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            alignItems="center"
            justifyContent="center"
          >
            {/* <p style={{ marginBottom: 0 }}>
              #{c_id.toString(16).padStart(6, "0").toUpperCase()}
            </p> */}
            {/* -------------------------- Brand Logo ------------------------------------- */}
            <Box
              sx={{
                width: 100,

                paddingLeft: 2,
                paddingRight: 2,
                height: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!logoError ? (
                <img
                  src={props.brandLogo}
                  onError={() => SetlogoError(true)}
                  alt="brand logo"
                  style={{
                    width: "100%",
                    borderRadius: 12,
                    // background: "white",
                    height: "100%",
                    objectFit: "contain",
                    padding: 4,
                    filter:
                      props.validity &&
                        moment(props.validity).isAfter() &&
                        !props.redeem
                        ? ""
                        : "grayscale(100%)",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    color: props.coupon_color,
                    borderRadius: 12,
                    background: "white",
                    height: "100%",
                  }}
                >
                  <h4
                    style={{
                      marginTop: 32,
                      overflowWrap: "anywhere",
                      fontSize: "0.6em",
                      fontFamily: "rota-black",
                      textAlign: "center",
                    }}
                  >
                    {props.brandName.toUpperCase()}
                  </h4>
                </div>
              )}
              {props.shadow && !props.redeem && (
                <span
                  className="badge"
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  New
                </span>
              )}
            </Box>

            {/* <button style={{border: "none",
                  borderRadius: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  backgroundColor: "white"}} 
                  onClick={copyToClipboard}>Copy</button> */}

            {/* <img src={props.industry_icon} alt={props.industry_name} style={{ width: 32, height: 32 }} /> */}
          </Stack>

          {/* ------------------------------------ Right Column ------------------------------------- */}

          {/* <Stack sx={{ paddingLeft: 2, paddingTop: 2 }}> */}
          <Stack sx={{ padding: 1.3 }}>
            {/* ----------------------- Offer Details --------------------------------- */}
            <div
              style={{
                background: "#F6EEF9",
                padding: "12px",
                borderRadius: "4px",
                marginTop: "4px",
              }}
            >
              <h2
                style={{
                  marginBottom: 0,
                  marginTop: 4,
                  fontFamily: "rota-black",
                  fontSize: "1em",
                }}
              >
                {props.offerType && props.offerType.length > 60
                  ? `${props.offerType.substring(0, 60)}...`
                  : props.offerType}
              </h2>

              <p
                style={{
                  margin: 0,
                  textTransform: "capitalize",
                  fontSize: "0.8em",
                }}
              >
                {props.offerDetails && props.offerDetails.length > 40
                  ? `${props.offerDetails.substring(0, 40)}...`
                  : props.offerDetails}
              </p>
            </div>
            {/* ------------------------------------------ Brand Name and Industry ------------------------------------ */}

            <Stack direction="row" justifyContent="space-between">
              <h3
                style={{
                  margin: 0,
                  textTransform: "capitalize",
                  fontSize: "0.8em",
                  marginLeft: 12,
                  marginTop: 12,
                  color: "#767778",
                }}
              >
                {props.brandName}{" "}
                <span
                  style={{
                    fontSize: "1em",
                  }}
                >
                  {" "}
                  |{" "}
                </span>{" "}
                {props.industry_name.replace("and", "&")}
              </h3>
            </Stack>
            {/* ----------------------- Validity --------------------------------- */}
            <p
              style={{
                marginTop: 8,
                marginBottom: 4,
                marginLeft: 12,
                fontSize: "0.8em",
                color: "#767778",
              }}
            >
              {props.validity &&
                moment(props.validity).isAfter() &&
                !props.redeem ? (
                <span>
                  Valid till:{" "}
                  <span style={{ fontFamily: "rota-black" }}>
                    {moment(props.validity)
                      ?.subtract(1, "days")
                      ?.format("DD MMM YYYY")}
                  </span>
                </span>
              ) : props.redeem ? (
                <span
                  style={{ background: "green", padding: 8, color: "white" }}
                >
                  {" "}
                  Redeemed
                </span>
              ) : (
                <span style={{ background: "red", padding: 8, color: "white" }}>
                  {" "}
                  Expired
                </span>
              )}
            </p>
            {/* ----------- Coupon cuts -------------- */}

            <Divider sx={{ marginTop: 1 }} />

            {/* ------------------------------- Coupon Code -------------------------------- */}
            <div
              style={{
                marginTop: 16,
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "#F6EEF9",
                  borderRadius: "4px",
                  padding: 8,
                  width: "100%",
                }}
              >
                <p
                  style={{
                    color: "#4F3084",
                    fontSize: "0.8em",
                    fontFamily: "rota-black",
                    paddingLeft: 6,
                    margin: 0,
                  }}
                >
                  {props.code}
                  {/* </span> */}
                </p>
                {props.validity &&
                  moment(props.validity).isAfter() &&
                  !props.redeem && (
                    <>
                      {!checked ? (
                        <Tooltip
                          title="Copy to clipboard"
                          arrow
                          placement="top"
                        >
                          <ContentCopyIcon
                            onClick={copyToClipboard}
                            sx={{ fontSize: "18px" }}
                          />
                        </Tooltip>
                      ) : (
                        <Collapse in={checked}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <DownloadDone sx={{ fontSize: "18px" }} />
                            <label style={{ fontSize: 14, marginBottom: 3 }}>
                              copied
                            </label>
                          </div>
                        </Collapse>
                      )}
                    </>
                  )}
              </div>
              {validURL(props.location) && (
                <div
                  style={{
                    background: "#F6EEF9",
                    borderRadius: "4px",
                    padding: 2,
                  }}
                >
                  <a href={props.location} target="_blank" rel="noreferrer">
                    <IconButton size="small" aria-label="redeem">
                      <OpenInNewIcon />
                    </IconButton>
                  </a>
                </div>
              )}
            </div>
            {/* ----------------------- Powered --------------------------------- */}
            {/* <div
              style={{
                marginTop: 8,
                fontSize: "0.7em",
                verticalAlign: "middle",
                color: "#4F3084",
                // textAlign: "center",
              }}
            >
              {" "}
              Powered by{" "}
              <img
                style={{ height: 18, verticalAlign: "middle" }}
                src="./assets/extraa_logo.png"
                alt=""
              />{" "}
            </div> */}
            {/* <p
              style={{
                margin: 0,
                color: "#4F3084",
                marginTop: 6,
                fontSize: "0.55em",
                fontFamily: "rota-black",
                textAlign: "center",
                marginRight: "30px",
              }}
            >
              www.extraa.in
            </p> */}
          </Stack>

          <div
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 10,
            }}
          ></div>
        </Box>
      </Card>

      {/* ---------------------------- Full Dialog coupon --------------------------------------- */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        className="couponFull"
        fullWidth
        id="coupon-dw"
      >
        <DialogContent sx={{}}>
          <Card sx={{ borderRadius: 2, marginBottom: 2 }} elevation={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "#fff",
                position: "relative",
              }}
            >
              {/* ------------------------------ Left Column ---------------------------- */}
              <Stack
                sx={{
                  background: props.coupon_color,
                  color: "white",
                  flex: { xs: 1, lg: 0, md: 0, sm: 0 },
                }}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                {/* -------------------------- Brand Logo ------------------------------------- */}

                <Box
                  sx={{
                    width: 120,
                    paddingTop: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 1,
                    height: 120,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!logoError ? (
                    <Box
                      sx={{
                        width: 200,
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={props.brandLogo}
                        onError={() => SetlogoError(true)}
                        alt="brand logo"
                        style={{
                          width: "auto",
                          height: "auto",
                          maxHeight: 100,
                          maxWidth: 200,
                        }}
                      />
                    </Box>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        color: props.coupon_color,
                        borderRadius: 12,
                        background: "white",
                        height: "100%",
                      }}
                    >
                      <h4
                        style={{
                          marginTop: 56,
                          overflowWrap: "anywhere",
                          fontSize: "1em",
                          fontFamily: "rota-black",
                          textAlign: "center",
                        }}
                      >
                        {props.brandName.toUpperCase()}
                      </h4>
                    </div>
                  )}
                </Box>
                {/* <img src={props.industry_icon} alt={props.industry_name} style={{ width: 32, height: 32 }} /> */}
              </Stack>

              {/* ------------------------------------ Right Column ------------------------------------- */}
              <IconButton
                onClick={handleClose}
                sx={{
                  flex: 0,
                  margin: 0,
                  position: "absolute",
                  right: 6,
                  mixBlendMode: "lighten",
                  color: "white",
                }}
              >
                {" "}
                <Close />{" "}
              </IconButton>

              {/* ------------------------------------------ QR coode ------------------------------------ */}
              <Stack>
                <Stack
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 2,
                    background: "#f7f0f9",
                  }}
                >
                  {" "}
                  <QRCode
                    value={
                      "#" +
                      (c_id * 121).toString(16).padStart(6, "0").toUpperCase()
                    }
                    size={100}
                  />
                  <p style={{ marginTop: 2, marginBottom: 1 }}>
                    #{(c_id * 121).toString(16).padStart(6, "0").toUpperCase()}
                  </p>
                  {/* Coupon Code copy */}
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      background: "#f0e2f9",
                      borderRadius: "4px",
                      padding: 8,
                      margin: 4,
                      marginBottom: 8,
                      width: "80%",
                    }}
                  >
                    <p
                      style={{
                        color: "#4F3084",
                        fontSize: "0.8em",
                        fontFamily: "rota-black",
                        paddingLeft: 6,
                        margin: 0,
                      }}
                    >
                      {props.code}
                      {/* </span> */}
                    </p>
                    {props.validity &&
                      moment(props.validity).isAfter() &&
                      !props.redeem && (
                        <>
                          {!checked ? (
                            <Tooltip
                              title="Copy to clipboard"
                              arrow
                              placement="top"
                            >
                              <ContentCopyIcon
                                onClick={copyToClipboard}
                                sx={{ fontSize: "18px" }}
                              />
                            </Tooltip>
                          ) : (
                            <Collapse in={checked}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <DownloadDone sx={{ fontSize: "18px" }} />
                                <label
                                  style={{ fontSize: 14, marginBottom: 3 }}
                                >
                                  copied
                                </label>
                              </div>
                            </Collapse>
                          )}
                        </>
                      )}
                  </div>
                </Stack>
                {/* -------------------------------- Brand name -----------------------------  */}
                <Stack sx={{ paddingLeft: 2, paddingTop: 2, paddingRight: 3 }}>
                  <h3
                    style={{
                      margin: 0,
                      textTransform: "capitalize",
                      fontSize: "0.8em",
                    }}
                  >
                    {props.brandName}
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      textTransform: "capitalize",
                      fontSize: "0.8em",
                      fontFamily: "rota-black",
                    }}
                  >
                    {props.industry_name}
                  </h3>
                  {/* ----------------------- Validity --------------------------------- */}
                  <p style={{ marginTop: 2, fontSize: "0.8em" }}>
                    <span
                      style={{
                        fontSize: "0.9em",
                        fontFamily: "rota-black",
                        color: "#7B7979",
                      }}
                    >
                      Valid till:{" "}
                    </span>
                    {props.validity &&
                      moment(props.validity)
                        ?.subtract(1, "days")
                        ?.format("DD MMM YYYY")}
                  </p>
                  {/* ------------------------------------ Offer title -------------------------- */}
                  <div style={{ marginBottom: 8, color: "#4f3084" }}>
                    <h2
                      style={{
                        marginBottom: 0,
                        marginTop: 4,
                        fontFamily: "rota-black",
                        fontSize: "1.2em",
                      }}
                    >
                      {props.offerType}
                    </h2>
                    {/* ----------------------- Offer Details --------------------------------- */}
                    <p
                      style={{
                        margin: 0,
                        textTransform: "capitalize",
                        fontSize: "1em",
                      }}
                    >
                      {props.offerDetails}
                    </p>
                  </div>

                  <Divider style={{ margin: 8 }} />

                  {/*----------------------  location --------------------------------- */}

                  {!validURL(props?.location) && (
                    <div style={{ marginLeft: 0 }}>
                      <h4
                        style={{
                          margin: 0,
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <LocationOn
                          sx={{ width: 16, height: 16 }}
                          color="primary"
                        />
                        Locations:
                      </h4>
                      <p style={{ wordBreak: "break-all", marginTop: 0 }}>
                        <span
                          style={{
                            marginTop: 0,
                            fontSize: ".8em",
                          }}
                        >
                          {props.location}
                        </span>
                      </p>
                    </div>
                  )}
                  {/* ------------------- Download and redeem Buttons ------------- */}

                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      marginBottom: 8,
                    }}
                  >
                    {validURL(props.location) && (
                      <a href={props.location} target="_blank" rel="noreferrer">
                        <Button
                          style={{
                            margin: 0,

                            borderRadius: 4,
                            textTransform: "capitalize",
                            background: "#421c4d",
                            color: "white",
                          }}
                          endIcon={<ChevronRight />}
                        >
                          Redeem now
                        </Button>
                      </a>
                    )}
                    <Button
                      style={{
                        background: "#f6eef9",
                        borderRadius: 2,
                        color: "#421C4D",
                        border: "solid 1px #421C4D",
                        textTransform: "capitalize",
                      }}
                      onClick={handleDownloadCoupon}
                      endIcon={<Download />}
                    >
                      Download
                    </Button>
                  </div>
                  {/* ----------------------- Powered --------------------------------- */}
                  {/* <div
                    style={{
                      marginTop: 8,
                      fontSize: "0.7em",
                      verticalAlign: "middle",
                      color: "#4F3084",
                    }}
                  >
                    {" "}
                    Powered by{" "}
                    <img
                      style={{ height: 18, verticalAlign: "middle" }}
                      src="./assets/extraa_logo.png"
                      alt=""
                    />{" "}
                  </div> */}
                </Stack>
              </Stack>
            </Box>
          </Card>

          {/* ----------------------------- Terms and Conditions --------------------------- */}
          <Card
            sx={{ borderRadius: 2, marginBottom: 2, background: "#fff" }}
            elevation={4}
          >
            <Box
              sx={{
                paddingLeft: 2,
                marginTop: 2,
                marginLeft: 2,
                height: 32,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ fontSize: "1.2em" }}> Terms & Conditions</h2>
              {logoError ? (
                <h4
                  style={{
                    paddingRight: 24,
                    textTransform: "uppercase",
                    fontSize: "0.5em",
                  }}
                >
                  {" "}
                  {props.brandName}
                </h4>
              ) : (
                <img
                  src={props.brandLogo}
                  alt="brand logo"
                  style={{
                    paddingRight: 24,
                    paddingTop: 12,
                    height: "auto",
                    width: "auto",
                    maxHeight: 60,
                    maxWidth: 60,
                  }}
                />
              )}
            </Box>
            <Divider
              sx={{
                borderWidth: 2,
                background: props.coupon_color,
              }}
            />

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <CardContent
                  sx={{ flex: "1 0 auto", margin: 0 }}
                  className="terms"
                >
                  {parse(props.terms.replace("<p>&nbsp;</p>", ""))}
                </CardContent>
              </Box>
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
