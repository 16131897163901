import { gql } from "@apollo/client";

const UserPreferences = gql`
mutation MyMutation($_eq: bigint = "", $preferences: jsonb = "") {
    update_users(where: {id: {_eq: $_eq}}, _set: {preferences: $preferences}) {
      returning {
        id
        preferences
      }
    }
  }
`;

export const UpdateUserPreferences = async (
    id,
    data,
    client,
  ) => {
    try {
      // const loginId = JSON.parse(localStorage.getItem('gogo_current_user')).id
      // const merchantId = JSON.parse(localStorage.getItem('gogo_current_user')).merchant_id
  
      const resp=  await client.mutate({
        mutation: UserPreferences,
       // refetchQueries: [getQuery],
        variables: {
            "_eq": id,
            "preferences":data
          },
          context: {
            headers: {
              "Content-Type": "application/json",
              "x-hasura-admin-secret": "aseraadmin@1234",
            },
          },
      });
      if(resp.errors){
        return {success:false}
      }
      // window.location.reload();
    } catch (e) {
      console.log(JSON.stringify(e));
      // showFailureAlert('Something went wrong while updating your cart');
    }
    return {success:true}
  };
export default UpdateUserPreferences;