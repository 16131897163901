import { Button, CircularProgress, Fade, InputAdornment, Stack, TextField } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useState, useCallback, useEffect } from "react";
import PlaceIcon from '@mui/icons-material/Place';
import { useApolloClient } from "@apollo/client";
import UserSignUp, { newUserSignUp } from "../mutations/UserSignUp";

const NewUserDetails = ({ increment }) => {

    const [gender, SetGender] = useState("MALE");
    const [ageGroup, SetAgeGroup] = useState(0);
    const [pageLoading, SetPageLoading] = useState(false);
    const [pincode, SetPincode] = useState("");
    const client = useApolloClient();

    const submitUserForm = useCallback(
        async (gender, age, pincode) => {
            try {
                await client.mutate({
                    mutation: newUserSignUp,
                    variables: {
                        id: localStorage.user_id,
                        // name: name,
                        gender: gender,
                        age_range: age.toString(),
                        pincode: pincode
                    },
                    context: {
                        headers: {
                            "Content-Type": "application/json",
                            "x-hasura-admin-secret": "aseraadmin@1234",
                        },
                    },
                });
                increment();
            } catch (e) {
                console.log(JSON.stringify(e));
            }
        },
        [client]
    );

    return (
        <div className="n-signup">
            {pageLoading ? (
                <Fade in>
                    <div
                        style={{
                            width: "100%",
                            height: "90vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                </Fade>
            ) : (
                <>
                    <Stack alignItems="center">
                        <div>
                            <img
                                src="/assets/extraa-logo.png"
                                alt="extraa logo"
                                height={80}
                                style={{ paddingTop: 64, paddingBottom: 32 }}
                            />
                        </div>
                        <div>
                            {/* <Stack
                                alignItems="start"
                                width="100%"
                                sx={{ marginBottom: "20px" }}
                            >
                                <label className="extraatext">Welcome to Extraa</label>
                            </Stack> */}
                            <Fade in timeout={600}>
                                <div className="user-details">
                                    <ValidatorForm
                                        onSubmit={() => submitUserForm(gender, ageGroup, pincode)}
                                    >
                                        <div className="gender-container">
                                            Select your gender
                                            <div className="gender-selector">
                                                <div className="selecotr-item">
                                                    <input
                                                        type="radio"
                                                        id="male"
                                                        name="gender"
                                                        className="selector-item_radio"
                                                        defaultChecked={gender === "MALE" ? true : false}
                                                    />
                                                    <label
                                                        onClick={() => SetGender("MALE")}
                                                        htmlFor="male"
                                                        className="selector-item_label gender-label"
                                                    >
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="selecotr-item">
                                                    <input
                                                        type="radio"
                                                        id="female"
                                                        name="gender"
                                                        className="selector-item_radio"
                                                    />
                                                    <label
                                                        onClick={() => SetGender("FEMALE")}
                                                        htmlFor="female"
                                                        className="selector-item_label gender-label"
                                                    >
                                                        Female
                                                    </label>
                                                </div>
                                                <div className="selecotr-item">
                                                    <input
                                                        type="radio"
                                                        id="other"
                                                        name="gender"
                                                        className="selector-item_radio"
                                                    />
                                                    <label
                                                        onClick={() => SetGender("OTHER")}
                                                        htmlFor="other"
                                                        className="selector-item_label gender-label"
                                                    >
                                                        Other
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="age-container ">
                                            Select your age group
                                            <div className="age-selector">
                                                <div className="selecotr-item">
                                                    <input
                                                        type="radio"
                                                        id="age-1"
                                                        name="selector"
                                                        className="selector-item_radio"
                                                        defaultChecked={ageGroup === 0 ? true : false}
                                                    />
                                                    <label
                                                        onClick={() => SetAgeGroup(0)}
                                                        htmlFor="age-1"
                                                        className="selector-item_label"
                                                    >
                                                        5-20
                                                    </label>
                                                </div>
                                                <div className="selecotr-item">
                                                    <input
                                                        type="radio"
                                                        id="age-2"
                                                        name="selector"
                                                        className="selector-item_radio"
                                                    />
                                                    <label
                                                        onClick={() => SetAgeGroup(1)}
                                                        htmlFor="age-2"
                                                        className="selector-item_label"
                                                    >
                                                        21-40
                                                    </label>
                                                </div>
                                                <div className="selecotr-item">
                                                    <input
                                                        type="radio"
                                                        id="age-3"
                                                        name="selector"
                                                        className="selector-item_radio"
                                                    />
                                                    <label
                                                        onClick={() => SetAgeGroup(2)}
                                                        htmlFor="age-3"
                                                        className="selector-item_label"
                                                    >
                                                        41-60
                                                    </label>
                                                </div>
                                                <div className="selecotr-item">
                                                    <input
                                                        type="radio"
                                                        id="age-4"
                                                        name="selector"
                                                        className="selector-item_radio"
                                                    />
                                                    <label
                                                        onClick={() => SetAgeGroup(3)}
                                                        htmlFor="age-4"
                                                        className="selector-item_label"
                                                    >
                                                        60+
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <TextValidator
                                            variant="outlined"
                                            //  label="Phone Number"
                                            placeholder="Pincode"
                                            // required
                                            validators={[
                                               // "required",
                                                "isNumber",
                                                "matchRegexp:^[0-9]{6}$",
                                            ]}
                                            errorMessages={[
                                               // "This field is required",
                                                "Please enter a vaild pincode",
                                                "Please enter a vaild pincode",
                                            ]}
                                            sx={{
                                                backgroundColor: "#ffffff",
                                                "& .MuiInputBase-root": {
                                                    backgroundColor: "#ffffff",
                                                },
                                            }}
                                            InputProps={{
                                                inputMode: "numeric",
                                                pattern: "[0-9]*",
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PlaceIcon sx={{ color: "#000" }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{
                                                maxLength: 6,
                                            }}
                                            style={{ marginTop: "1em" }}
                                            value={pincode}
                                            onChange={(e) => SetPincode(e.target.value)}
                                        ></TextValidator>
                                        <Button
                                            className="v-btn"
                                            style={{ marginTop: "2em", width: "100%", marginBottom: '2em' }}
                                            type="submit"
                                            variant="contained"
                                        // endIcon={<ChevronRightRounded />}
                                        >
                                            Submit
                                        </Button>
                                    </ValidatorForm>
                                </div>
                            </Fade>
                        </div>
                    </Stack>

                </>
            )}
        </div>
    )
}

export default NewUserDetails;