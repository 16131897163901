export const merchantCreation = (results) => {
  const accountArr = {
    account: {
      data: {
        username: results.newPocName.trim().toLowerCase(),
        password: "123456",
        phone_number: results.newPocMobile,
        email: results.newPocEmail,
        details: {
          phone: results.newPocMobile,
          designation: results.newPocDesignation,
          email: results.newPocEmail,
        },
        role: "merchant",
        roles: { roles: ["merchant"] },
      },
    },
  };
  const merchantOnboardVar = {
    merchant_accounts: accountArr,
    name: `${results.merchantName}`,
    spaceName: `${results.merchantName} Branch`,
    qrName: `${results.merchantName} QR`,
    details: {
      phone: results.newPocMobile,
      email: results.newPocEmail,
      address: results.merchantOfficeAddress,
      branches: "1",
    },
    pincode: results.merchantPincode,
    industry: Number(results.industry),
    location: results.location
  };

  // console.log(merchantOnboardVar.merchant_accounts);
  //console.log(merchantOnboardVar);
 // console.log(results);
  return merchantOnboardVar
};
