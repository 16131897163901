import {
  TextField,
  CircularProgress,
  Fade,
  Box,
  Stack,
  InputAdornment,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import LockIcon from '@mui/icons-material/Lock';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AccountCircle,
  ChevronRightRounded,
  LabelRounded,
  Phone,
  PhoneAndroid,
} from "@mui/icons-material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import "./SignUpAndLogin.scss";
import { getOTP, verifyOTP } from "../../common/getOtp";
import UserSignUp from "../../mutations/UserSignUp";
import Otp from "../Otp";

const SignUpAndLogin = (props) => {
  const increment = props.increment;
  const incrementbyamount = props.incrementbyamount;
  const [pageLoading, SetPageLoading] = useState(false);
  const [userName, SetUserName] = useState("");
  const [ageGroup, SetAgeGroup] = useState("");
  // const [gender, SetGender] = useState("");
  // const [pincode, SetPincode] = useState("");
  const [number, SetNumber] = useState("");
  const [otpSent, SetOtpSent] = useState(false);
  const [otp, SetOtp] = useState("");
  const [login, SetLogin] = useState(false);
  const [userDetails, SetUserDetails] = useState({});
  const [errormsg, SetErorMsg] = useState("");
  const client = useApolloClient();
  const handleNameChange = (e) => {
    SetUserName(e.target.value);
  };
  const submitUserForm = async (uname, num) => {
    //  console.log(uname, num);
   // SetPageLoading(true);
   SetOtpSent(true);
    const result = await getOTP(number);
    SetUserDetails({
      otp_id: result.insert_otp.id,
      user_id: result.user.id,
    });
    await client.mutate({
      mutation: UserSignUp,
      variables: {
        id: result.user.id,
        name: uname,
        gender: "",
        age_range: "",
      },
      context: {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoic3VwZXJhZG1pbiIsImlhdCI6MTY5ODgxNTIyMiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbInN1cGVyYWRtaW4iLCJtZXJjaGFudCJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJzdXBlcmFkbWluIiwieC1oYXN1cmEtdXNlci1pZCI6IjEiLCJ4LWhhc3VyYS1tZXJjaGFudC1pZCI6IjEwNjAifX0._tQrdKxPCFszepLVKUjildzyv6hqLTYKuJHIOU3xHjw",
        },
      },
    });
   // SetPageLoading(false);
  };
  const loginUser = async (number) => {
    // console.log(number);
   // SetPageLoading(true);
   SetOtpSent(true);
    const result = await getOTP(number);
    SetUserDetails({
      otp_id: result.insert_otp.id,
      user_id: result.user.id,
    });
    // console.log(result, 'result')
  //  SetPageLoading(false);
    
  };

  const onVerifyClick = async (otp) => {
    SetPageLoading(true);
    const { otp_id, user_id } = userDetails;
    const result = await verifyOTP(otp, otp_id, user_id);
    // console.log(result,'otp sucess')
    SetPageLoading(false);
    if (result.status) {
      localStorage.setItem("token", result.token);
      localStorage.setItem("user_id", user_id);
      if (login) {
        incrementbyamount(2);
        // console.log("login");
      } else {
        //  SetOtpSent(false);
        increment();
      }
    } else {
      SetErorMsg(result.message);
    }
  };

  return (
    <div className="n-signup">
      {pageLoading ? (
        <Fade in>
          <div
            style={{
              width: "100%",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </Fade>
      ) : (
        <>
          {/* {!otpSent ? ( */}
          <Stack alignItems="center">
            <div>
              <img
                src="/assets/extraa-logo.png"
                alt="extraa logo"
                height={80}
                style={{ paddingTop: 64, paddingBottom: 32 }}
              />
            </div>
            <div>
              <Stack
                alignItems="start"
                width="100%"
                sx={{ marginBottom: "30px" }}
              >
                <label className="extraatext">Welcome to Extraa</label>
                <label className="subtext">
                  {!login
                    ? "Please enter your details to sign in"
                    : "Please enter your details to login"}
                </label>
              </Stack>
              {!login ? (
                <Fade in timeout={600}>
                  <div className="user-details">
                    <ValidatorForm
                      onSubmit={() => submitUserForm(userName, number)}
                    >
                      <TextValidator
                        variant="outlined"
                        // label="Name"
                        placeholder="Enter your full name"
                        required
                        sx={{
                          backgroundColor: "#ffffff",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="Phonenumber" src="/assets/user.png" />
                            </InputAdornment>
                          ),
                        }}
                        style={{ marginTop: "1em" }}
                        value={userName}
                        onChange={handleNameChange}
                      ></TextValidator>

                      <TextValidator
                        variant="outlined"
                        //  label="Phone Number"
                        placeholder="Phone number"
                        required
                        validators={[
                          "required",
                          "isNumber",
                          "matchRegexp:^[0-9]{10}$",
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Please enter a vaild number",
                          "Please enter a vaild number",
                        ]}
                        sx={{
                          backgroundColor: "#ffffff",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src="/assets/smartphone.png" />
                              <label className="phoneText">+91</label>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          maxLength: 10,
                        }}
                        style={{ marginTop: "1em" }}
                        value={number}
                        onChange={(e) => SetNumber(e.target.value)}
                      ></TextValidator>

                      {/* <div style={{ width: "100%" }}>
                        <p style={{ textAlign: "left" }}>Gender</p>
  
                        <ToggleButtonGroup
                          className="toggle-btns"
                          color="primary"
                          value={gender}
                          exclusive
                          onChange={(e) => SetGender(e.target.value)}
                          aria-label="Gender"
                        >
                          <ToggleButton value="MALE">He/Him</ToggleButton>
                          <ToggleButton value="FEMALE">She/Her</ToggleButton>
                          <ToggleButton value="OTHER">They/Them</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <div style={{ width: "100%" }}>
                        <p style={{ textAlign: "left" }}>Age group</p>
  
                        <ToggleButtonGroup
                          className="toggle-btns age-group"
                          color="primary"
                          value={ageGroup}
                          exclusive
                          onChange={(e) => SetAgeGroup(e.target.value)}
                          aria-label="Gender"
                        >
                          <ToggleButton value="0">-20</ToggleButton>
                          <ToggleButton value="1">20-30</ToggleButton>
                          <ToggleButton value="2">30-40</ToggleButton>
                          <ToggleButton value="3">40-50</ToggleButton>
                          <ToggleButton value="4">50+</ToggleButton>
                        </ToggleButtonGroup>
                      </div> */}

                      {/* <TextValidator
                        variant="outlined"
                        label="Pincode"
                        required
                        validators={[
                          "required",
                          "isNumber",
                          "matchRegexp:^[0-9]{6}$",
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Please enter a vaild pincode",
                          "Please enter a vaild pincode",
                        ]}
                        sx={{
                          backgroundColor: "#ffffff",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        inputProps={{
                          maxLength: 6,
                        }}
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "^[0-9]$",
                        }}
                        style={{ marginTop: "1em" }}
                        value={pincode}
                        onChange={(e) => {
                          SetPincode(e.target.value);
                        }}
                        error={false}
                      ></TextValidator> */}
                      {!otpSent ? (
                        <Button
                          className="v-btn"
                          style={{ marginTop: "2em", width: "100%" }}
                          type="submit"
                          variant="contained"
                        // endIcon={<ChevronRightRounded />}
                        >
                          Generate OTP
                        </Button>
                      ) : (
                        <Fade in timeout={600}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <TextValidator
                              variant="outlined"
                            //  label="OTP"
                              placeholder="Enter OTP"
                              type="password"
                              required
                              validators={[
                                "required",
                                "isNumber",
                                "matchRegexp:^[0-9]{4}$",
                              ]}
                              errorMessages={[
                                "This field is required",
                                "Please enter a vaild OTP",
                                "Please enter a vaild OTP",
                              ]}
                              sx={{
                                backgroundColor: "#ffffff",
                                "& .MuiInputBase-root": {
                                  backgroundColor: "#ffffff",
                                },
                              }}
                              inputProps={{
                                maxLength: 4,
                              }}
                              InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                startAdornment: (
                                  <InputAdornment position="start">
                                   <LockIcon sx={{color:'#000'}} />
                                  </InputAdornment>
                                ),
                              }}
                              style={{ marginTop: "1em" }}
                              value={otp}
                              onChange={(e) => {
                                SetOtp(e.target.value);
                              }}
                              error={false}
                            ></TextValidator>
                            <label style={{ color: 'red' }}>{errormsg}</label>
                            <Button
                              className="v-btn"
                              style={{ marginTop: "2em", width: "100%" }}
                              type="submit"
                              variant="contained"
                             // endIcon={<ChevronRightRounded />}
                              onClick={() => {
                                onVerifyClick(otp)
                              }}
                            >
                              Verify
                            </Button>
                          </div>
                        </Fade>
                      )}
                    </ValidatorForm>
                    <div className="terms-container">
                      <div className="terms">
                        already a member?
                        <Button
                          className="txt-btn"
                          onClick={() => {
                            SetLogin(true);
                          }}
                        >
                          Log in
                        </Button>
                      </div>
                      {/* <div className="terms">
                          <p>
                            By submitting you agree to the {""}
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.extraa.in/terms.html"
                            >
                              Terms & Conditions <br /> Privacy Policy
                            </a>
                          </p>
                        </div> */}
                    </div>
                  </div>
                </Fade>
              ) : (
                <Fade in timeout={600}>
                  <div className="user-details">
                    <ValidatorForm onSubmit={() => loginUser(number)}>
                      <TextValidator
                        variant="outlined"
                        //  label="Phone Number"
                        required
                        validators={[
                          "required",
                          "isNumber",
                          "matchRegexp:^[0-9]{10}$",
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Please enter a vaild number",
                          "Please enter a vaild number",
                        ]}
                        sx={{
                          backgroundColor: "#ffffff",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src="/assets/smartphone.png" />
                              <label className="phoneText">+91</label>
                            </InputAdornment>
                          ),
                        }}
                        style={{ marginTop: "1em" }}
                        value={number}
                        onChange={(e) => SetNumber(e.target.value)}
                      ></TextValidator>
                      {otpSent ? (
                        <Fade in timeout={600}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <TextValidator
                              variant="outlined"
                             // label="OTP"
                              placeholder="Enter OTP"
                              required
                              type="password"
                              validators={[
                                "required",
                                "isNumber",
                                "matchRegexp:^[0-9]{4}$",
                              ]}
                              errorMessages={[
                                "This field is required",
                                "Please enter a vaild OTP",
                                "Please enter a vaild OTP",
                              ]}
                              sx={{
                                backgroundColor: "#ffffff",
                                "& .MuiInputBase-root": {
                                  backgroundColor: "#ffffff",
                                },
                                marginBottom:5
                              }}
                              inputProps={{ maxLength: 4 }}
                              InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                startAdornment: (
                                  <InputAdornment position="start">
                                   <LockIcon sx={{color:'#000'}} />
                                  </InputAdornment>
                                ),
                              }}
                              style={{ marginTop: "1em" }}
                              value={otp}
                              onChange={(e) => {
                                SetOtp(e.target.value);
                              }}
                              error={false}
                            ></TextValidator>
                            <label style={{ color: 'red' }}>{errormsg}</label>
                            <Button
                              className="v-btn"
                              onClick={() => {
                                onVerifyClick(otp)
                              }}
                              fullWidth
                              variant="contained"
                             // endIcon={<ChevronRightRounded />}
                            >
                              Verify
                            </Button>
                          </div>
                        </Fade>
                      ) : (
                        <Button
                          className="v-btn"
                          style={{ marginTop: "2em", width: "100%" }}
                          type="submit"
                          variant="contained"
                        // endIcon={<ChevronRightRounded />}
                        >
                          Generate OTP
                        </Button>
                      )}
                    </ValidatorForm>
                    <div className="terms-container">
                      <div className="terms">
                        not a member yet?
                        <Button
                          onClick={() => {
                            SetLogin(false);
                          }}
                        >
                          Sign up
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              )}
            </div>
          </Stack>
          {/* ) : (
            <Otp
              onSubmit={onVerifyClick}
              modeOpen={() => {
                SetOtpSent(false);
              }}
              error={errormsg}
              phNumber={number}
              userdetails={(val) => {
                SetUserDetails(val);
              }}
            />
          )} */}
        </>
      )}
    </div>
  );
};

export default SignUpAndLogin;
