import { ChevronRightRounded } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Stack } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Fade, CircularProgress } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ReactGA from 'react-ga';
import { getAPI } from "../api/getApi";
import { decodeJWT } from "./UrlToken";
import { useApolloClient } from "@apollo/client";
import UseCRMToken from "../queries/GetCrmTokens";

function MLogin(props) {
    const pathURL = process.env.REACT_APP_ENDPOINT;
    const client = useApolloClient();
    const navigate = useNavigate();
    const [phNum, SetphNum] = useState("");
    const [pass, setpass] = useState("");
    const [errMsg, SetErrMsg] = useState("");
    const [loading, SetLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleChange = (event) => {
        SetphNum(event.target.value);
    };
    const onPassChange = (event) => {
        setpass(event.target.value);
    };
    const locations = useLocation()
    const urls = locations.state?.url
    // console.log(url,"url")

    //   useEffect(() => {
    //     //GA code added by yuvanesh
    //     ReactGA.pageview(window.location.pathname)
    //     if (localStorage.getItem("token")) {
    //       navigate("/user-home");
    //     }
    //   }, []);

    const onLoginClick = async (number, password) => {
        SetLoading(true)
        const url = 'https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/merchant/login'
        const variables = { "username": number, "password": password }
        const response = await getAPI(url, variables)
        SetLoading(false)
        if (response?.data?.status) {
            // console.log(response, 'data')
            localStorage.setItem("mtoken", response?.data?.token)
            localStorage.setItem("accid", response?.data?.account_id)
            localStorage.setItem("mer_id", response?.data?.merchant_id)
            localStorage.setItem("user_name", response?.data?.username)
            localStorage.setItem("user_ph_number", response?.data?.details?.phone)
            // const decode = response?.data?.token && decodeJWT(response?.data?.token)['https://hasura.io/jwt/claims']
            SetErrMsg("")
            navigate(urls)
        } else {
            SetErrMsg(response.message)
        }
        // console.log(response, 'response')
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div style={{ background: "#FFF7C0" }}>
            {loading ? (
                <Fade in>
                    <div
                        style={{
                            width: "100%",
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                </Fade>
            ) : (
                <div className="user-login">
                    <Stack alignItems={"center"} pt={12} >
                        <img
                            src="./assets/extraa_logo.png"
                            alt=""
                            className="home-logo"
                            style={{
                                width: "200px",
                            }}
                        />

                        <ValidatorForm onSubmit={() => onLoginClick(phNum, pass)}>
                            <TextValidator
                                // label="Phone Number*"
                                onChange={handleChange}
                                // variant="filled"
                                name="phNum"
                                placeholder="Phone Number"
                                type="tel"
                                value={phNum}
                                validators={["required"]}
                                errorMessages={[
                                    "This field is required",
                                    "Please enter a vaild number or email",
                                    "Please enter a vaild number or email",
                                ]}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    "& .MuiInputBase-root": {
                                        backgroundColor: "#ffffff",
                                    },
                                }}
                                // InputProps={{
                                //     inputMode: "numeric",
                                //     pattern: "[0-9]*",
                                // }}
                                style={{ marginTop: "2em", width: '250px' }}
                            />

                            <TextValidator
                                // label="Phone Number*"
                                onChange={onPassChange}
                                // variant="filled"
                                name="password"
                                placeholder="Password"
                                type={!showPassword ? "password" : "tel"}
                                value={pass}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    "& .MuiInputBase-root": {
                                        backgroundColor: "#ffffff",
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            {/* <PhoneInTalk /> */}
                                            <div style={{
                                                backgroundColor: "#ffffff",
                                                "& .MuiInputBase-root": {
                                                    backgroundColor: "#ffffff",
                                                },
                                                cursor: 'pointer'
                                            }}>
                                                {!showPassword ? <VisibilityIcon sx={{ marginTop: '5px' }} fontSize='small' onClick={togglePasswordVisibility} /> :
                                                    <VisibilityOffIcon fontSize='small' sx={{ marginTop: '5px' }} onClick={togglePasswordVisibility} />}
                                            </div>
                                        </InputAdornment>

                                    ),
                                }}
                                style={{ marginTop: "2em" }}
                            />

                            {errMsg && (
                                <p
                                    style={{ color: "red", marginBottom: 0, textAlign: "center" }}
                                >
                                    {errMsg}
                                </p>
                            )}
                            <Button
                                style={{ marginTop: "2em" }}
                                className="p-btn"
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                Login
                            </Button>
                        </ValidatorForm>
                    </Stack>
                </div>
            )}
        </div>
    );
}

export default MLogin;
