import { gql } from "@apollo/client";
import { client } from "..";
import { setInitalHeaders } from "../common/methods";

export const createResponse = gql`
mutation CreateProduct($brand: String = "", $category: String = "", $description: String = "", $images: jsonb = "", $name: String = "", $tags: jsonb = "", $type: Int = 0, $product_variants: product_variants_arr_rel_insert_input = {data: {reg_price: "", sale_price: ""}}, $coupon_id: bigint = "", $industry_id: bigint = "", $location: String = "", $merchant_id: bigint = "", $slug: String = "", $metadata: jsonb = "", $terms: String = "") {
  insert_products_one(object: {brand: $brand, category: $category, description: $description, images: $images, name: $name, tags: $tags, type: $type, product_variants: $product_variants, coupon_id: $coupon_id, industry_id: $industry_id, location: $location, merchant_id: $merchant_id, slug: $slug, metadata: $metadata, terms: $terms}) {
    id
  }
}  
`;

export const CreateProducts = async (
    data
) => {
    let resp;
    try {
        resp = await client.mutate({
            mutation: createResponse,
            refetchQueries: [],
            variables: data,
            context: {
                headers: {
                    "Content-Type": "application/json",
                    authorization:
                        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikd1ZXN0IiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImd1ZXN0Il0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6Imd1ZXN0In19.dFW-ZzED-qnnoWVb0r9oZIcmn2gSsBxqBp30BuUz1wk",
                },
            },
        });
        if (resp.errors) {
            console.log(resp.errors)
            // message.error(resp.errors);
            return resp.errors
        }
        // window.location.reload();
    } catch (e) {
        console.log(JSON.stringify(e));
    }
    // message.success(`Product ${data?.name} Created Successfully!'`, 5);
    return resp;
};
