import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { Box, Button, Stack } from "@mui/material";
import { redirect, useNavigate } from "react-router-dom";
import '../pages/Feedback.css'

export const ThankYouPage = ({ tilte, message, coupons, newPage, google_link, couponCount, sales_mob }) => {
  const navigate = useNavigate();
  const mtoken = localStorage.getItem("mtoken");
  const onRegClick = () => {
    window.location.reload()
  }
  return (
    <Stack alignItems="center">
      <Box maxWidth="600px" width="90%" marginBottom="36px">
        {
          !newPage &&
          <>
            <div style={{ textAlign: "center" }}>
              <h2> {tilte}</h2>
              <p> {message}</p>
              {coupons.length > 0 ? (
                <Player
                  autoplay
                  loop
                  background={"transparent"}
                  src="/assets/anims/couponsWon.json"
                  style={{ height: "180px", width: "300px" }}
                >
                  <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                  />
                </Player>
              ) : (
                <Player
                  autoplay
                  loop
                  background={"transparent"}
                  src="/assets/anims/thumbsup.json"
                  style={{ height: "180px", width: "300px" }}
                >
                  <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                  />
                  {(mtoken ||sales_mob) && <Button variant="contained" sx={{ marginTop: 6 }} onClick={onRegClick}>Register Again</Button>}
                </Player>
              )}
              {coupons.length > 0 && <h3>Celebrate with these cool coupons!</h3>}
            </div>

            {coupons.length > 0 && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap="24px"
                flexWrap="wrap"
              >
                {coupons.map((obj, index) => {
                  return (
                    <Stack alignItems="center" key={index}>
                      <div
                        style={{
                          background: "#FFF",
                          padding: "8px",
                          borderRadius: "12px",
                          boxShadow:
                            "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "120px",
                            maxHeight: "80px",
                            borderRadius: "12px",
                          }}
                          src={obj.coupon?.brand_logo}
                          alt={obj.coupon?.brand_name}
                        />
                      </div>
                    </Stack>
                  );
                })}
              </Stack>
            )}
            {coupons.length > 0 && (
              <Stack alignItems="center">
                <Button
                  variant="contained"
                  onClick={() => navigate("/")}
                  className="p-btn"
                >
                  Claim now!
                </Button>
              </Stack>
            )}
          </>
        }
        {newPage &&
          <>
            <div style={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: "center", paddingTop: '50px' }} >
              <img src='/assets/Group.png' width={'300px'} />
            </div>
            <Stack alignItems={'center'}>
              <Stack alignItems={'start'} width={'300px'} spacing={4} sx={{ marginTop: '75px' }}>
                <div>
                  <label className="greatTxt">Great...!</label><br></br>
                  <label className="thanksTxt">Thanks for helping us out...</label>
                </div>
                <Stack spacing={2}>
                  <label className="thanksSub">We value your feedback & whicle we curate the best deals and the coupons for you</label>
                  <label className="thanksSub">Kindly review us a review on google as well</label>
                </Stack>
                <Stack spacing={2} alignItems={'center'} width={'100%'} sx={{ maxWidth: '300px' }}>
                  <Button
                    className="v-btn"
                    style={{ marginTop: "2em", width: "100%" }}
                    type="submit"
                    variant="contained"
                    // endIcon={<ChevronRightRounded />}
                    onClick={() => window.location.href = google_link || ""}
                  >
                    Okay, I’ll write a review on google
                  </Button>
                  <Button
                    className="v-btn"
                    style={{ width: "100%", backgroundColor: '#ffff', color: "#4f3084", border: "1px solid #4f3084" }}
                    type="submit"
                    variant="contained"
                    onClick={() => navigate("/home", {
                      state: {
                        couponNum: couponCount,
                      },
                    })}
                  // endIcon={<ChevronRightRounded />}
                  >
                    I’ll do it later
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </>
        }
      </Box>
    </Stack>
  );
};

export default ThankYouPage;