const branchCreation = (results) => {
   const branchVar= {
        "merchant_id":`${results.merchantId}` , 
"branch_name":`${results.branchName}` , 
"qr_name": `${results.merchantToAddBranch} QR`, 
"username": `${results.merchantToAddBranch}`,
"details": { "gst": "`${results.branchPocGst}`", 
"phone": `${results.branchPocMobile}`, 
"email": `${results.branchPocEmail}`, 
"address": `${results.branchAddress}`, 
"branch": `${results.branchName}` },
"details1": { "gst": `${results.branchPocGst}`, "phone":`${results.branchPocMobile}` , "email": `${results.branchPocEmail}`, "address": `${results.branchAddress}`, "branch": `${results.branchName}` },
"roles": { "roles": ["space_manager"] } ,
"location": results.location

      }
  return branchVar
}

export default branchCreation