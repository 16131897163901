import { createElement, useState } from "react";
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import {
    SurveyQuestionElementBase,
    ReactQuestionFactory
} from "survey-react-ui";
import ReactDOMServer from "react-dom/server";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";

const CUSTOM_TYPE = "searchdropdown";

// A model for the new question type
export class QuestionSearchDropdownModel extends Question {
    getType() {
        return CUSTOM_TYPE;
    }

    get Url() {
        return this.getPropertyValue("Url");
    }
    set Url(val) {
        this.setPropertyValue("Url", val);
    }
    get pathData() {
        return this.getPropertyValue("pathData");
    }
    set pathData(val) {
        this.setPropertyValue("pathData", val);
    }

    get val() {
        return this.getPropertyValue("val");
    }
    set val(vals) {
        this.setPropertyValue("val", vals);
    }

    get label() {
        return this.getPropertyValue("label");
    }
    set label(vals) {
        this.setPropertyValue("label", vals);
    }

    get headers() {
        return this.getPropertyValue("headers");
    }
    set headers(val) {
        this.setPropertyValue("headers", val);
    }

    //   get disableAlpha() {
    //     return this.getPropertyValue("disableAlpha");
    //   }
    //   set disableAlpha(val) {
    //     this.setPropertyValue("disableAlpha", val);
    //   }
}

// Register `QuestionColorPickerModel` as a model for the `color-picker` type
export function registerSearchDropdown() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
        return new QuestionSearchDropdownModel(name);
    });
}

// Specify display names for the question type and its properties
// const locale = localization.getLocale("");
// locale.qt[CUSTOM_TYPE] = "searchdropdown";
// locale.pe.colorPickerType = "Color picker type";
// locale.pe.disableAlpha = "Disable alpha channel";

// Register an SVG icon for the question type
const svg = ReactDOMServer.renderToString();
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, svg);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
    CUSTOM_TYPE,
    [
        {
            name: "Url",
            default: "",
            //   choices: ["Slider", "Sketch", "Compact"],
            category: "general",
            visibleIndex: 2 // After the Name and Title
        },
        {
            name: "pathData",
            default: "",
            //   choices: ["Slider", "Sketch", "Compact"],
            category: "general",
            visibleIndex: 3 // After the Name and Title
        },
        {
            name: "headers",
            default: "",
            //   choices: ["Slider", "Sketch", "Compact"],
            category: "general",
            visibleIndex: 4 // After the Name and Title
        },
        {
            name: "val",
            default: "",
            category: "general",
            visibleIndex: 5 // After the Name and Title
        },
        {
            name: "label",
            default: "",
            category: "general",
            visibleIndex: 6// After the Name and Title
        },
    ],
    function () {
        return new QuestionSearchDropdownModel("");
    },
    "question"
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionSearchDropdown extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        this.state = { value: this.question.value };
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question.value;
    }
    get disableAlpha() {
        return this.question.disableAlpha;
    }
    get Url() {
        return this.question.Url;
    }
    get path() {
        return this.question.pathData;
    }
    get val() {
        return this.question.val;
    }
    get label() {
        return this.question.label;
    }
    get headers() {
        return this.question.headers;
    }
    handleColorChange = (data) => {
        // console.log(data, 'data')
        this.question.value = data.value;
    };
    // Support the read-only and design modes
    get style() {
        return this.question.getPropertyValue("readOnly") ||
            this.question.isDesignMode
            ? { pointerEvents: "none" }
            : undefined;
    }

    renderElement() {
        // console.log(this.questionBase.urlType, 'questions',this.question.pathData)
        return <SearchDropdown
            handleChange={this.handleColorChange}
            path={this.question.pathData}
            url={this.question.Url}
            value={this.question.val}
            label={this.question.label}
            headers={this.question.headers}
        />
    }
}

// Register `SurveyQuestionColorPicker` as a class that renders `color-picker` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return createElement(SurveyQuestionSearchDropdown, props);
});

// Register the `color-picker` as an editor for properties of the `color` type in the Survey Creator's Property Grid
// PropertyGridEditorCollection.register({
//     fit: function (prop) {
//         return prop.type === "color";
//     },
//     getJSON: function () {
//         return {
//             type: CUSTOM_TYPE,
//             colorPickerType: "Compact"
//         };
//     }
// });

export function SearchDropdown({ handleChange, path, url, value, label, headers }) {
    const [data, setData] = useState([])
    // console.log(localStorage.getItem('crm_token'), 'headers');
    const handleText = async (e) => {
        // console.log(e.target.value, 'values');
        const api = url.concat(e.target.value)
        if (e.target.value?.length > 1) {
            const resp = await axios.get(api, { 'headers': { 'crm_token': localStorage.getItem('crm_token')||"" }}).
                then(async (response) => await response.data)
                .catch((error) => console.log(error));
            const options = resp[path]?.map((item) => ({ label: item[label], value: item[value] })) || []
            setData(options)
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "300%",
                width: "100%",
                height: "100%",
                marginTop: 5
            }}
        >
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                sx={{ width: '100%' }}
                options={data}
                onChange={(event, value) => handleChange(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={handleText}
                        // label="search and select"
                        sx={{ background: '#fff' }}
                        placeholder="search and select"
                    />
                )}
            />
        </div >
    )
}