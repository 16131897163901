import React, { useState, useEffect } from 'react';

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress === 100) {
          clearInterval(interval);
          return prevProgress;
        }
        return prevProgress + 1;
      });
    }, 50); // Increase the value (e.g., 50) for a smoother animation

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <div className="range" style={{ '--p': `${progress}` }}>
  <div className="range__label">Progress</div>
</div>
  );
};

export default ProgressBar;