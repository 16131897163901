import { Button } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function ComeBackLater(props) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "70vh",
        alignItems: "center",
        flexDirection: "column",
        paddingRight: "2em",
        paddingLeft: "2em",
      }}
    >
      <h2 style={{ textAlign: "center" }}> {props.msg}</h2>

      <img style={{ maxWidth: "120px" }} src="../assets/calendar.png" alt="" />
      <h3 style={{ textAlign: "center" }}>  {!props.thankMsg ? ("Thanks for your feedback.") : (props.thankMsg)}</h3>
      {!props.nofooter ? (
        <div>
          <div className="powered">
            powered by
            <img src="../assets/extraa_logo.png" alt="" />
          </div>

          <Button
            className="y-btn"
            endIcon={<ChevronRight />}
            onClick={() => {
              const url = 'https://mates.extraa.in/';
              window.open(url, '_blank');
            }}
            fullWidth
            sx={{ marginTop: 4 }}
          >
            {" "}
            Go to your profile
          </Button>
        </div>)
        :
        (!props.survey_id &&
          <Button
            className="y-btn"
            endIcon={<ChevronRight />}
            onClick={() => {
             navigate('/home')
            }}
            fullWidth
            sx={{ marginTop: 4 }}
          >
            {" "}
            View Coupons
          </Button>
        )
        }

    </div>
  );
}
