import { Model, FunctionFactory } from "survey-core";
import { ReactSurveyElementsWrapper, Survey } from "survey-react-ui";
import { useCallback, useEffect, useState, useRef } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import GetSurvey from "../queries/GetSurvey";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ComponentCollection, Serializer } from "survey-core";
import moment from "moment";
import "survey-core/defaultV2.min.css";
import "./CustomSurvey.scss";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Stack,
  Typography,
} from "@mui/material";
import ComeBackLater from "../components/ComeBackLater";
import axios from "axios";
import {
  defaultTheme,
  skodaTheme,
  rotatryTheme,
  lifecellTheme,
  blackOrchidTheme,
  zimsonTheme,
  saiBabaTheme,
} from "../surveyThemes/themes";
import ThankYouPage from "../components/ThankyouPage";
import { getAPI } from "../api/getApi";
import RedirectDailog from "../components/RedirectDialog";
import { citiesInIndia } from "../common/city";
import { state } from "../common/state";
import { Designations, department } from "../common/department";
import { industry } from "../common/industry";
import { merchantCreation } from "../common/merchantCreation";
import { getValue } from "@testing-library/user-event/dist/utils";
import { decodeJWT } from "./UrlToken";
import branchCreation from "../common/branchCreation";
import contactCreation from "../common/contactCreation";
import Pincodevar from "../common/Pincodevar";
import axiosGet from "../api/axiosGet";
import CloseIcon from "@mui/icons-material/Close";
import {
  CrmActivityCreate,
  create_product,
  flattenArray,
  getFreeFillForm,
  getOTP,
  gogasLocation,
  onUploadName,
  send_coins,
  setInitalHeaders,
} from "../common/methods";
import { encode } from "@yag/id-hash";
import { registerCounter } from "../components/Counter";
import { registerSearchDropdown } from "../components/SearchDropdown";
import LogoutIcon from "@mui/icons-material/Logout";
import UseCRMToken from "../queries/GetCrmTokens";
import { registerQrScanner } from "../components/QrScanner";
import { registerCouponsInput } from "../components/CouponInput";

// slk("MmRiY2U1M2YtNzkxZi00MTAzLThiMjYtMmY1MjM0OTA4OGY2OzE9MjAyNC0wNS0xOQ==");
// custom counter question
registerCounter();
// custom search dropdown question
registerSearchDropdown();
// custom Qr scanner
registerQrScanner();
// custom textbox
registerCouponsInput();

function CustomSurvey() {
  const [formDetails, SetFormDetails] = useState([]);
  const [coupons, SetCoupons] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const couponsRef = useRef([]);
  const qrIdRef = useRef(0);
  const surveyIdRef = useRef(0);
  const [formComplete, SetFormComplete] = useState(false);
  const [loading, SetLoading] = useState(true);
  const [alreadyDone, SetAlreadyDone] = useState(false);
  const [banner, SetBanner] = useState("");
  const skodaObj = useRef("");
  const redeemCode = useRef("");
  const accID = useRef("");
  const blackOrchidId = useRef("");
  const loadingRef = useRef(true);
  const already_done = useRef(false);
  const googleRating = useRef("");
  const dataRef = useRef({});
  // const [msgTitle, SetMsgTitle] = useState("");
  const msgTitleRef = useRef("Thank You");
  const statusRef = useRef("");

  const msgRef = useRef("Your feedback is valuable to us");
  const metadataRef = useRef("");
  const redirect = useRef(false);
  // const [msg, SetMsg] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  // const history=useHistory()
  const survey2 = new Model(formDetails);
  const token = localStorage.getItem("token");
  const mtoken = localStorage.getItem("mtoken");
  const acc_id = localStorage.getItem("accid");
  const decode =
    (token &&
      token !== "undefined" &&
      decodeJWT(token)["https://hasura.io/jwt/claims"]["x-hasura-phone"]) ||
    "";
  const accId = queryParams.get("acc_id");
  if (acc_id || accId) {
    const acc_data = UseCRMToken(acc_id || accId);
    // console.log(acc_data, 'data');
    localStorage.setItem("crm_token", acc_data?.token?.directus_token);
    survey2.setValue("station_name", acc_data?.token?.details?.station_name);
    survey2.setValue("city", acc_data?.token?.details?.city);
    survey2.setValue("state", acc_data?.token?.details?.state);
    survey2.setValue("cashback", acc_data?.token?.details?.cashback);
    survey2.setValue("cpil_region", acc_data?.token?.details?.cpil_region);
    survey2.setValue("erp_code", acc_data?.token?.details?.erp_code);
    survey2.setValue("scheme", acc_data?.token?.details?.scheme);
    survey2.setValue("terr_m", acc_data?.token?.details?.terr_m);
    survey2.setValue("sales_name", acc_data?.token?.username);
  }
  // console.log(acc_data,'crmtoken');
  const client = useApolloClient();
  // console.log(formDetails,'token')
  survey2.clearInvisibleValues = false;

  //urlParamsFunction
  const UrlParams = (parameters) => {
    const params = new URLSearchParams(window.location.search);
    parameters.forEach((item) => {
      //console.log(item);
      survey2.setValue(item, params.get(item));
    });
  };
  survey2.setValue("mobile", decode || "");
  const sales_mob = queryParams.get("sales_mob");
  const sales_name = queryParams.get("sales_name");
  // console.log(sales_mob,'val',value);
  const urlParams = new URLSearchParams(window.location.search);
  const queryList = [];
  // console.log(urlParams, 'urls');

  //set answer from url params
  const answerParams = () => {
    for (const [key, value] of urlParams) {
      queryList.push({ key, value });
    }
    // Now, queryList contains the query parameters in the form of a list of objects
    // console.log(queryList, 'list');
  };
  answerParams();

  if (queryList?.length > 0) {
    // console.log('true')
    queryList?.forEach((e) => {
      survey2.setValue(e?.key, e?.value?.replace(/_/g, " "));
    });
  }

  // pothys sales man survey
  const pothysSalesParams = () => {
    //console.log("dsdas")
    survey2.setValue("sales_name", sales_name?.replace(/_/g, " "));
    survey2.setValue("sales_mob", sales_mob);
    survey2.setValue("sales_emp", urlParams.get("sales_emp"));
    survey2.setValue(
      "sales_floor",
      urlParams.get("sales_floor")?.replace(/_/g, " ")
    );
    survey2.setValue(
      "sales_designation",
      urlParams.get("sales_desi")?.replace(/_/g, " ")
    );
  };
  if (urlParams.get("sales_emp") && urlParams.get("sales_emp") !== "") {
    pothysSalesParams();
  }

  if (sales_mob || sales_name) {
    survey2.setValue("mobile", "");
  }
  survey2.setValue(
    "salesname",
    localStorage.getItem("user_name") || sales_name
  );
  survey2.setValue(
    "salesmobile",
    localStorage.getItem("user_ph_number") || sales_mob
  );
  survey2.setValue("account_id", acc_id || accId);
  //hospital
  if (surveyIdRef.current === 193) {
    survey2.clearInvisibleValues = false;
    const parameters = ["city", "hospitalCode", "hospitalName"];
    UrlParams(parameters);
  }
  if (localStorage.getItem("crm_token")) {
    survey2.setValue(
      "crm_token",
      `{Authorization: Bearer ${localStorage.getItem("crm_token")}}`
    );
  }
  //sowbaghya
  // if(surveyIdRef.current===200){
  //   survey2.clearInvisibleValues=false
  //   const parameters=["productCategory","productCode","productDescription"]
  //   UrlParams(parameters);
  // }
  onUploadName(survey2);
  // survey2.onUploadFiles.add((_, options) => {
  //   const filename = options.files[0].name
  //     .replace(/[^a-zA-Z0-9.]/g, "_")
  //     .replace(/\s+/g, "_");
  //   let formData = new FormData();
  //   formData.append("file", options.files[0], filename);

  //   let config = {
  //     method: "POST",
  //     body: formData,
  //     redirect: "follow",
  //   };

  //   fetch("https://storage.extraa.in/upload", config)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       options.callback(
  //         "success",
  //         options.files.map((file) => {
  //           return {
  //             file: file,
  //             content: data.url,
  //           };
  //         })
  //       );
  //     })
  //     .catch((error) => {
  //       console.error("Error: ", error);
  //     });
  // });

  // function setInitalHeaders() {
  //   if (localStorage.token) {
  //     return {
  //       "Content-Type": "application/json",
  //       authorization: "Bearer " + localStorage.token,
  //     };
  //   }

  //   return {
  //     "Content-Type": "application/json",
  //     authorization:
  //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikd1ZXN0IiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImd1ZXN0Il0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6Imd1ZXN0In19.dFW-ZzED-qnnoWVb0r9oZIcmn2gSsBxqBp30BuUz1wk",
  //   };
  // }
  //

  // SEND OTP

  let headers = new Headers();

  // function getOTP([mobile], bool) {
  //   loadingRef.current = true;
  //   // console.log("getting");
  //   if (!mobile) {
  //     this.returnResult(false);
  //     return;
  //   }
  //   // SetphNum(num);
  //   // SetLoading(true);
  //   headers.append("Content-Type", "application/json");

  //   let data = bool
  //     ? {
  //       phone_number: mobile,
  //       qr_id: 1,
  //       skoda: true,
  //     }
  //     : {
  //       phone_number: mobile,
  //       qr_id: 1,
  //     };

  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/get-otp",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       //  console.log(response.data);
  //       localStorage.setItem("otp_id", response.data.insert_otp.id);
  //       localStorage.setItem("user_id", response.data.user.id);
  //       // loadingRef.current = false;
  //       this.returnResult(true);
  //       // SetLoading(false);
  //       // navigate("/verify-otp", {
  //       //   state: {
  //       //     otp_id: response.data.insert_otp.id,
  //       //     user_id: response.data.user.id,
  //       //     phone_number: sender.data.mobile,
  //       //   },
  //       // });
  //     })
  //     .catch(function (error) {
  //       // SetLoading(false);
  //       // this.returnResult(false);
  //     });
  //   loadingRef.current = false;
  //   if (!bool) {
  //     this.returnResult(true);
  //   }
  // }
  //blackORchid OTP
  function getBlackOrchidOTP([mobile], bool) {
    loadingRef.current = true;
    // console.log("getting");
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    // SetphNum(num);
    // SetLoading(true);
    headers.append("Content-Type", "application/json");

    let data = {
      phone_number: mobile,
      qr_id: 1,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/get-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //  console.log(response.data);
        localStorage.setItem("otp_id", response.data.insert_otp.id);
        localStorage.setItem("user_id", response.data.user.id);
        // loadingRef.current = false;
        this.returnResult(true);
        // SetLoading(false);
        // navigate("/verify-otp", {
        //   state: {
        //     otp_id: response.data.insert_otp.id,
        //     user_id: response.data.user.id,
        //     phone_number: sender.data.mobile,
        //   },
        // });
      })
      .catch(function (error) {
        // SetLoading(false);
        this.returnResult(false);
      });
    loadingRef.current = false;
    //this.returnResult(true);
  }

  async function verificationSkodaMember([mobile]) {
    loadingRef.current = true;
    //  console.log("getting");
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    // SetphNum(num);
    // SetLoading(true);
    headers.append("Content-Type", "application/json");

    let data = {
      // phone_number: mobile,
      _phone: mobile.toString(),
    };

    // const response = await axios.post(url, data, { headers });
    const resp = await axios
      .post("https://backend.extraa.in/api/rest/skodagurudev", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
          "x-hasura-admin-secret": "aseraadmin@1234",
        },
      })
      .then(function (response) {
        //  console.log('skoda member res',response.data);
        // loadingRef.current = false;
        return response.data;
      })
      .catch(function (error) {
        // SetLoading(false);
        // this.returnResult(false);
      });

    if (resp.users.length > 0) {
      // setSkodaObj(resp.users[0])
      skodaObj.current = resp.users[0];
      survey2.setValue("name", resp.users[0].name);
      // console.log(resp.users)
      getOTP([mobile], true, loadingRef);
    }
    loadingRef.current = false;
    //  console.log('skoda',resp.users);
    this.returnResult(resp.users.length > 0);
  }
  //BlackOrchid Verification
  async function verificationBlackOrchid([mobile]) {
    //console.log(mobile);
    loadingRef.current = true;
    //  console.log("getting");
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    // SetphNum(num);
    // SetLoading(true);
    headers.append("Content-Type", "application/json");

    let data = {
      // phone_number: mobile,
      phone_number: mobile.toString(),
      survey_id: surveyIdRef.current.toString(),
    };
    const resp = await axios
      .post(
        "https://0kx96rd1z1.execute-api.ap-south-1.amazonaws.com/",
        data
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //    // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
        //     "x-hasura-admin-secret": "aseraadmin@1234",
        //   },
        // }
      )
      .then(function (response) {
        // console.log('skoda member res', response.data);
        // loadingRef.current = false;
        return response.data;
      })
      .catch(function (error) {
        // SetLoading(false);
        // this.returnResult(false);
      });
    // console.log(resp.alreadyDone);
    // console.log(resp);
    if (
      resp?.user?.users.length === 0 ||
      resp?.user?.users[0]?.user_metadata.length === 0
    ) {
      const value =
        resp?.user?.user_metadata[0]?.metadata?.membership_no.split("-") || "";
      blackOrchidId.current =
        (value[1] && `TM-${parseInt(value[1]) + 1}`) || "TM-501";
      survey2.setValue("alreadyDone", false);
      getBlackOrchidOTP([mobile], true);
    } else {
      if (token) {
        survey2.setValue("alreadyDone", true);
        survey2.setValue("name", resp?.user?.name);
      } else {
        survey2.setValue("alreadyDone", "notoken");
        getBlackOrchidOTP([mobile], true);
      }
      survey2.setValue("name", resp?.user?.users[0]?.name);
    }
    // console.log('val', blackOrchidId.current);
    loadingRef.current = false;
    //  console.log('skoda',resp.users);
    this.returnResult(true);
  }
  // AlreadyDone verifiaction using Mobile
  async function verifyAlreadyDone([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    headers.append("Content-Type", "application/json");
    let data = {
      phone_number: mobile.toString(),
      survey_id: surveyIdRef.current.toString(),
    };
    const resp = await axios
      .post("https://0kx96rd1z1.execute-api.ap-south-1.amazonaws.com/", data)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) { });
    // console.log(resp.alreadyDone, 417);
    if (!resp.alreadyDone) {
      // console.log(1);
      survey2.setValue("alreadyDone", false);
    } else {
      // console.log(2);
      survey2.setValue("alreadyDone", true);
    }
    loadingRef.current = false;
    this.returnResult(true);
  }

  //emp number verify
  async function empNumberVerify([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    headers.append("Content-Type", "application/json");
    let data = {
      phone_number: mobile.toString(),
      like: "tvs_id",
      survey_id: surveyIdRef.current,
    };
    const resp = await axios
      .post(
        "https://5sajhu4ngmgialhzcckugjjpji0xoatd.lambda-url.ap-south-1.on.aws/",
        data
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    if (resp?.users?.length > 0 && resp?.survey_responses?.length === 0) {
      getOTP([mobile], true, loadingRef);
      this.returnResult(true);
    } else {
      this.returnResult(false);
    }
    // console.log('verified');
    loadingRef.current = false;
    // this.returnResult(true);
  }

  //emp id verify
  async function empIdVerify([empId]) {
    loadingRef.current = true;
    if (!empId) {
      this.returnResult(false);
      return;
    }
    // console.log('right');
    headers.append("Content-Type", "application/json");
    let data = {
      contains: [{ kauvery_id: empId }],
    };
    const resp = await axios
      .post(
        "https://nlitsxw24q6inqms5hq6jjk32e0qvwcc.lambda-url.ap-south-1.on.aws/",
        data
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    if (resp.users?.length === 0) {
      this.returnResult(false);
    } else {
      this.returnResult(true);
    }
    loadingRef.current = false;
  }

  //emailAuthentication
  async function emailAuthentication([email]) {
    loadingRef.current = true;
    //  console.log("getting");
    const url = `https://m9jjhja4gl.execute-api.ap-south-1.amazonaws.com/GetByjusUsers/${email}`;

    const response = await axiosGet(url, "");
    if (response.body) {
      this.returnResult(true);
      // console.log("success", response);
    } else {
      this.returnResult(false);
      console.log("error");
    }
  }
  async function verifyCouponCode([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    // SetphNum(num);
    // SetLoading(true);
    headers.append("Content-Type", "application/json");
    let found = mobile && mobile.toString().replaceAll('#', '')
    // console.log(mobile, 'val');
    // let couponCode = found ? mobile.slice(1): mobile;
    const couponCode = parseInt(found, 16);
    redeemCode.current = couponCode / 121;
    const authToken =
      mtoken ||
      `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoic3VwZXJhZG1pbiIsImlhdCI6MTY4MDI2MDk1NiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbInN1cGVyYWRtaW4iLCJtZXJjaGFudCJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJzdXBlcmFkbWluIiwieC1oYXN1cmEtdXNlci1pZCI6IjEiLCJ4LWhhc3VyYS1tZXJjaGFudC1pZCI6IjEwNjAifX0.idq3fyqbnemJdsq5IrAhBNc2P5iXic4w6TmRp7sRaxM`;
    let data = { ucoupon_id: couponCode / 121, token: authToken };
    // const response = await axios.post(url, data, { headers });
    const resp = await axios
      .post("https://plswlr52lif4wq2vlvkawvmyum0gcgqh.lambda-url.ap-south-1.on.aws/", data)
      .then(function (response) {
        //  console.log('skoda member res',response.data);
        // loadingRef.current = false;
        return response.data;
      })
      .catch(function (error) {
        // SetLoading(false);
        this.returnResult(false);
      });

    if (resp.user_coupons_by_pk && !resp.user_coupons_by_pk.redeemed &&
      !moment(resp?.user_coupons_by_pk?.coupon?.expiry_date).isSameOrBefore(moment(), 'day')) {
      // console.log(resp.user_coupons_by_pk, "data");
      const { coupon, user } = resp.user_coupons_by_pk;
      const percentage = coupon.offer_title.match(/\d+/g);
      survey2.setValue("offer_amount", percentage);
      survey2.setValue("username", user.name);
      survey2.setValue("phone_number", user.phone_number);
      survey2.setValue("couponName", coupon.name);
      survey2.setValue("offer_title", coupon.offer_title);
      survey2.setValue("subtitle", coupon.offer_subtitle);
      survey2.setValue(
        "metadata",
        `${user?.metadata?.model}, ${user?.metadata?.chassis_no || user?.metadata?.chasiss_no
        }`
      );
      survey2.setValue("account_id", localStorage.getItem("accid"));
      survey2.setValue("merchant_id", localStorage.getItem("mer_id"));
      this.returnResult(true);
    } else {
      this.returnResult(false);
    }
    loadingRef.current = false;
    //  console.log('skoda',resp.users);
  }

  // Verify OTP
  async function verifyOTP([otp]) {
    // SetLoading(true);
    loadingRef.current = true;
    //  console.log("verifyOTP");
    let data = JSON.stringify({
      otp: otp,
      id: localStorage.otp_id,
      user_id: localStorage.user_id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/verify-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const validation = await axios(config)
      .then(function (response) {
        // SetOtpLoading(false);
        // console.log(response);
        // console.log(response.data);
        if (response.data.status) {
          localStorage.setItem("token", response.data.token);
          // localStorage.setItem("user_id", location.state.user_id);

          // this.returnResult(true);
          return true;
        } else {
          // SetOtpError(true);
          // throw new Error(`Invalid OTP`);
          this.returnResult(false);
          return false;
          // return;
        }
      })
      .catch(function (error) {
        // throw new Error(`HTTP error! Status: ${error}`);
      });

    // this.returnResult(validation);

    this.returnResult(validation);
    loadingRef.current = false;
  }
  const today = moment().format("YYYY-MM-DD");
  const { surveyLoading, error, data } = useQuery(GetSurvey, {
    variables: { url: params.survey_url, _gt: today },
    context: {
      headers: setInitalHeaders(),
    },
  });

  // console.log(data, 'data');

  const googleLink =
    (data?.surveys?.length > 0 &&
      data?.surveys[0]?.survey_qrs?.length > 0 &&
      data?.surveys[0]?.survey_qrs[0]?.qr?.spaces_qrs?.length > 0 &&
      data?.surveys[0]?.survey_qrs[0]?.qr?.spaces_qrs[0]?.space
        ?.google_rating_page) ||
    "";
  const qrId =
    (data?.surveys?.length > 0 &&
      data?.surveys[0]?.survey_qrs?.length > 0 &&
      data?.surveys[0]?.survey_qrs[0]?.qr?.id) ||
    null;
  const randomCoupons =
    (data?.surveys?.length > 0 &&
      data?.surveys[0]?.survey_qrs?.length > 0 &&
      data?.surveys[0]?.survey_qrs[0]?.qr?.dist_coupon_amount) ||
    0;
  const qrLocation =
    data?.surveys?.length > 0 &&
    data?.surveys[0]?.survey_qrs?.length > 0 &&
    data?.surveys[0]?.survey_qrs[0]?.qr.spaces_qrs.length > 0 &&
    data?.surveys[0]?.survey_qrs[0]?.qr?.spaces_qrs[0]?.space?.location;
  const industries =
    data?.surveys?.length > 0 && data?.surveys[0]?.merchant?.industry;
  // console.log(googleLink, 'googlelink', data)

  //membership survey function
  async function generalAlreadyDone([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    headers.append("Content-Type", "application/json");

    let data = {
      phone_number: mobile.toString(),
      survey_id: surveyIdRef.current.toString(),
    };
    console.log(data,"data");
    const resp = await axios
      .post(
        "https://kvmcavvzrbqvpifb6e5fv7qmlm0ozlnc.lambda-url.ap-south-1.on.aws/",
        data
      )
      .then(function (response) {
        console.log('skoda member res', response.data);
        // loadingRef.current = false;
        return response.data;
      })
      .catch(function (error) {
        // SetLoading(false);
        // this.returnResult(false);
      });
    // console.log(resp.alreadyDone);
    // console.log(resp);
    if (!resp?.alreadyDone) {
      survey2.setValue("alreadyDone", false);
      getBlackOrchidOTP([mobile], true);
    } else {
      if (token) {
        survey2.setValue("alreadyDone", true);
        survey2.setValue("name", resp?.user?.name);
        survey2.setValue("mobile",data?.phone_number);
      } else {
        survey2.setValue("alreadyDone", "notoken");
        getBlackOrchidOTP([mobile], true);
      }
      survey2.setValue("name", resp?.user?.users[0]?.name);
      survey2.setValue("mobile", data?.phone_number);
    }
    loadingRef.current = false;
    this.returnResult(true);
  }

  async function gogasUserValidate([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    // console.log('right');
    headers.append("Content-Type", "application/json");

    let data = {
      phone_number: mobile.toString(),
      // "coin_type": 7
    };

    if (/^[a-zA-Z]/.test(mobile)) {
      data = {
        "vehicle_number": mobile.toString()
      }
    }

    const resp = await axios
      .post(
        "https://v7wrknevmzeste3irdoe4ih6lq0noxkm.lambda-url.ap-south-1.on.aws/",
        data
      )
      .then(function (response) {
        // console.log(response, 'resp');
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    // if (resp.wallet?.length === 0) {
    //   this.returnResult(false);
    // } else {
    //   const userId = resp.wallet?.length > 0 && resp?.wallet[0]?.user?.id
    //   const metadata = resp.wallet?.length > 0 && resp?.wallet[0]?.metadata?.firstPurchase || ''
    //   survey2.setValue("user_id", userId);
    //   survey2.setValue("firstPurchase", metadata);
    //   survey2.setValue("name", resp.wallet?.length > 0 && resp?.wallet[0]?.user?.name);
    //   survey2.setValue("balance", resp.wallet?.length > 0 && resp?.wallet[0]?.balance || 0);
    //   this.returnResult(true);
    // }
    // console.log(resp, 'resp');
    if (Array.isArray(resp) && resp?.length > 0) {
      survey2.setValue("mobile", resp[0]?.phone_number)
      survey2.setValue("vehicleNumber", resp[0]?.vehicle_number)
    } else {
      survey2.setValue("regUser", "false");
    }
    this.returnResult(true);
    loadingRef.current = false;
  }

  async function gogasUserCheck([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    headers.append("Content-Type", "application/json");
    let data = {
      phone_number: mobile.toString(),
      survey_id: surveyIdRef.current,
    };
    // console.log(data, 'variables');
    const resp = await axios
      .post(
        "https://krr53agste43dqselzjojloeiu0webjt.lambda-url.ap-south-1.on.aws/",
        data
      )
      .then(function (response) {
        // console.log(response, 'resp');
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    if (resp.alreadyDone) {
      this.returnResult(false);
    } else {
      this.returnResult(true);
    }

    loadingRef.current = false;
  }
  // Rotary Attedance check using QR's
  async function rotaryCheckIn([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    headers.append("Content-Type", "application/json");
    let data = {
      "phone_number": mobile.toString(),
      'survey_id': surveyIdRef.current
    }
    const resp = await axios.post("https://5dsaijojtdimpcf7de3zzpzx3e0ilfba.lambda-url.ap-south-1.on.aws/", data)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    // console.log(resp, 'response');
    if (resp?.error) {
      this.returnResult(false);
    }
    else {
      this.returnResult(true);
      loadingRef.current = false;
    }
  }
  // AMKM user validation using 
  async function amkmUserValidaton([params]) {
    const [mobile, surveyId] = params
    loadingRef.current = true;

    if (!mobile) {
      this.returnResult(false);
      return;
    }
    headers.append("Content-Type", "application/json");
    let data = {
      "phone_number": mobile.toString(),
      'survey_id': surveyId.toString()
    }
    const resp = await axios.post("https://5dsaijojtdimpcf7de3zzpzx3e0ilfba.lambda-url.ap-south-1.on.aws/", data)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    // console.log(resp, 'response');
    if (resp?.error) {
      this.returnResult(false);
    }
    else {
      this.returnResult(true);
      loadingRef.current = false;
    }
  }

  // Jainism AMKM UserInsert
  async function amkmJainismUserInsert(results) {
    loadingRef.current = true;
    headers.append("Content-Type", "application/json");
    let data = {
      "username": results?.name,
      "age": results?.age,
      "gender": results?.gender,
      "language": results?.language,
      "phone": results?.mobile,
      "password": results?.password,
      "area": results?.area
    }
    const url = "https://lu4psnwbmphf75oi62xkttibqq0iiamo.lambda-url.ap-south-1.on.aws/";
    const resp = await axios.post(url, data)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    console.log(resp, 'response')
  }
  async function gogasUserRedeem([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    // console.log('right');
    headers.append("Content-Type", "application/json");
    let data = {
      phone_number: mobile.toString(),
      coin_type: 7,
    };
    const resp = await axios
      .post(
        "https://v7wrknevmzeste3irdoe4ih6lq0noxkm.lambda-url.ap-south-1.on.aws/",
        data
      )
      .then(function (response) {
        // console.log(response, 'resp');
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    if (resp.wallet?.length === 0) {
      this.returnResult(false);
    } else {
      const userId = resp.wallet?.length > 0 && resp?.wallet[0]?.user?.id;
      const metadata =
        (resp.wallet?.length > 0 && resp?.wallet[0]?.metadata?.redeemDate) ||
        "";
      const givenDate = moment(metadata);
      const currentDate = moment().startOf("day");
      survey2.setValue("user_id", userId);
      survey2.setValue(
        "name",
        (resp.wallet?.length > 0 && resp?.wallet[0]?.user?.name) || ""
      );
      if (givenDate.isSameOrBefore(currentDate, "day")) {
        // console.log("The given date is today.");
        const futureDate = moment().add(14, "days");
        const formattedFutureDate = futureDate.format("YYYY-MM-DD");
        survey2.setValue("redeemDate", formattedFutureDate);
        survey2.setValue(
          "wallet_id",
          resp.wallet?.length > 0 && resp?.wallet[0]?.id
        );
        survey2.setValue("redeemDate", formattedFutureDate);
        survey2.setValue(
          "amount",
          (resp.wallet?.length > 0 &&
            Math.floor(resp?.wallet[0]?.balance / 2)) ||
          0
        );
        survey2.setValue(
          "balance",
          (resp.wallet?.length > 0 && resp?.wallet[0]?.balance) || 0
        );
        this.returnResult(true);
      } else {
        // console.log("The given date is not today.");
        this.returnResult(false);
      }
    }
    loadingRef.current = false;
  }

  async function jolieUserCheck([mobile]) {
    loadingRef.current = true;
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    headers.append("Content-Type", "application/json");
    let data = {
      phone_number: mobile.toString(),
    };
    // console.log(data, 'variables');
    const resp = await axios
      .post(
        "https://4joi665nubluaetrd5h4kbp4rq0ycwup.lambda-url.ap-south-1.on.aws/",
        data
      )
      .then(function (response) {
        // console.log(response, 'resp');
        return response.data;
      })
      .catch(function (error) {
        this.returnResult(false);
      });
    // console.log(resp, 'response');
    if (resp?.error) {
      this.returnResult(false);
    } else {
      if (resp?.message === "Jolie_Member") {
        survey2.setValue("member_name", resp?.user?.name);
        survey2.setValue("member_no", resp?.user?.phone_number);
      }
      if (resp?.message === "Jolie_Guest") {
        survey2.setValue("guest_name", resp?.user?.name);
        survey2.setValue("guest_no", resp?.user?.phone_number);
        survey2.setValue(
          "referral_name",
          (resp?.user?.survey_responses?.length > 0 &&
            resp?.user?.survey_responses[0]?.response_details?.referral_name) ||
          ""
        );
        survey2.setValue(
          "referral_no",
          (resp?.user?.survey_responses?.length > 0 &&
            resp?.user?.survey_responses[0]?.response_details?.referral_no) ||
          ""
        );
      }
      this.returnResult(true);
    }

    loadingRef.current = false;
  }

  FunctionFactory.Instance.register("getOTP", getOTP, true);
  // FunctionFactory.Instance.register("getOTP", getOTP, true);
  FunctionFactory.Instance.register("empNumberVerify", empNumberVerify, true);
  FunctionFactory.Instance.register("empIdVerify", empIdVerify, true);
  FunctionFactory.Instance.register("verifyCouponCode", verifyCouponCode, true);
  FunctionFactory.Instance.register(
    "generalAlreadyDone",
    generalAlreadyDone,
    true
  );
  FunctionFactory.Instance.register(
    "verificationSkodaMember",
    verificationSkodaMember,
    true
  );
  //blackorchid
  FunctionFactory.Instance.register(
    "verificationBlackOrchid",
    verificationBlackOrchid,
    true
  );
  //emailAuth
  FunctionFactory.Instance.register(
    "emailAuthentication",
    emailAuthentication,
    true
  );
  //verifyAlreadyDone
  FunctionFactory.Instance.register(
    "verifyAlreadyDone",
    verifyAlreadyDone,
    true
  );
  FunctionFactory.Instance.register("verifyOTP", verifyOTP, true);
  FunctionFactory.Instance.register(
    "gogasUserValidate",
    gogasUserValidate,
    true
  );
  FunctionFactory.Instance.register("gogasUserRedeem", gogasUserRedeem, true);
  FunctionFactory.Instance.register("gogasUserCheck", gogasUserCheck, true);
  FunctionFactory.Instance.register("jolieUserCheck", jolieUserCheck, true);
  FunctionFactory.Instance.register("rotaryCheckIn", rotaryCheckIn, true);
  FunctionFactory.Instance.register("amkmUserValidaton", amkmUserValidaton, true);
  // Submit API
  async function submitAPI(
    phone,
    gender,
    name,
    email,
    dob,
    ageRange,
    results,
    metadata,
    surveyResponse,
    pincode,
    area,
    sales_mob
  ) {
    try {
      let req = localStorage.getItem("mtoken")
        ? // && surveyIdRef.current === 276
        {
          qr_id: qrIdRef.current,
          phone_number: phone.toString(),
          gender: gender,
          name: name,
          email: email,
          dob: dob,
          age_range: ageRange.toString(),
          survey_id: surveyIdRef.current.toString(),
          response_details: results,
          coupons: couponsRef.current,
          meta: metadata,
          // token: localStorage.getItem("token") || "",
          survey_responses: surveyResponse,
          allow_multiple_response: already_done.current,
        }
        : {
          qr_id: qrIdRef.current,
          phone_number: phone.toString(),
          gender: gender,
          name: name,
          email: email,
          dob: dob,
          age_range: ageRange.toString(),
          survey_id: surveyIdRef.current.toString(),
          response_details: results,
          coupons: couponsRef.current,
          meta: metadata,
          token: localStorage.getItem("token") || "",
          survey_responses: surveyResponse,
          allow_multiple_response: already_done.current,
        };
      const BlackReq =
        blackOrchidId.current !== ""
          ? {
            qr_id: qrIdRef.current,
            phone_number: phone.toString(),
            gender: gender,
            name: name,
            email: email,
            dob: dob,
            age_range: ageRange.toString(),
            survey_id: surveyIdRef.current.toString(),
            response_details: results,
            coupons: couponsRef.current,
            meta: metadata,
            // token: localStorage.getItem("token") || "",
            survey_responses: surveyResponse,
            allow_multiple_response: already_done.current,
            metaJson: {
              membership_no: blackOrchidId.current,
            },
          }
          : {
            qr_id: qrIdRef.current,
            phone_number: phone.toString(),
            gender: gender,
            name: name,
            email: email,
            dob: dob,
            age_range: ageRange.toString(),
            survey_id: surveyIdRef.current.toString(),
            response_details: results,
            coupons: couponsRef.current,
            meta: null,
            // token: localStorage.getItem("token") || "",
            survey_responses: surveyResponse,
            allow_multiple_response: already_done.current,
          };

      const mmReq = {
        location: dataRef.current?.locations,
        randomCoupons: dataRef.current?.randomCoupons || "",
        industry: dataRef.current?.industries || "",
        qr_id: qrIdRef.current,
        phone_number: phone.toString(),
        gender: gender,
        name: name,
        email: email,
        dob: dob,
        age_range: ageRange.toString(),
        survey_id: surveyIdRef.current.toString(),
        response_details: results,
        coupons: couponsRef.current,
        meta: metadata,
        token: localStorage.getItem("token") || "",
        survey_responses: surveyResponse,
        allow_multiple_response: already_done.current,
      };
      const request = {
        176: BlackReq,
        286: mmReq,
        297: mmReq,
        300: mmReq,
        302: mmReq,
        304: mmReq,
        306: mmReq,
        307: mmReq,
        309: mmReq,
        310: mmReq,
      };
      req =
        (sales_mob?.length > 0 && {
          qr_id: qrIdRef.current,
          phone_number: phone.toString(),
          gender: gender,
          name: name,
          email: email,
          dob: dob,
          age_range: ageRange.toString(),
          survey_id: surveyIdRef.current.toString(),
          response_details: results,
          coupons: couponsRef.current,
          meta: metadata,
          survey_responses: surveyResponse,
          allow_multiple_response: already_done.current,
        }) ||
        req;
      // console.log(request[surveyIdRef.current],'request')
      const res = await axios({
        // url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/survey/submit",
        url: "https://6j8wmnnyoa.execute-api.ap-south-1.amazonaws.com/SubmitAndCoupon",
        data: request[surveyIdRef.current] || req,
        // data: mmReq,
        method: "post",
        timeout: 9000,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        // test for status you want, etc
        //  console.log(res.status);
      }
      // Don't forget to return something
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  // Coupon redeem submit
  const couponRedeemSubmit = async (acc_id) => {
    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSZ");
    const url = "https://backend.extraa.in/api/rest/redeem-coupon";
    const authToken =
      mtoken ||
      `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoic3VwZXJhZG1pbiIsImlhdCI6MTY4MDI2MDk1NiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbInN1cGVyYWRtaW4iLCJtZXJjaGFudCJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJzdXBlcmFkbWluIiwieC1oYXN1cmEtdXNlci1pZCI6IjEiLCJ4LWhhc3VyYS1tZXJjaGFudC1pZCI6IjEwNjAifX0.idq3fyqbnemJdsq5IrAhBNc2P5iXic4w6TmRp7sRaxM`;
    const variables = {
      ucoupon_id: redeemCode.current,
      redeemed_at: currentDateTime,
      redeemed_by: acc_id || 1,
    };
    const headers = {
      "Content-Type": "application/json",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoic3VwZXJhZG1pbiIsImlhdCI6MTcxODM1ODA4OCwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbInN1cGVyYWRtaW4iLCJtZXJjaGFudCJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJzdXBlcmFkbWluIiwieC1oYXN1cmEtdXNlci1pZCI6IjEiLCJ4LWhhc3VyYS1tZXJjaGFudC1pZCI6IjEwNjAifX0.Cc9meYUA3uyM6XcGwloAsVRk3RQZQcN9f6shBqLUM0`,
      Authorization: `Bearer ${authToken}`,
      // "x-hasura-admin-secret": "aseraadmin@1234",
    };
    const response = await getAPI(url, variables, headers);
    return response;
  };



  //merchant_Create
  const merchant_Create = async (results) => {
    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSZ");
    const url = "https://backend.extraa.in/api/rest/create-merchant";
    const variables =
      // ucoupon_id: redeemCode.current,
      // redeemed_at: currentDateTime,
      merchantCreation(results);
    // console.log(results)
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
      "x-hasura-admin-secret": "aseraadmin@1234",
    };
    const response = await getAPI(url, variables, headers);
    return response;
  };
  //Branch Create
  const branch_Create = async (results) => {
    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSZ");
    const url = "https://backend.extraa.in/api/rest/create-branch";
    const variables =
      // ucoupon_id: redeemCode.current,
      // redeemed_at: currentDateTime,
      branchCreation(results);
    // console.log(results)
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
      "x-hasura-admin-secret": "aseraadmin@1234",
    };
    const response = await getAPI(url, variables, headers);
    return response;
  };
  //Contact_Create
  const contact_Create = async (results) => {
    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSZ");
    const url = "https://backend.extraa.in/api/rest/create-contact";
    const variables =
      // ucoupon_id: redeemCode.current,
      // redeemed_at: currentDateTime,
      contactCreation(results);

    // console.log(results)
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
      "x-hasura-admin-secret": "aseraadmin@1234",
    };
    const response = await getAPI(url, variables, headers);
    return response;
  };
  //pincode
  const pincodeFetch = async (pincode, area) => {
    const url = `https://jx1bl3imuc.execute-api.ap-south-1.amazonaws.com/locations/${pincode}`;

    const response = await axiosGet(url, "");
    let error = "Object not found";
    const pincodeId = response.find((item) => item.id === area);
    if (pincodeId) {
      const locationData = {
        lat: pincodeId.lat,
        long: pincodeId.long,
        area: pincodeId.area,
        city: pincodeId.city,
        pincode: pincodeId.pincode,
        id: pincodeId.id,
      };
      return locationData;
    } else {
      return error;
    }
  };
  //MM Motors
  // if (surveyIdRef.current === 276) {
  // }
  //  --------------------------------------------------------- SUBMIT FEEDBACK ---------
  const submitSurvey = useCallback(async (sender) => {
    // loadingRef.current = true;
    let results = sender.data;
    // console.log(sender)
    // alert(results);
    if (skodaObj.current) {
      results = {
        ...sender.data,
        ...skodaObj.current.metadata,
        name: skodaObj.current.name,
      };
    }
    const name = results.name || skodaObj.current.name || "";
    const phone = results.mobile || "";
    const gender = results.gender;
    const email = results.email;
    const dob = results.dob;
    const age = moment().diff(dob, "years");
    const surveyResponse = {
      name: skodaObj.current.name,
      ...skodaObj.current.metadata,
    };
    const pincode =
      results.pincode || results.merchantPincode || results.branchPincode;
    const area = results.area || results.merchantArea || results.branchArea; //areaCode
    //console.log(results);
    let ageRange;

    if (age < 20) {
      ageRange = 0;
    } else if (age >= 20 && age < 40) {
      ageRange = 1;
    } else if (age >= 40 && age < 60) {
      ageRange = 2;
    } else {
      ageRange = 3;
    }

    // console.log(name, ageRange, gender, dob, phone, email);
    // console.log(survey2);

    SetLoading(true);
    if (results.coupon_code) {
      accID.current = sender.data?.account_id || 0;
      const result = await couponRedeemSubmit(accID.current);
      //  console.log(result,'result')
    }
    if (surveyIdRef.current == 1211) {
      const response = await amkmJainismUserInsert(results)
      console.log(response);
    }
    // to send email on survey submit 
    // create field in the survey with name
    // sendEmail and let the default value be true
if(results.sendEmail){
  
  const sendMailResult= await getAPI("https://6tgvrplkxbg2bif6vrv5gxdofe0wrver.lambda-url.ap-south-1.on.aws/",results);
}
    loadingRef.current = true;
    let sub = await submitAPI(
      phone,
      gender,
      name,
      email,
      dob,
      ageRange,
      results,
      metadataRef.current,
      surveyResponse,
      pincode,
      area, //areaCode
      results?.salesmobile
    );
    // creating the token for user without otp @texvalley
    // console.log(sub,'sub');
    const outputJson = sub?.output && JSON.parse(sub?.output);
    const userToken =
      (outputJson?.length > 0 && outputJson[0].body?.token) ||
      (outputJson?.length > 0 && outputJson[1]?.body?.token) ||
      "";
    const userID =
      (outputJson?.length > 0 && outputJson[0].body?.user?.id) ||
      (outputJson?.length > 0 && outputJson[1]?.body?.user?.id) ||
      "";
    // console.log(outputJson[1]?.body.user.id);
    //retriving existing userid &token from resposne @texvalley
    // const existingUserToken=outputJson[0]?.token
    // const existingUser_UserId=outputJson[0]?.user_id
    // console.log(sales_mob,'teste', !sales_mob);
    if (!token && !mtoken && !sales_mob) {
      localStorage.setItem("token", userToken);
      localStorage.setItem("user_id", userID);
      // ||existingUserToken
      // ||existingUser_UserId
    }
    // else if (token||mtoken){

    // }

    //pincode
    if (pincode) {
      // console.log(pincode);
      // console.log(area); //areaCode
      const location = await pincodeFetch(pincode, area);
      // console.log(location, "location");
      //  console.log(results,"Results Without LOC");
      //results=[...results,...res]
      results = { ...sender.data, location: location };
      // console.log(results, "Results with LOC");
    }

    //order create API
    if (results?.form_type === "purchase") {
      await order_create(results);
    }

    //crm create API
    if (results?.type === "crm") {
      // console.log(results, 'results');
      CrmActivityCreate(results);
    }
    //product create
    else if (results?.type === "products") {
      create_product(results);
    } else if (results?.type === "gogas") {
      await send_coins(results);
    } else if (results?.type === "gogasAccount") {
      await gogasLocation(results);
    }
    //merchat_Create
    if (surveyIdRef.current === 149) {
      if (results.question1 === "Add a Branch") {
        //console.log(results.question1)
        //console.log(results)
        branch_Create(results);
      } else if (results.question1 === "Add New Merchant") {
        merchant_Create(results);
      } else if (results.question1 === "Add a Contact") {
        //console.log(results);
        contact_Create(results);
      }
    }
    //pincode
    sub = (sub?.output && JSON.parse(sub?.output)) || null;
    // console.log(sub[0], "sub");
    if (results.rating && googleRating.current !== "") {
      if (results.rating > 3) {
        window.location.href = googleRating.current || "";
      } else if (sub[0]?.alreadyDone) {
        SetAlreadyDone(true);
      } else {
        navigate("/home", {
          state: {
            couponNum: 2,
          },
        });
      }
    }
    if (sub?.length > 0 && sub[0]?.alreadyDone) {
      SetAlreadyDone(true);
      // console.log(sub?.body?.alreadyDone,'alreaady done')
    } else {
      SetFormComplete(true);
      // const couponCount = sub[1]?.body?.insert_user_coupons?.returning.length
      // onCouponsDone(couponCount, results?.rating)
    }
    if (sub?.hasOwnProperty("survey")) {
      SetFormComplete(true);
      survey2.doComplete();
    }

    SetLoading(false);
    loadingRef.current = false;
    //  localStorage.removeItem("link");
    // survey2.doComplete();
    // formComplete.current++;
  }, []);

  const order_create = async (items) => {
    // console.log(items, "order create items");
    let variables;
    if (items?.product_id) {
      // console.log(true);
      variables = {
        amount: items?.total_amount || 0,
        name: items?.name || "",
        email: items?.email || "",
        phone_number: items?.mobile || "",
        pincode: items?.pincode || "",
        state: items?.order_status || "",
        quantity: items?.quantity || "",
        payment_mode: items?.payment_type || "",
        product_id: items?.product_id || 0,
        get_link: false,
      };
    } else {
      const keysWithProductId = Object.keys(items).filter((key) =>
        key.includes("product_id")
      );
      const product_id = keysWithProductId.map((x) => {
        const cleanedProductId = x.replace("product_id_", "");
        return {
          product_variant_id: cleanedProductId,
          quantity: items[`product_id_${cleanedProductId}`],
        };
      });
      variables = {
        amount: items?.total_amount || 0,
        name: items?.name || "",
        email: items?.email || "",
        phone_number: items?.mobile || "",
        pincode: items?.pincode || "",
        state: items?.order_status || "",
        payment_mode: items?.payment_type || "",
        orderProducts: product_id,
        get_link: false,
      };
      // console.log(false, product_id,'product')
    }
    const url =
      "https://iulp7q2dvlrtswa66xmwf62t240qqynx.lambda-url.ap-south-1.on.aws/";
    const res = await getAPI(url, variables);
    const encoded = encode(res?.data?.order_id * 11111);
    const desc = `Your+order+number+${res?.data?.order_id}+of+amount+Rs.${items?.total_amount || 0
      }+has+been+placed+successfully`;
      const product_name=res?.data?.product_name;
    const mailVariables = {
      order_id: res?.data?.order_id.toString(),
      email: items?.email,
      order_link: `https://deals.extraa.in/od/${encoded}`,
    };
    await axiosGet(
      `https://api.growaasan.com/api/sendPosCommunication?clietnId=100365&authKey=Q0ptUDJ1dTlzZFYrd0RMUFR6aU5OUT09&communicationType=2&waTemplateName=deals_confirmation&waTemplateLang=en&country_code=91&customerMobile=${items?.mobile}&varCount=3&var1=${res?.data?.order_id||""}&var2=${product_name||""}&var3=${encoded}`
    );
    await axiosGet(
      `https://api.growaasan.com/api/sendPosCommunication?varCount=1&var1=${res?.data?.order_id}&var2=${encoded}&clietnId=100365&authKey=Q0ptUDJ1dTlzZFYrd0RMUFR6aU5OUT09&communicationType=1&smsTemplateId=1707170558637253609&country_code=91&customerMobile=${items?.mobile}&varCount=2`
    );
    if (items?.email?.length > 0) {
      await getAPI(
        "https://6tgvrplkxbg2bif6vrv5gxdofe0wrver.lambda-url.ap-south-1.on.aws/",
        mailVariables
      );
    }
    // console.log(res?.data?.order_id, 'result', encoded);
  };

  useEffect(() => {
    const currentRoute = location.pathname;
    if (data && data.surveys[0] && data.surveys[0].form_details) {
      // console.log(data.surveys[0]?.m_login,'data');

      // SetBanner(data.surveys[0]?.banner);
      statusRef.current = data.surveys[0]?.allow_multiple_responses;
      SetCoupons(data.surveys[0].survey_coupons);
      couponsRef.current = data.surveys[0].survey_coupons;
      // console.log(data.surveys[0].survey_coupons);
      SetBanner(data.surveys[0]?.banner || data.surveys[0]?.merchant?.logo);
      if (data.surveys[0]?.banner || data.surveys[0]?.merchant?.logo) {
        survey2.title = "";
      }
      already_done.current =
        data?.surveys?.length > 0 && data?.surveys[0]?.allow_multiple_responses;
      // console.log(data.surveys[0]?.metadata, 'flag', data.surveys[0].id);
      googleRating.current = googleLink || "";
      metadataRef.current = data.surveys[0]?.metadata;
      msgTitleRef.current = data.surveys[0]?.message_title;
      msgRef.current = data.surveys[0]?.message;
      qrIdRef.current = data.surveys[0].qr_id || qrId;
      surveyIdRef.current = data.surveys[0].id;
      dataRef.current = {
        locations: qrLocation || "",
        randomCoupons: randomCoupons || "",
        industries: industries,
      };
      SetFormDetails(data.surveys[0]?.form_details);
      SetLoading(false);

      const formArr = data.surveys[0]?.form_details;
      const cleanArr =
        formArr &&
        formArr.pages.map((i) => {
          return i.elements.map((x) => {
            return { name: x.name };
          });
        });
      const finalArr = flattenArray(cleanArr);
      const findIndex = finalArr.findIndex((x) => x.name === "mobile");
      if (
        !token &&
        findIndex === -1 &&
        data &&
        data.surveys[0] &&
        !data.surveys[0].m_login
      ) {
        if (surveyIdRef.current === 18) {
          navigate("/signup", { state: { url: currentRoute } });
        } else if (token === "undefined") {
          navigate("/", { state: { url: currentRoute } });
        } else {
          navigate("/", { state: { url: currentRoute } });
        }
      }
      if (data && data.surveys[0] && data.surveys[0].m_login) {
        const mtoken = localStorage.getItem("mtoken");
        if (!mtoken && !sales_mob) {
          navigate("/mlogin", { state: { url: currentRoute } });
        }
      }
      //   setBoxData(data.boxes[0]);
    } else if (token === "undefined") {
      navigate("/", { state: { url: currentRoute } });
    }
    // localStorage.setItem("link", googleLink);
  }, [surveyLoading, error, data]);
  //  preFillMethod @texvalley
  survey2.getValue("pre_fill_id") &&
    getFreeFillForm(
      survey2,
      client,
      token,
      survey2.getValue("pre_fill_id"),
      decode
    );
  // function flattenArray(nestedArray) {
  //   const flattened = [];

  //   function flatten(arr) {
  //     arr.forEach((item) => {
  //       if (Array.isArray(item)) {
  //         flatten(item);
  //       } else {
  //         flattened.push(item);
  //       }
  //     });
  //   }

  //   flatten(nestedArray);

  //   return flattened;
  // }

  // let fd = formDetails;
  loadingRef.current = false;
  // useEffect(() => {
  //   if (formComplete) {
  //     survey2.doComplete();
  //   }
  // }, [formComplete]);

  // ----------------- SET API VALUES // NOT PRODUCTION READY --------------------
  // survey2.setValue("name", "MY NAME");
  // survey2.setValue("mobile", "8056299487");
  // survey2.setValue("gender", "Item 1");
  // survey2.setValue("dob", "1994-08-23");
  // survey2.setValue("email", "email@email.com");

  // ---------------------------------------------------------
  survey2.showProgressBar = "top";

  // survey id and theme map
  const thememap = {
    29: skodaTheme,
    31: skodaTheme,
    37: skodaTheme,
    66: rotatryTheme,
    193: lifecellTheme,
    176: blackOrchidTheme,
    154: blackOrchidTheme,
    433: zimsonTheme,
    ...Object.fromEntries(
      [
        477, 478, 498, 499, 548, 550, 552, 554, 556, 558, 560, 562, 564, 566,
        568, 570, 571, 573, 575, 577, 579581, 583, 585, 587, 589, 591, 593, 595,
        597, 599, 601, 603, 60, 607, 609, 611, 613, 615, 617, 619, 621, 623,
        625,
      ].map((key) => [key, saiBabaTheme])
    ),
  };

  if (!Serializer.findClass("states")) {
    ComponentCollection.Instance.add({
      name: "states",
      title: "State",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a State...",
        choices: state,
      },
    });
  }

  if (!Serializer.findClass("cities")) {
    ComponentCollection.Instance.add({
      name: "cities",
      title: "City",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a City...",
        choices: citiesInIndia,
      },
    });
  }

  if (!Serializer.findClass("industry")) {
    ComponentCollection.Instance.add({
      name: "industry",
      title: "Industry",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a Industry...",
        choices: industry,
      },
    });
  }

  if (!Serializer.findClass("department")) {
    ComponentCollection.Instance.add({
      name: "department",
      title: "Office Department",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a department...",
        choices: department,
      },
    });
  }

  if (!Serializer.findClass("designations")) {
    ComponentCollection.Instance.add({
      name: "designations",
      title: "Office Designations",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a designation...",
        choices: Designations,
      },
    });
  }
  //pincode
  if (!Serializer.findClass("pincode")) {
    ComponentCollection.Instance.add({
      name: "pincode",
      title: "pincode",
      // The actual question that will do the job
      questionJSON: {
        type: "text",
        name: "pincode",
        placeholder: "Enter Pincode",
        isRequired: "true",
        requiredErrorText: "Field cannot be empty",
        min: 99999,
        max: 999999,
        maxLength: 6,
        validators: [
          {
            type: "regex",
            text: "Enter Valid Pincode",
            regex: "^[0-9]{6}$",
          },
        ],
      },
    });
  }
  if (!Serializer.findClass("mobile")) {
    ComponentCollection.Instance.add({
      name: "Mobile",
      title: "Mobile Number",
      // The actual question that will do the job
      questionJSON: {
        name: "mobile",
        title: "title",
        type: "text",
        inputType: "tel",
        placeholder: "Enter Mobile Number...",
        isRequired: "true",
        requiredErrorText: "Field cannot be empty",
        maxLength: 10,
        validators: [
          {
            type: "regex",
            text: "Enter Valid Mobile Number",
            regex: "^[0-9]{10}$",
          },
        ],
      },
    });
  }
  if (!Serializer.findClass("otp")) {
    ComponentCollection.Instance.add({
      name: "otp",
      title: "otp",
      // The actual question that will do the job
      questionJSON: {
        name: "otp",
        title: "otp",
        type: "text",
        inputType: "tel",
        placeholder: "Enter OTP...",
        isRequired: "true",
        requiredErrorText: "Field cannot be empty",
        maxLength: 4,
        validators: [
          {
            type: "regex",
            text: "Enter Valid OTP",
            regex: "^[0-9]{4}$",
          },
        ],
      },
    });
  }

  const currentRoute = window.location.pathname;

  // const currentRoute = window.location.pathname;

  // console.log("Current Route:", currentRoute);

  // const dynamicManifest = generateDynamicManifest(currentRoute);

  // useEffect(() => {
  //   // Create a JSON blob of the manifest data
  //   const manifestBlob = new Blob([JSON.stringify(dynamicManifest)], {
  //     type: "application/json",
  //   });

  //   // Create a URL for the blob
  //   const manifestUrl = URL.createObjectURL(manifestBlob);

  //   // Set the dynamic manifest URL in the document head
  //   const link = document.createElement("link");
  //   link.setAttribute("rel", "manifest");
  //   link.setAttribute("href", manifestUrl);
  //   document.head.appendChild(link);

  //   return () => {
  //     // Clean up when the component is unmounted
  //     document.head.removeChild(link);
  //     URL.revokeObjectURL(manifestUrl);
  //  //   window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
  //   };
  // }, [dynamicManifest]);

  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    // console.log('in use') // This should appear in the console
    const handleBeforeInstallPrompt = (event) => {
      // console.log('handler')
      event.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(event);
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const onClick = (e) => {
    if (promptInstall) {
      // Show the browser's installation prompt
      promptInstall.prompt();

      // Wait for the user's choice
      promptInstall.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the installation");
        } else {
          console.log("User declined the installation");
        }

        // Reset the deferredPrompt variable
        promptInstall = null;
      });
      setSupportsPWA(false);
    }
  };

  const onLogoutClick = () => {
    // console.log('clicked');
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div
      className="csv-survey"
      style={thememap[surveyIdRef.current] || defaultTheme}
    >
      {/* <SurveyComponent /> */}
      {/* {data?.surveys?.length > 0 && data?.surveys[0]?.m_login && */}
      <div className={banner ? "logout" : "without_banner"}>
        <div className="box-2" onClick={onLogoutClick}>
          <div className="btn btn-two">
            <LogoutIcon />
            <p>log out</p>
          </div>
        </div>
      </div>
      {banner && (
        <>
          <div style={{ width: "100%" }}>
            <Stack
              alignItems="center"
              style={{ width: "100%", background: "var(--banner-bg)" }}
            >
              <Box maxWidth="600px" width="100%">
                <img
                  style={{
                    width: "100%",
                    maxHeight: 300,
                    objectFit: "contain",
                  }}
                  src={banner}
                  alt=""
                />
              </Box>
            </Stack>
          </div>
        </>
      )}
      {formDetails &&
        !loading &&
        survey2 &&
        !alreadyDone &&
        !formComplete &&
        !loadingRef.current && (
          <>
            <Survey
              model={survey2}
              showCompletedPage={false}
              onComplete={submitSurvey}
            />
            {(currentRoute === "/csv/test_survey_state_survey" ||
              currentRoute === "/csv/pothys_test_survey") &&
              supportsPWA && (
                <div className="installDiv">
                  <CloseIcon
                    fontSize="small"
                    onClick={() => setSupportsPWA(false)}
                  />
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: "rota-black",
                        marginRight: 2,
                      }}
                    >
                      Get our app for a better experience
                    </Typography>
                    <button className="insBtn" onClick={(e) => onClick(e)}>
                      Install Now
                    </button>
                  </Stack>
                </div>
              )}
          </>
        )}
      {(loading || loadingRef.current) && (
        <Fade in>
          <div
            style={{
              width: "100%",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // background: "#FFF7DF",
            }}
          >
            <CircularProgress />
          </div>
        </Fade>
      )}
      {alreadyDone &&
        !statusRef.current &&
        (surveyIdRef.current === 29 ||
          surveyIdRef.current === 31 ||
          surveyIdRef.current === 37 ? (
          <ComeBackLater
            msg={"Looks like you've already done this..."}
            nofooter={true}
            thankMsg={"Thank you for your registration"}
            survey_id={surveyIdRef.current}
          />
        ) : (
          surveyIdRef.current !== 65 && (
            <>
              <ComeBackLater
                msg={"Looks like you've already done this..."}
                nofooter={true}
                thankMsg={"Thank you, we will get back to you soon"}
              />
              {/* {console.log("already done")} */}
            </>
          )
        ))}
      {formComplete &&
        !alreadyDone &&
        surveyIdRef.current !== 65 &&
        !statusRef.current && (
          <>
            <ThankYouPage
              title={msgTitleRef.current}
              message={msgRef.current}
              coupons={coupons}
              sales_mob={sales_mob}
            />
          </>
        )}
      {(formComplete && surveyIdRef.current === 65) ||
        (formComplete && statusRef.current && (
          <>
            <ThankYouPage
              title={msgTitleRef.current}
              message={msgRef.current}
              coupons={coupons}
              sales_mob={sales_mob}
            />
            {/* {console.log("thank you")}  */}
          </>
        ))}
      {surveyIdRef.current === 65 && formComplete && (
        <ThankYouPage
          title={msgTitleRef.current}
          message={msgRef.current}
          coupons={coupons}
          sales_mob={sales_mob}
        />
      )}
      {/* {formComplete && !alreadyDone && (
        <>
          <Fade in>
            <div
              style={{
                width: "100%",
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fade>
        </>
      )} */}
      <div
        className="powered"
        style={{
          marginBottom: 0,
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        Forms by
        <img src="../assets/extraa_logo.png" alt="" />
        {/* <div className= 'logout'>
        <div className="box-2" onClick={onLogoutClick}>
          <div className="btn btn-two">
            <p>Log out</p>
          </div>
        </div>
      </div> */}
      </div>
      {/* <RedirectDailog
        modelOpen={modelOpen}
        onSubmit={onSkipClick}
        toggleOpen={setModelOpen}
      /> */}
    </div>
  );
}

export default CustomSurvey;
