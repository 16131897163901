import { createElement, useState } from "react";
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import {
    SurveyQuestionElementBase,
    ReactQuestionFactory
} from "survey-react-ui";
import ReactDOMServer from "react-dom/server";

const CUSTOM_TYPE = "counter";

// A model for the new question type
export class QuestionCounterModel extends Question {
    getType() {
        return CUSTOM_TYPE;
    }
    //   get colorPickerType() {
    //     return this.getPropertyValue("colorPickerType");
    //   }
    //   set colorPickerType(val) {
    //     this.setPropertyValue("colorPickerType", val);
    //   }

    //   get disableAlpha() {
    //     return this.getPropertyValue("disableAlpha");
    //   }
    //   set disableAlpha(val) {
    //     this.setPropertyValue("disableAlpha", val);
    //   }
}

// Register `QuestionColorPickerModel` as a model for the `color-picker` type
export function registerCounter() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
        return new QuestionCounterModel(name);
    });
}

// Specify display names for the question type and its properties
// const locale = localization.getLocale("");
// locale.qt[CUSTOM_TYPE] = "counter";
// locale.pe.colorPickerType = "Color picker type";
// locale.pe.disableAlpha = "Disable alpha channel";

// Register an SVG icon for the question type
const svg = ReactDOMServer.renderToString();
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, svg);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
    CUSTOM_TYPE,
    [
        // {
        //   name: "colorPickerType",
        //   default: "Slider",
        //   choices: ["Slider", "Sketch", "Compact"],
        //   category: "general",
        //   visibleIndex: 2 // After the Name and Title
        // },
        // {
        //   name: "disableAlpha:boolean",
        //   dependsOn: "colorPickerType",
        //   visibleIf: function (obj) {
        //     return obj.colorPickerType === "Sketch";
        //   },
        //   category: "general",
        //   visibleIndex: 3 // After the Name, Title, and Color Picker type
        // }
    ],
    function () {
        return new QuestionCounterModel("");
    },
    "question"
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionCounter extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        this.state = { value: this.question.value };
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question.value;
    }
    get disableAlpha() {
        return this.question.disableAlpha;
    }
    get type() {
        return this.question.colorPickerType;
    }
    handleColorChange = (data) => {
        this.question.value = data;
    };

    // Support the read-only and design modes
    get style() {
        return this.question.getPropertyValue("readOnly") ||
            this.question.isDesignMode
            ? { pointerEvents: "none" }
            : undefined;
    }

    renderElement() {
        return <Counter handleChange={this.handleColorChange} />
    }
}

// Register `SurveyQuestionColorPicker` as a class that renders `color-picker` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return createElement(SurveyQuestionCounter, props);
});

// Register the `color-picker` as an editor for properties of the `color` type in the Survey Creator's Property Grid
// PropertyGridEditorCollection.register({
//     fit: function (prop) {
//         return prop.type === "color";
//     },
//     getJSON: function () {
//         return {
//             type: CUSTOM_TYPE,
//             colorPickerType: "Compact"
//         };
//     }
// });

export function Counter({ handleChange }) {
    const [counter, setCounter] = useState(0);

    // Function is called everytime increment button is clicked
    const handleClick1 = () => {
        // Counter state is incremented
        setCounter(counter + 1);
        handleChange(counter + 1)
    };

    // Function is called everytime decrement button is clicked
    const handleClick2 = () => {
        // Counter state is decremented
        if (counter > 0) {
            setCounter(counter - 1);
            handleChange(counter - 1)
        } else if (counter === 0) {
            handleChange(0)
        }
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "300%",
                width: "100%",
                height: "100%",
                top: "-15%",
            }}
        >
            <div style={{
                display: 'flex',
                gap: 24,
                alignItems: 'center',
                justifyContent: "center",
            }}>
                <button
                    style={{
                        fontSize: 30,
                        marginRight: 5,
                        backgroundColor: "red",
                        border: '1px solid red',
                        borderRadius: "50%",
                        color: "white",
                        width: 50,
                        height: 50,
                        textAlign: "center"
                    }}
                    onClick={handleClick2}
                >
                    -
                </button>
                <div
                    style={{
                        fontSize: "120%",
                    }}
                >
                    {counter}
                </div>
                <button
                    style={{
                        fontSize: 30,
                        marginRight: 5,
                        backgroundColor: "green",
                        border: '1px solid green',
                        borderRadius: "50%",
                        color: "white",
                        width: 50,
                        height: 50,
                        textAlign: "center"
                    }}
                    onClick={handleClick1}
                >
                    +
                </button>
            </div>
        </div >
    )
}