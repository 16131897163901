import axios from "axios";

const axiosGet = async(url,data) => {
    const result=await axios.get(url)
  .then(response => {
    // console.log('Data:', response.data);
    return response.data
  })
  .catch(error => {
    console.error('Error:', error);
  });
  return result
}

export default axiosGet