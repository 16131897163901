import { gql } from "@apollo/client";

const GetQRForm = gql`
 query get_form_and_questions($where: qr_bool_exp = {code: {_eq: ""}}) {
  qr(where: $where) {
    id
    status
    logo
    redirection_link
    dist_coupon_amount
    survey_qrs {
      survey {
        link
      }
    }
    qr_forms_accounts {
      form {
        id
        form_questions {
          question {
            id
            label
            status
            type
            question_options {
              option {
                id
                label
              }
            }
          }
        }
      }
    }
    spaces_qrs {
      space {
        location
        google_rating_page
        merchant_spaces {
          merchant {
            id
            details
            industry
            name
            logo
          }
        }
      }
    }
  }
}
`;
export default GetQRForm;
