import React from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";

const Jolie = () => {
    const params = useParams();
    const qr_code = params.qrcode

    return (
        <>
            <div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                    <div style={{ margin: "10px 10px", padding: "25px", border: "2px solid black", maxWidth: "262px", height: "500px", borderRadius: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                        <img src="https://storage.extraa.in/files/rotary-logo.svg" style={{ width: "200px", height: "150px" }}></img>
                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <div className="qr-css" style={{ padding: "20px", display: "flex", justifySelf: "center", maxWidth: "fit-content", borderRadius: "20px" }}>
                                <QRCode
                                    fgColor="#000"
                                    bgColor="transparent"
                                    size={300}
                                    style={{ height: "auto", maxWidth: "500px", width: "100%" }}
                                    value={"@" + qr_code}
                                    viewBox={`0 0 256 256`}
                                    level="H"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Jolie;
