import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

// import App from './App';
import reportWebVitals from "./reportWebVitals";
import swDev from "./swDev";
import { store } from "./store/store.js";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "./pages/Login";
import Otp from "./pages/Otp";
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
// import Feedback from "./pages/Feedback";
import SmFeedback from "./pages/SmFeedback";
import PageNotFound from "./components/PageNotFound";
// import UserForm from './components/UserForm';
import SignUp from "./pages/SignUp";
import PromoBox from "./pages/PromoBox";
import Survey from "./pages/Survey";
import CustomSurvey from "./pages/CustomSurvey";
import ReactGA from 'react-ga';
import MLogin from "./pages/MLogin";
import NewSignUp from "./pages/NewSignUp";
import QrFeedback from "./pages/QrFeedback";
import UrlToken from "./pages/UrlToken";
import generateDynamicManifest from "./dynamicManifest";

import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import GoGas from "./pages/GoGas.jsx";
import Jolie from "./pages/Jolie.jsx";

//GA code added by yuvanesh
const trackingID = 'G-1MXHVPW800'  //GA Tracking id
ReactGA.initialize(trackingID);

//service worker method for pwa
// swDev();
const currentRoute = window.location.pathname;
// console.log("Current Route: app", currentRoute);
// const dynamicManifest = generateDynamicManifest(currentRoute);
// const manifestBlob = new Blob([JSON.stringify(dynamicManifest)], {
//   type: "application/json",
// });

// // Create a URL for the blob
// const manifestUrl = URL.createObjectURL(manifestBlob);

// // Set the dynamic manifest URL in the document head
const link = document.createElement("link");
if (currentRoute === "/csv/pothys_test_survey") {
  link.setAttribute("rel", "manifest");
  link.setAttribute("href", `${process.env.PUBLIC_URL}/manifest2.json`);
} else if (currentRoute === "/csv/test_survey_state_survey") {
  link.setAttribute("rel", "manifest");
  link.setAttribute("href", `${process.env.PUBLIC_URL}/manifest.json`);
}

document.head.appendChild(link);

export const client = new ApolloClient({
  uri: "https://backend.extraa.in/v1/graphql",
  cache: new InMemoryCache(),
});

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#4F3084",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#F9B61F",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <CacheBuster
    currentVersion={packageInfo.version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <ApolloProvider client={client}>
      <Router basename={process.env.PUBLIC_URL}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/mlogin" element={<MLogin />} />
              <Route path="/verify-otp" element={<Otp />} />
              <Route path="/user-home" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/user-profile" element={<Profile />} />
              <Route path="/user-signup" element={<SignUp />} />
              <Route path="/signup" element={<NewSignUp />} />
              <Route path="qr/:qr_code" element={<SmFeedback />} />
              <Route path="sm/:qr_code" element={<SmFeedback />} />
              <Route path="p/:box_url" element={<PromoBox />} />
              <Route path="sv/:survey_url" element={<Survey />} />
              <Route path="csv/:survey_url" element={<CustomSurvey />} />
              <Route path="uw/:wtoken" element={<UrlToken />} />
              <Route path="/qrf/:qrcode" element={<QrFeedback />} />
              <Route
                path="*"
                element={<PageNotFound message="Oops.. We can't find that" />}
              />
              <Route path="GoGas-qr/:qrcode" element={<GoGas />} />
              <Route path="rotary-qr/:qrcode" element={<Jolie />} />
              {/* <Route path="*" element={<PageNotFound message="Oops.. We can't find that" />} /> */}
            </Routes>
          </Provider>
        </ThemeProvider>
      </Router>
    </ApolloProvider>
  </CacheBuster>
  /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
