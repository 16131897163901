import axios from "axios";
import getUserUserSurveyResp from "../queries/GetUserSurveyResponses";
import Compressor from "compressorjs";
import { getAPI } from "../api/getApi";
import { CreateProducts } from "../mutations/CreateProduct";
import moment from "moment";
import axiosGet from "../api/axiosGet";
// import moment from "moment";
const CRM_Token = "crm_token";
export function setInitalHeaders() {
  if (localStorage.token) {
    return {
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.token,
    };
  }

  return {
    "Content-Type": "application/json",
    authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikd1ZXN0IiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImd1ZXN0Il0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6Imd1ZXN0In19.dFW-ZzED-qnnoWVb0r9oZIcmn2gSsBxqBp30BuUz1wk",
  };
}

let headers = new Headers();
export function getOTP([mobile], bool, loadingRef) {
  if (loadingRef) {
    loadingRef.current = true;
  }
  if (!mobile) {
    this.returnResult(false);
    return;
  }
  // SetphNum(num);
  // SetLoading(true);
  headers.append("Content-Type", "application/json");

  let data = bool
    ? {
      phone_number: mobile,
      qr_id: 1,
      skoda: true,
    }
    : {
      phone_number: mobile,
      qr_id: 1,
    };

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/get-otp",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      //  console.log(response.data);
      localStorage.setItem("otp_id", response.data.insert_otp.id);
      localStorage.setItem("user_id", response.data.user.id);
      // loadingRef.current = false;
      this.returnResult(true);
      // SetLoading(false);
      // navigate("/verify-otp", {
      //   state: {
      //     otp_id: response.data.insert_otp.id,
      //     user_id: response.data.user.id,
      //     phone_number: sender.data.mobile,
      //   },
      // });
    })
    .catch(function (error) {
      // SetLoading(false);
      // this.returnResult(false);
    });
  if (loadingRef) {
    loadingRef.current = false;
  }
  if (!bool) {
    this.returnResult(true);
  }
}

export const onSkipClick = (setModelOpen, redirect) => {
  setModelOpen(false);
  redirect.current = true;
};

export function flattenArray(nestedArray) {
  const flattened = [];

  function flatten(arr) {
    arr.forEach((item) => {
      if (Array.isArray(item)) {
        flatten(item);
      } else {
        flattened.push(item);
      }
    });
  }

  flatten(nestedArray);

  return flattened;
}

export const getFreeFillForm = async (survey2, client, token, prefillValue, phNo) => {
  // console.log( prefillValue)

  const prefillData = JSON.parse(prefillValue);
  if (Array.isArray(prefillData)) {


    const { data } = await getUserUserSurveyResp(client, token, prefillData, phNo)
    survey2.setValue("name", data[0]?.response_details?.name)
    survey2.setValue("area", data[0]?.response_details?.area)
    survey2.setValue("profession", data[0]?.response_details?.profession)
    survey2.setValue("how do you know about Texvalley", data[0]?.response_details['how do you know about Texvalley'])
  }
}

export function onUploadName(survey2) {
  survey2.onUploadFiles.add((_, options) => {
    const random = Math.floor(Math.random() * 90 + 10)
    // console.log(random, 'date');
    const filename = options.files[0].name
      .replace(/[^a-zA-Z0-9.]/g, "_")
      .replace(/\s+/g, "_");
    // console.log(options.files[0], 'file');
    if (options.files[0]?.size > 2100000) {
      // console.log("compressed");
      new Compressor(options.files[0], {
        quality: 1,
        maxHeight: 1000,
        maxWidth: 1000,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          const formData = new FormData();
          // The third parameter is required for server
          formData.append('file', result, `${random}_${result.name}`);

          // Send the compressed image file to server with XMLHttpRequest.
          let config = {
            method: "POST",
            body: formData,
            redirect: "follow",
          };

          fetch("https://storage.extraa.in/upload", config)
            .then((response) => response.json())
            .then((data) => {
              // console.log(data.url, 'url');
              options.callback(
                "success",
                options.files.map((file) => {
                  return {
                    file: file,
                    content: data.url,
                  };
                })
              );
            })
            .catch((error) => {
              console.error("Error: ", error);
            });
        },
        error(err) {
          console.log(err.message);
        },
      });
    } else {
      let formData = new FormData();
      formData.append("file", options.files[0], `${random}_${filename}`);
      // console.log("not compressed");
      let config = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      fetch("https://storage.extraa.in/upload", config)
        .then((response) => response.json())
        .then((data) => {
          options.callback(
            "success",
            options.files.map((file) => {
              return {
                file: file,
                content: data.url,
              };
            })
          );
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }


  });
}

export const CrmActivityCreate = async (items) => {
  const url = 'https://tq3yzya34nz7vh6673gt4fsuta0mdwjf.lambda-url.ap-south-1.on.aws/'
  let variables = {}
  const members = items?.members?.length > 0 && items?.members.map((x) => {
    return { "directus_users_id": { "id": x } }
  }) || null
  const member = items?.member?.length > 0 && items?.member.map((x) => {
    return { "directus_users_id": { "id": x } }
  }) || null
  if (items?.form_type === 'task') {
    variables = {
      "merchant_id": items?.merchant || "",
      "token": localStorage.getItem(CRM_Token),
      "task_name": items?.task_name || '',
      "description": items?.description || '',
      "due_on": items?.due_on || '',
      "status": items?.status || '',
      "member_id": members || null,
      "type": items?.form_type
    }
  } else if (items?.form_type === 'merchants') {
    variables = {
      merchant_name: items?.merchant_name,
      area: items?.area,
      city: items?.city,
      status: items?.status,
      scale: items?.scale,
      industry: {
        id: items?.industry
      },
      contacts: {
        name: items?.name,
        mobile: items?.mobile,
        designation: items?.designation,
        email:items?.email||''
      },
      "type": items?.form_type
    }
  }
  else {
    variables = {
      "add_type": items['add Type'],
      "contact_id": items?.contact_name,
      "member_id": members || null,
      "outcome": items['out Come'],
      "notes": items?.notes,
      "start_time": items['start Time'],
      "end_time": items['end Time'],
      "merchant_id": items?.merchant,
      "token": localStorage.getItem(CRM_Token),
      "task_name": items?.task_name || '',
      "description": items?.description || '',
      "due_on": items?.due_on || '',
      "status": items?.status || '',
      "member": member,
      "type": items?.form_type
    }
  }
  console.log(variables, "variables", items);
  const res = await getAPI(url, variables);
}

export const create_product = async (data) => {
  const images = [data?.files[0]?.content]
  if (data?.files2?.length > 0) {
    images.push(data?.files2[0].content)
  }
  if (data?.files3?.length > 0) {
    images.push(data?.files3[0].content)
  }
  if (data?.files4?.length > 0) {
    images.push(data?.files4[0].content)
  }
  const category = [data?.category]
  if (data?.category1?.length > 0) {
    category.push(data?.category1)
  }
  if (data?.category2?.length > 0) {
    category.push(data?.category2)
  }
  if (data?.category3?.length > 0) {
    category.push(data?.category3)
  }

  const result = {
    "name": data?.name,
    "brand": data?.brand,
    "category": category,
    "images": images,
    "description": data?.desc,
    "type": data?.product_type,
    "product_variants": {
      "data": {
        "stock": data?.stock,
        "sale_price": data?.sale_price || 0
      }
    },
    "metadata": {
      "model_no": data?.model_no || '',
      "hsn_code": data?.hsn_code || '',
      "short_desc": data?.short_desc || '',
      "extraa_gst_persentage": data?.extraa_gst,
      "gst_persentage": data?.gst,
      "CountryOfOrigin": "India",

    },
    "active": data?.status === "Active"
  }
  const res = await CreateProducts(result)
  // console.log(result, 'Product Results', res);
}

export const send_coins = async (data) => {
  const futureDate = moment().add(14, 'days');
  // const redeemDate=moment(data?.redeemDate).add(14,'days')
  const formattedFutureDate = futureDate.format('YYYY-MM-DD');
  // const formattedRedeemDate = redeemDate.format('YYYY-MM-DD');
  let variables = {}
  if (data?.wallet_id) {
    variables = {
      "wallet_objects": [
        {
          "balance": 0,
          "user_id": data?.user_id,
          "coin_type": 7,
          "store_id": 1
        }
      ],
      "transaction_objects": [
        {
          "sender_wallet_number": `${data?.user_id || 0}-7`,
          "receiver_wallet_number": "2-7",
          "coin_type": 7,
          "store_id": 1,
          "amount": Math.floor(data?.amount)
        }
      ],
      "where": {
        "_or": [
          {
            "wallet_number": {
              "_eq": `2-7`
            }
          }
        ]
      },
      "sender_id": data?.wallet_id,
      "amount": Math.floor(data?.amount),
      "debit_amount": Math.floor(data?.amount),
      "sender_metadata": { "redeemDate": formattedFutureDate }
    }
  } else {
    variables = {
      "wallet_objects": [
        {
          "balance": 0,
          "user_id": data?.user_id,
          "coin_type": 7,
          "store_id": 1
        }
      ],
      "transaction_objects": [
        {
          "sender_wallet_number": "1-7",
          "receiver_wallet_number": `${data?.user_id || 0}-7`,
          "coin_type": 7,
          "store_id": 1,
          "amount": Math.floor(data?.coins)
        }
      ],
      "where": {
        "_or": [
          {
            "wallet_number": {
              "_eq": `${data?.user_id || 0}-7`
            }
          }
        ]
      },
      "sender_id": "74",
      "amount": Math.floor(data?.coins),
      "debit_amount": Math.floor(data?.coins),
      "metadata": { "firstPurchase": moment().format('YYYY-MM-DD'), "redeemDate": formattedFutureDate }
    }
  }
  if (data?.firstPurchase && data?.firstPurchase !== '') {
    delete variables.metadata
  }
  const url = 'https://qbuoeunhnft5h3n7x4lgupjchi0vfltp.lambda-url.ap-south-1.on.aws/'
  const res = await getAPI(url, variables);
  if (data?.wallet_id) {
    await axiosGet(`https://api.growaasan.com/api/sendPosCommunication?clietnId=100365&authKey=Q0ptUDJ1dTlzZFYrd0RMUFR6aU5OUT09&communicationType=2&waTemplateName=gogas_redeem&waTemplateLang=en&country_code=91&customerMobile=${data?.mobile}&varCount=3&var1=${data?.name}&var2=${data?.amount}&var3=${data?.balance - data?.amount}`)
  } else {
    await axiosGet(`https://api.growaasan.com/api/sendPosCommunication?clietnId=100365&authKey=Q0ptUDJ1dTlzZFYrd0RMUFR6aU5OUT09&communicationType=2&waTemplateName=gogas_refueling&waTemplateLang=en&country_code=91&customerMobile=${data?.mobile}&varCount=5&var1=${data?.name}&var2=${data?.amount}&var3=${data?.coins}&var4=${res?.data?.receiver_wallets?.returning?.length > 0 && res?.data?.receiver_wallets?.returning[0]?.balance}&var5=${moment(formattedFutureDate).format("DD/MM/YYYY")}`)

  }
  // console.log(res, 'result');
}

export const gogasLocation=async(data)=>{
// console.log(data);
const url="https://qfw6oges6ys3btp4ypdxrhp7wu0ziasr.lambda-url.ap-south-1.on.aws/"
 const res =await getAPI(url,data);

}