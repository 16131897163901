import { gql, useApolloClient, useQuery } from "@apollo/client";
import { setInitalHeaders } from "../common/methods";

const query = gql`
query directusToken($_eq: bigint = "") {
    accounts(where: {id: {_eq: $_eq}}) {
      directus_id
      directus_token
      details
      username
    }
  }
  `;

export default function UseCRMToken(id) {
    // console.log(today, lastSeventhDay,'data')
    const { data, loading, refetch, error } = useQuery(query, {
        variables: {
            "_eq": id
        },
        context: {
            headers:{
                "Content-Type": "application/json",
                authorization:
                  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoic3VwZXJhZG1pbiIsImlhdCI6MTY4MDI2MDk1NiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbInN1cGVyYWRtaW4iLCJtZXJjaGFudCJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJzdXBlcmFkbWluIiwieC1oYXN1cmEtdXNlci1pZCI6IjEiLCJ4LWhhc3VyYS1tZXJjaGFudC1pZCI6IjEwNjAifX0.idq3fyqbnemJdsq5IrAhBNc2P5iXic4w6TmRp7sRaxM",
            }
        },
    });
    return {
        refetch,
        loadingG: loading,
        token: data && data?.accounts[0] || [],
    };
}