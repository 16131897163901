import { Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import { useCallback, useEffect, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import GetSurvey from "../queries/GetSurvey";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "survey-core/defaultV2.min.css";
import "./CustomSurvey.scss";
import { Box, Button, CircularProgress, Fade, Stack } from "@mui/material";
import ComeBackLater from "../components/ComeBackLater";
import { useRef } from "react";
import axios from "axios";
import { defaultTheme, skodaTheme } from "../surveyThemes/themes";
import ThankYouPage from "../components/ThankyouPage";
import { getAPI } from "../api/getApi";
import RedirectDailog from "../components/RedirectDialog";
import Preferences from "./Preferences";
import { useDispatch, useSelector } from "react-redux";
import { decremented, incremented, incrementbyamount } from "../store/store";
import SignUpAndLogin from "./feedback/SignUpAndLogin";
import SurveySection from "./feedback/SurveySection";
import "./QrFeedback.scss";
import GetQRForm from "../queries/GetFormQuestions";
import NewUserDetails from "./NewUserDetails";

function setInitalHeaders() {
  if (localStorage.token) {
    return {
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.token,
    };
  }

  return {
    "Content-Type": "application/json",
    authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikd1ZXN0IiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImd1ZXN0Il0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6Imd1ZXN0In19.dFW-ZzED-qnnoWVb0r9oZIcmn2gSsBxqBp30BuUz1wk",
  };
}

const QrFeedback = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const params = useParams();
  const pageIndex = useSelector((state) => state.pageIndex);
  // console.log(params.qrcode,'paramas')
  const qr_code = params.qrcode
  const { loading, error, data } = useQuery(GetQRForm, {
    variables: { qr_code: qr_code },
    context: {
      headers: setInitalHeaders(),
    },
  });



  const link = data && data.qr[0]?.survey_qrs[0]?.survey?.link
  const qrId = data && data?.qr[0]?.id
  const randomCoupons = data && data?.qr[0]?.dist_coupon_amount
  const qrLocation = data && data?.qr[0]?.spaces_qrs[0]?.space?.location
  const googleLink = data && data?.qr[0]?.spaces_qrs[0]?.space?.google_rating_page
  // console.log(link, "link", randomCoupons, qrLocation, googleLink)

  const decrement = () => {
    dispatch(decremented());
  };
  const increment = () => {
    dispatch(incremented());
  };
  const inscrementbyamount = (amount) => {
    dispatch(incrementbyamount(amount));
  };
  onpopstate = () => {
    decrement();
  };

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token && token !== 'undefined') {
      dispatch(incrementbyamount(2));
    } else {
      localStorage.clear()
    }
  }, [])

  // pageIndex component map
  const pageIndexComponentMap = {
    0: (
      <div className="qr-feedback">
        <Fade in={true}>
          <div>
            <SignUpAndLogin
              increment={increment}
              incrementbyamount={inscrementbyamount}
            />
          </div>
        </Fade>
      </div>
    ),
    1: (
      <div className="qr-feedback">
        <Fade in={true}>
          <div>
            {/* <Preferences increment={increment} /> */}
            <NewUserDetails increment={increment} />
          </div>
        </Fade>
      </div>
    ),
    2: (
      <div className="qr-feedback">
        <Fade in={true}>
          <div>
            <SurveySection
              increment={increment}
              link={link}
              details={data}
              qrId={qrId}
              randomCoupons={randomCoupons}
              qrLocation={qrLocation}
              googleLink={googleLink}
            />
          </div>
        </Fade>
      </div>
    ),
    3: (
      <div className="qr-feedback">
        <Fade in={true}>
          <div>
            <SignUpAndLogin increment={increment} />
          </div>
        </Fade>
      </div>
    ),
  };

  return pageIndexComponentMap[pageIndex];
};

export default QrFeedback;
