import { createElement, useState } from "react";
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import {
    SurveyQuestionElementBase,
    ReactQuestionFactory
} from "survey-react-ui";
// import {
//     localization,
//     PropertyGridEditorCollection
// } from "survey-creator-react";
import ReactDOMServer from "react-dom/server";
import { Scanner } from '@yudiel/react-qr-scanner';
import { Button } from "@mui/material";
import QrCodeIcon from '@mui/icons-material/QrCode';
import IdHash from "@yag/id-hash";

const CUSTOM_TYPE = "qr scanner";

// A model for the new question type
export class QuestionQrScanner extends Question {
    getType() {
        return CUSTOM_TYPE;
    }
    //   get colorPickerType() {
    //     return this.getPropertyValue("colorPickerType");
    //   }
    //   set colorPickerType(val) {
    //     this.setPropertyValue("colorPickerType", val);
    //   }

    //   get disableAlpha() {
    //     return this.getPropertyValue("disableAlpha");
    //   }
    //   set disableAlpha(val) {
    //     this.setPropertyValue("disableAlpha", val);
    //   }
}

// Register `QuestionColorPickerModel` as a model for the `color-picker` type
export function registerQrScanner() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
        return new QuestionQrScanner(name);
    });
}

// Specify display names for the question type and its properties
// const locale = localization.getLocale("");
// locale.qt[CUSTOM_TYPE] = "qr scanner";
// locale.pe.colorPickerType = "Color picker type";
// locale.pe.disableAlpha = "Disable alpha channel";

// Register an SVG icon for the question type
const svg = ReactDOMServer.renderToString();
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, svg);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
    CUSTOM_TYPE,
    [
        // {
        //   name: "colorPickerType",
        //   default: "Slider",
        //   choices: ["Slider", "Sketch", "Compact"],
        //   category: "general",
        //   visibleIndex: 2 // After the Name and Title
        // },
        // {
        //   name: "disableAlpha:boolean",
        //   dependsOn: "colorPickerType",
        //   visibleIf: function (obj) {
        //     return obj.colorPickerType === "Sketch";
        //   },
        //   category: "general",
        //   visibleIndex: 3 // After the Name, Title, and Color Picker type
        // }
    ],
    function () {

        return new QuestionQrScanner("");
    },
    "question"
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionQrScanner extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        this.state = { value: this.question.value };
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question.value;
    }
    get disableAlpha() {
        return this.question.disableAlpha;
    }
    get type() {
        return this.question.colorPickerType;
    }
    handleColorChange = (data) => {
        this.question.value = data;
    };

    // Support the read-only and design modes
    get style() {
        return this.question.getPropertyValue("readOnly") ||
            this.question.isDesignMode
            ? { pointerEvents: "none" }
            : undefined;
    }

    renderElement() {
        return <SurveyQrScanner handleChange={this.handleColorChange} />
    }
}

// Register `SurveyQuestionColorPicker` as a class that renders `color-picker` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return createElement(SurveyQuestionQrScanner, props);
});

// Register the `color-picker` as an editor for properties of the `color` type in the Survey Creator's Property Grid
// PropertyGridEditorCollection.register({
//     fit: function (prop) {
//         return prop.type === "color";
//     },
//     getJSON: function () {
//         return {
//             type: CUSTOM_TYPE,
//             colorPickerType: "Compact"
//         };
//     }
// });

export function SurveyQrScanner({ handleChange }) {
    const [Start, setStart] = useState(false)
    const [value, setValue] = useState('')
    const hasher = new IdHash();
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <div style={{ width: 300, height: 300, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {!Start && <button style={{ backgroundColor: '#F9B61F', gap: 8, minWidth: 100, cursor: 'pointer', border: 0, padding: 12, borderRadius: 8, fontSize: 16, color: '#4F3084', fontWeight: 'bold', display: 'inline-flex', alignItems: 'center' }} onClick={() => setStart(true)}>
                    <QrCodeIcon />Scan</button>}
                {Start && <Scanner
                    // onDecode={(result) => console.log(result,'res')}
                    onError={(error) => console.log(error?.message)}
                    onResult={(data) => {
                        // console.log(data, 'result')
                        if (data.startsWith('@')) {
                            const value = data.substring(1)
                            const decoded = hasher.decode(value);
                            // console.log(value,'val', decoded)
                            handleChange(decoded.toString())
                            setValue(decoded.toString())
                        } else if(data.startsWith('#')){
                            const value = data
                            handleChange(value)
                            setValue(value)
                        }
                            else {
                            handleChange(data)
                            setValue(data)
                        }
                        setStart(false)
                    }}

                />}
                {value?.length > 0 && <p>{value}</p>}
            </div>
        </div>
    )

}