import { Model, FunctionFactory } from "survey-core";
import { ComponentCollection, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import GetSurvey, { newSurveyQuery } from "../../queries/GetSurvey";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// import Color from "color-thief-react";
// import SurveyComponent from "../components/SurveyComponent";

import "survey-core/defaultV2.min.css";
import "../CustomSurvey.scss";
import {
  Box,
  CircularProgress,
  Fade,
  Stack,
  LinearProgress,
} from "@mui/material";
import ComeBackLater from "../../components/ComeBackLater";
import { useRef } from "react";
import axios from "axios";
import { blackOrchidTheme, defaultTheme, lifecellTheme, rotatryTheme, skodaTheme } from "../../surveyThemes/themes";
import ThankYouPage from "../../components/ThankyouPage";
import { getAPI } from "../../api/getApi";
import RedirectDailog from "../../components/RedirectDialog";
import Coupon from "../../components/Coupon";
import { Designations, department } from "../../common/department";
import { industry } from "../../common/industry";
import { citiesInIndia } from "../../common/city";
import { state } from "../../common/state";
import GetUserStats from "../../queries/GetUserStats";
import IdHash from "@yag/id-hash";
import jwt_decode from 'jwt-decode';
import { couponRedeemSubmit, flattenArray, getOTP, onUploadName, setInitalHeaders } from "../../common/methods";
// import "survey-core/modern.min.css";

const decodeJWT = (token) => {
  try {
    const decodedToken = jwt_decode(token);
    return decodedToken;
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
};

function SurveySection({ link, details, qrId, googleLink, qrLocation, randomCoupons }) {
  const [formDetails, SetFormDetails] = useState([]);
  const [coupons, SetCoupons] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const qrIdRef = useRef(0);
  const surveyIdRef = useRef(0);
  const pgNumRef = useRef(0);
  const [formComplete, SetFormComplete] = useState(false);
  const [loading, SetLoading] = useState(true);
  const [alreadyDone, SetAlreadyDone] = useState(false);
  const [banner, SetBanner] = useState("");
  const [logo, SetLogo] = useState(false);
  // const [rating, SetRating] = useState("");
  const users = useRef("");
  const skodaObj = useRef("");
  const redeemCode = useRef("");
  const loadingRef = useRef(true);
  const dataRef = useRef({})
  // const [msgTitle, SetMsgTitle] = useState("");
  const msgTitleRef = useRef("Thank You");
  const msgRef = useRef("Your feedback is valuable to us");
  const metadataRef = useRef("");
  const redirect = useRef(false);
  // const [msg, SetMsg] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const history=useHistory()
  const token = localStorage.getItem("token");
  const gLink = useRef(googleLink)

  //  console.log(details, "details");
  const survey2 = new Model(formDetails);
    onUploadName(survey2)
  // survey2.onUploadFiles.add((_, options) => {
  //   const filename = options.files[0].name.replace(/[^a-zA-Z0-9.]/g, '_').replace(/\s+/g, '_')
  //   let formData = new FormData();
  //   formData.append("file", options.files[0], filename);

  //   let config = {
  //     method: 'POST',
  //     body: formData,
  //     redirect: 'follow'
  //   };

  //   fetch("https://storage.extraa.in/upload", config)
  //     .then(response => response.json())
  //     .then(data => {
  //       options.callback("success", options.files.map(file => {
  //         return {
  //           file: file,
  //           content: data.url
  //         };
  //       }));
  //     })
  //     .catch(error => {
  //       console.error("Error: ", error);
  //     });
  // });

  // function setInitalHeaders() {
  //   if (localStorage.token) {
  //     return {
  //       "Content-Type": "application/json",
  //       authorization: "Bearer " + localStorage.token,
  //     };
  //   }

  //   return {
  //     "Content-Type": "application/json",
  //     authorization:
  //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikd1ZXN0IiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImd1ZXN0Il0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6Imd1ZXN0In19.dFW-ZzED-qnnoWVb0r9oZIcmn2gSsBxqBp30BuUz1wk",
  //   };
  // }

  // SEND OTP

  let headers = new Headers();

  // function getOTP([mobile], bool) {
  //   loadingRef.current = true;
  //   // console.log("getting");
  //   if (!mobile) {
  //     this.returnResult(false);
  //     return;
  //   }
  //   // SetphNum(num);
  //   // SetLoading(true);
  //   headers.append("Content-Type", "application/json");

  //   let data = bool
  //     ? {
  //       phone_number: mobile,
  //       qr_id: 1,
  //       skoda: true,
  //     }
  //     : {
  //       phone_number: mobile,
  //       qr_id: 1,
  //     };

  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/get-otp",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       //  console.log(response.data);
  //       localStorage.setItem("otp_id", response.data.insert_otp.id);
  //       localStorage.setItem("user_id", response.data.user.id);
  //       // loadingRef.current = false;
  //       this.returnResult(true);
  //       // SetLoading(false);
  //       // navigate("/verify-otp", {
  //       //   state: {
  //       //     otp_id: response.data.insert_otp.id,
  //       //     user_id: response.data.user.id,
  //       //     phone_number: sender.data.mobile,
  //       //   },
  //       // });
  //     })
  //     .catch(function (error) {
  //       // SetLoading(false);
  //       // this.returnResult(false);
  //     });
  //   loadingRef.current = false;
  //   if (!bool) {
  //     this.returnResult(true);
  //   }
  // }

  async function verificationSkodaMember([mobile]) {
    loadingRef.current = true;
    //  console.log("getting");
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    // SetphNum(num);
    // SetLoading(true);
    headers.append("Content-Type", "application/json");

    let data = {
      // phone_number: mobile,
      _phone: mobile.toString(),
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/get-otp",
      url: "https://backend.extraa.in/api/rest/skodagurudev",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
        "x-hasura-admin-secret": "aseraadmin@1234",
      },
      data: data,
    };
    // const response = await axios.post(url, data, { headers });
    const resp = await axios
      .post("https://backend.extraa.in/api/rest/skodagurudev", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
          "x-hasura-admin-secret": "aseraadmin@1234",
        },
      })
      .then(function (response) {
        //  console.log('skoda member res',response.data);
        // loadingRef.current = false;
        return response.data;
      })
      .catch(function (error) {
        // SetLoading(false);
        // this.returnResult(false);
      });

    if (resp.users.length > 0) {
      // setSkodaObj(resp.users[0])
      skodaObj.current = resp.users[0];
      survey2.setValue("name", resp.users[0].name);
      getOTP([mobile], true);
    }
    loadingRef.current = false;
    //  console.log('skoda',resp.users);
    this.returnResult(resp.users.length > 0);
  }

  async function verifyCouponCode([mobile]) {
    loadingRef.current = true;
    //  console.log("getting");
    if (!mobile) {
      this.returnResult(false);
      return;
    }
    // SetphNum(num);
    // SetLoading(true);
    headers.append("Content-Type", "application/json");
    // let found = mobile && mobile.includes('#');
    // let couponCode = found ? mobile.slice(1): mobile;
    const couponCode = parseInt(mobile, 16);
    redeemCode.current = couponCode;
    let data = { ucoupon_id: couponCode };

    // const response = await axios.post(url, data, { headers });
    const resp = await axios
      .post("https://backend.extraa.in/api/rest/ucoupon", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
          "x-hasura-admin-secret": "aseraadmin@1234",
        },
      })
      .then(function (response) {
        //  console.log('skoda member res',response.data);
        // loadingRef.current = false;
        return response.data;
      })
      .catch(function (error) {
        // SetLoading(false);
        // this.returnResult(false);
      });

    if (resp.user_coupons_by_pk) {
      // console.log(resp.user_coupons_by_pk, 'data')
      const { coupon, user } = resp.user_coupons_by_pk;
      survey2.setValue("username", user.name);
      survey2.setValue("phone_number", user.phone_number);
      survey2.setValue("couponName", coupon.name);
      survey2.setValue("offer_title", coupon.offer_title);
      survey2.setValue("subtitle", coupon.offer_subtitle);
      survey2.setValue("account_id", localStorage.getItem("accid"));
      survey2.setValue("merchant_id", localStorage.getItem("mer_id"));
      this.returnResult(true);
    } else {
      this.returnResult(false);
    }
    loadingRef.current = false;
    //  console.log('skoda',resp.users);
  }

  // Verify OTP
  async function verifyOTP([otp]) {
    // SetLoading(true);
    loadingRef.current = true;
    //  console.log("verifyOTP");
    let data = JSON.stringify({
      otp: otp,
      id: localStorage.otp_id,
      user_id: localStorage.user_id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://faas-blr1-8177d592.doserverless.co/api/v1/web/fn-5eb492b6-735e-4a32-8582-c80db698fb7e/user/verify-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const validation = await axios(config)
      .then(function (response) {
        // SetOtpLoading(false);
        if (response.data.status) {
          localStorage.setItem("token", response.data.token);
          // localStorage.setItem("user_id", location.state.user_id);

          // this.returnResult(true);
          return true;
        } else {
          // SetOtpError(true);
          // throw new Error(`Invalid OTP`);
          this.returnResult(false);
          return false;
          // return;
        }
      })
      .catch(function (error) {
        // throw new Error(`HTTP error! Status: ${error}`);
      });

    // this.returnResult(validation);

    this.returnResult(validation);
    loadingRef.current = false;
  }

  const userStats = useQuery(GetUserStats, {
    context: {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.token,
      },
    },
  });

  const userDetails = {
    name: userStats?.data?.users && userStats?.data?.users?.length > 0 && userStats?.data?.users[0]?.name,
    phone_number: userStats?.data?.users && userStats?.data?.users?.length > 0 && userStats?.data?.users[0]?.phone_number,
  }

  const { surveyLoading, error, data } = useQuery(newSurveyQuery, {
    variables: { url: link },
    context: {
      headers: setInitalHeaders(),
    },
  });

  const onSkipClick = () => {
    setModelOpen(false);
    redirect.current = true;
  };

  FunctionFactory.Instance.register("getOTP", getOTP, true);
  FunctionFactory.Instance.register("verifyCouponCode", verifyCouponCode, true);
  FunctionFactory.Instance.register(
    "verificationSkodaMember",
    verificationSkodaMember,
    true
  );
  FunctionFactory.Instance.register("verifyOTP", verifyOTP, true);

  if (!Serializer.findClass("states")) {
    ComponentCollection.Instance.add({
      name: "states",
      title: "State",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a State...",
        choices: state
      },
    });
  }

  if (!Serializer.findClass("cities")) {
    ComponentCollection.Instance.add({
      name: "cities",
      title: "City",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a City...",
        choices: citiesInIndia
      },
    });
  }

  if (!Serializer.findClass("industry")) {
    ComponentCollection.Instance.add({
      name: "industry",
      title: "Industry",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a Industry...",
        choices: industry
      },
    });
  }

  if (!Serializer.findClass("department")) {
    ComponentCollection.Instance.add({
      name: "department",
      title: "Office Department",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a department...",
        choices: department
      },
    });
  }

  if (!Serializer.findClass("designations")) {
    ComponentCollection.Instance.add({
      name: "designations",
      title: "Office Designations",
      // The actual question that will do the job
      questionJSON: {
        type: "dropdown",
        placeholder: "Select a designation...",
        choices: Designations
      },
    });
  }
   //pincode
   if (!Serializer.findClass("pincode")) {
    ComponentCollection.Instance.add({
      name: "pincode",
      title:"pincode",
      // The actual question that will do the job
      questionJSON: {
        type: "text",
        name:"pincode",                 
        placeholder:"Enter Pincode",
        isRequired: "true",
        requiredErrorText: "Field cannot be empty",              
        min: 99999,
        max: 999999,
        maxLength: 6,
        "validators": [
          {
           "type": "regex",
           "text": "Enter Valid Pincode",
           "regex": "^[0-9]{6}$"
          }
         ]       
       },
    });
  }
  if (!Serializer.findClass("mobile")) {
    ComponentCollection.Instance.add({
      name: "Mobile",
      title: "Mobile Number",      
      // The actual question that will do the job
      questionJSON: {   
        name:"mobile",
        title:"title",    
        type: "text",
        inputType: "tel",
        placeholder: "Enter Mobile Number...",
        isRequired: "true",
        requiredErrorText: "Field cannot be empty",
        maxLength:10,
        "validators": [
          {
           "type": "regex",
           "text": "Enter Valid Mobile Number",
           "regex": "^[0-9]{10}$"
          }
         ]
      },
    });
  }
  if (!Serializer.findClass("otp")) {
    ComponentCollection.Instance.add({
      name: "otp",
      title: "otp",      
      // The actual question that will do the job
      questionJSON: {   
        name:"otp",
        title:"otp",    
        type: "text",
        inputType: "tel",
        placeholder: "Enter OTP...",
        isRequired: "true",
        requiredErrorText: "Field cannot be empty",
        maxLength:4,
        "validators": [
          {
           "type": "regex",
           "text": "Enter Valid OTP",
           "regex": "^[0-9]{4}$"
          }
         ]
      },
    });
  }

  const userId = token && decodeJWT(token)['https://hasura.io/jwt/claims']['x-hasura-user-id']

  // Submit API
  async function submitAPI(
    phone,
    gender,
    name,
    email,
    dob,
    ageRange,
    results,
    metadata,
    surveyResponse
  ) {
    try {
      const url = "https://6j8wmnnyoa.execute-api.ap-south-1.amazonaws.com/SubmitAndCoupon";
      const header = {
        location: dataRef.current.locations,
        randomCoupons: dataRef.current.randomCoupons || '',
        industry: dataRef.current.industries.toString() || '',
        user_id: dataRef.current.user_id,
        qr_id: qrIdRef.current || 0,
        phone_number: phone || '',
        //  gender: gender,
        name: name || '',
        //  email: email,
        //  dob: dob,
        age_range: ageRange.toString(),
        survey_id: surveyIdRef.current.toString(),
        response_details: results,
        //  coupons: couponsRef.current,
        meta: metadata || '',
        token: token || "",
        //  survey_responses: surveyResponse,
      }
      const res = await axios.post(url, header)
        .then((resp) => {
          return resp
        })
        .catch((error) => {
          return error
        })
      const resp = JSON.parse(res.data.output)
      return resp;
    } catch (err) {
      console.error(err);
    }
  }

  // coupon redeem submit
  // const couponRedeemSubmit = async () => {
  //   const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSZ");
  //   const url = "https://backend.extraa.in/api/rest/redeem-coupon";
  //   const variables = {
  //     ucoupon_id: redeemCode.current,
  //     redeemed_at: currentDateTime,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization:
  //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieXV2YW5lc2hzIiwiaWF0IjoxNjg1NjE4MDQzLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsic2FsZXMiXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoic2FsZXMiLCJ4LWhhc3VyYS11c2VyLWlkIjoiMTc3NSIsIngtaGFzdXJhLW1lcmNoYW50LWlkIjoiMTA2MCJ9fQ.h47FQfJO0pVM1cwasqslCN2vsq2huIFw918HxeN9VYk",
  //     "x-hasura-admin-secret": "aseraadmin@1234",
  //   };
  //   const response = await getAPI(url, variables, headers);
  //   return response?.data;
  // };
  const hasher = new IdHash();
  // ----------------------------Whatsapp flow -----------------------------------
  const whatsappLink = async (name, phone) => {
    const number = phone ? parseInt(phone) : parseInt(users.current.phone_number)
    const names = (name ? name : users.current.name).replace(/ /g, "_")
    const encoded = hasher.encode(number);
    const link = `https://mates.extraa.in/uw/${encoded}`
    const url = `https://api.growaasan.com/api/sendPosCommunication?clietnId=100365&authKey=Q0ptUDJ1dTlzZFYrd0RMUFR6aU5OUT09&communicationType=2&waTemplateName=feedback&waTemplateLang=en&country_code=91&customerMobile=${phone ? phone : users.current.phone_number}&varCount=2&var1=${names}&var2=${link}`
    //  console.log(link, 'link', url)
    axios.get(url)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }
  //  --------------------------------------------------------- SUBMIT FEEDBACK ---------
  const submitSurvey = useCallback(async (sender) => {
    // loadingRef.current = true;
    let results = sender.data;
    // console.log(survey2,'survey')
    // alert(results);
    if (skodaObj.current) {
      results = {
        ...sender.data,
        ...skodaObj.current.metadata,
        name: skodaObj.current.name,
      };
    }
    const name = results.name || skodaObj.current.name || "";
    const phone = results.mobile || "";
    const gender = results.gender;
    const email = results.email;
    const dob = results.dob;
    const age = moment().diff(dob, "years");
    const surveyResponse = {
      name: skodaObj.current.name,
      ...skodaObj.current.metadata,
    };
    //  SetRating(results?.rating||'')
    // console.log(results);
    let ageRange;

    if (age < 20) {
      ageRange = 0;
    } else if (age >= 20 && age < 40) {
      ageRange = 1;
    } else if (age >= 40 && age < 60) {
      ageRange = 2;
    } else {
      ageRange = 3;
    }

    // console.log(name, ageRange, gender, dob, phone, email);
    // console.log(survey2);
    SetLoading(true);
    // if (results.coupon_code) {
    //   const result = await couponRedeemSubmit(redeemCode, getAPI);
    //   // console.log(result,'result')
    // }

    loadingRef.current = true;
    const sub = await submitAPI(
      phone,
      gender,
      name,
      email,
      dob,
      ageRange,
      results,
      metadataRef.current,
      surveyResponse
    );
    // console.log(sub, "sub");
    // if (results.ratting && results.ratting > 3) {
    //   setModelOpen(true);
    //   await new Promise((resolve) => setTimeout(resolve, 5000));
    //   if (!redirect.current) {
    //     window.location.replace(localStorage.getItem("link"));
    //   }
    // }
   // console.log(sub, 'data')
    if (sub?.body?.alreadyDone) {
      SetAlreadyDone(true);
    } else {
      SetFormComplete(true);
      const couponCount = sub[1]?.body?.insert_user_coupons?.returning.length
      onCouponsDone(couponCount, results?.rating)
    }
    if (sub.hasOwnProperty("survey")) {
      SetFormComplete(true);
      survey2.doComplete();
    }

   // whatsappLink(name, phone)

    SetLoading(false);
    loadingRef.current = false;
    localStorage.removeItem("link");
    // survey2.doComplete();
    // formComplete.current++;
  }, []);

  useEffect(() => {
    if (data && data.surveys[0] && data.surveys[0].form_details) {
      //  console.log(userDetails);
      users.current = userDetails
      // SetBanner(data.surveys[0]?.banner);

      //  SetCoupons(data.surveys[0].survey_coupons);
      //  couponsRef.current = data.surveys[0].survey_coupons;
      // console.log(data.surveys[0].survey_coupons);
      // console.log(data.surveys[0]?.form_details);
      if (data.surveys[0]?.merchant?.logo && !data.surveys[0]?.banner) {
        SetLogo(true);
      }
      SetBanner(data.surveys[0]?.banner || data.surveys[0]?.merchant?.logo);
      if (data.surveys[0]?.banner || data.surveys[0]?.merchant?.logo) {
        survey2.title = "";
      }
      metadataRef.current = data.surveys[0]?.metadata;
      msgTitleRef.current = data.surveys[0]?.message_title;
      msgRef.current = data.surveys[0]?.message;
      qrIdRef.current = qrId;
      surveyIdRef.current = data.surveys[0].id;
      dataRef.current = {
        locations: qrLocation || "",
        randomCoupons: randomCoupons || '',
        industries: data?.surveys[0]?.merchant?.industry || '',
        user_id: data?.surveys[0]?.user_id || localStorage.getItem('user_id') || userId,
      }
      gLink.current = googleLink
      // console.log(data.surveys[0]?.form_details);
      // console.log(fd.pages[0].elements[0], "hhh");
      SetFormDetails(data.surveys[0]?.form_details);
      SetLoading(false);
      //  console.log(coupons, 'coupons');
      // console.log(getColor("https://storage.extraa.in/files/jito_Banner.png"));
      const currentRoute = location.pathname;
      const formArr = data.surveys[0]?.form_details;
      const cleanArr =
        formArr &&
        formArr.pages.map((i) => {
          return i.elements.map((x) => {
            return { name: x.name };
          });
        });
      const finalArr = flattenArray(cleanArr);
      const findIndex = finalArr.findIndex((x) => x.name === "mobile");
      //  console.log(data.surveys[0]?.form_details, 'url', cleanArr, findIndex);
      if (
        !token &&
        findIndex === -1 &&
        data &&
        data.surveys[0] &&
        !data.surveys[0].m_login
      ) {
        // if (surveyIdRef.current === 18) {
        //   navigate("/signup", { state: { url: currentRoute } });
        // } else {
        //   navigate("/", { state: { url: currentRoute } });
        // }
      }
      if (data && data.surveys[0] && data.surveys[0].m_login) {
        const mtoken = localStorage.getItem("mtoken");
        if (!mtoken) {
          navigate("/mlogin", { state: { url: currentRoute } });
        }
      }
      //   setBoxData(data.boxes[0]);
    }
    // localStorage.setItem("link", googleLink);
  }, [surveyLoading, error, data]);

  // function flattenArray(nestedArray) {
  //   const flattened = [];

  //   function flatten(arr) {
  //     arr.forEach((item) => {
  //       if (Array.isArray(item)) {
  //         flatten(item);
  //       } else {
  //         flattened.push(item);
  //       }
  //     });
  //   }

  //   flatten(nestedArray);

  //   return flattened;
  // }

  // let fd = formDetails;
  loadingRef.current = false;
  // useEffect(() => {
  //   if (formComplete) {
  //     survey2.doComplete();
  //   }
  // }, [formComplete]);

  // ----------------- SET API VALUES // NOT PRODUCTION READY --------------------
  // survey2.setValue("name", "MY NAME");
  // survey2.setValue("mobile", "8056299487");
  // survey2.setValue("gender", "Item 1");
  // survey2.setValue("dob", "1994-08-23");
  // survey2.setValue("email", "email@email.com");

  // ---------------------------------------------------------
  survey2.showProgressBar = "top";

  const transitionTime = 300;

  function animate(isShowing) {
    const element = document.querySelector(".sd-container-modern");
    if (!!element) {
      const classes = element.classList;
      if (!classes.contains("hidden")) {
        classes.add("hidden");
      }
      isShowing ? classes.add("visible") : classes.remove("visible");
    }
  }
  function showAnimated() {
    //  console.log(survey2.currentPageNo, "page");
    pgNumRef.current = survey2.currentPageNo;

    animate(true);
  }
  function hideAnimated() {
    animate(false);
  }

  let doAnimation = true;
  survey2.onAfterRenderSurvey.add(showAnimated);

  survey2.onCurrentPageChanging.add(function (_, options) {
    if (!doAnimation) return;
    options.allow = false;
    setTimeout(function () {
      doAnimation = false;
      survey2.currentPage = options.newCurrentPage;
      doAnimation = true;
    }, transitionTime);
    hideAnimated();
  });

  survey2.onCurrentPageChanged.add(showAnimated);

  survey2.onCompleting.add(function (_, options) {
    if (!doAnimation) return;
    options.allow = false;
    setTimeout(function () {
      doAnimation = false;
      survey2.doComplete();
      doAnimation = true;
    }, transitionTime);
    hideAnimated();
  });
  // console.log(formDetails);

  // survey2.completedHtml = thankYouPage;

  // survey2.onServerValidateQuestions.add(getOTP(sender));

  const onCouponsDone = (coupons, ratings) => {
    if (!gLink.current || ratings < 4) {
      navigate("/home", {
        state: {
          couponNum: coupons,
        },
      });
    } else {
      window.location.href = gLink.current || ""
    }
  }

  const thememap = {
    29: skodaTheme,
    31: skodaTheme,
    37: skodaTheme,
    66: rotatryTheme,
    193: lifecellTheme,
    176:blackOrchidTheme,
    154:blackOrchidTheme
  };

  return (
    <div
      className="csv-survey qr-flow"
      style={thememap[surveyIdRef.current] || defaultTheme}
    >
      {/* <SurveyComponent /> */}

      {banner && (
        <div style={{ width: "100%" }}>
          <Stack
            alignItems="center"
            style={{ width: "100%", background: "var(--banner-bg)" }}
          >
            <Box
              maxWidth={logo ? "200px" : "600px"}
              paddingTop={logo ? "12px" : "0px"}
              width="100%"
            >
              <img
                style={{ width: "100%", maxHeight:300, objectFit:'contain' }}
                src={banner}
                alt=""
              />
            </Box>
          </Stack>
        </div>
      )}
      {formDetails &&
        !loading &&
        survey2 &&
        !alreadyDone &&
        !formComplete &&
        !loadingRef.current && (
          <Fade in>
            <div>
              {/* {survey2.currentPageNo + 1 / survey2.visiblePageCount}

              <LinearProgress
                variant="determinate"
                value={pgNumRef.current + (1 / survey2.visiblePageCount) * 100}
              /> */}

              <Survey
                model={survey2}
                showCompletedPage={false}
                onComplete={submitSurvey}
              />
            </div>
          </Fade>
        )}
      {(loading || loadingRef.current) && (
        <Fade in>
          <div
            style={{
              width: "100%",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </Fade>
      )}
      {alreadyDone &&
        (surveyIdRef.current === 29 || surveyIdRef.current === 31 || surveyIdRef.current === 37 ? (
          <ComeBackLater
            msg={"Looks like you've already done this..."}
            nofooter={true}
            thankMsg={"Thank you for your registration"}
            survey_id={surveyIdRef.current}
          />
        ) : (
          surveyIdRef.current !== 65 && (
            <ComeBackLater
              msg={"Looks like you've already done this..."}
              nofooter={true}
            />
          )
        ))}
      {formComplete && !alreadyDone && (
        <>
          {/* {link && couponCount === '' ? (
            <Coupon
              formData={data.surveys[0]}
              //  gender={localStorage.getItem("gender")}
              //  industryId={merchantIndustry}
              navigate={onCouponsDone}
              surveyCoupons={coupons.length}
            />
          ) :
            // <ThankYouPage
            //   title={msgTitleRef.current}
            //   message={msgRef.current}
            //   coupons={coupons}
            //   newPage={true}
            //   google_link={googleLink}
            //   couponCount={couponCount}
            // />
             //to avoid success page redirect directly to google ratings @aravind
          } */}
          <Fade in>
            <div
              style={{
                width: "100%",
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fade>
        </>
      )}
      {formComplete && surveyIdRef.current === 65 && (
        <ThankYouPage
          title={msgTitleRef.current}
          message={msgRef.current}
          coupons={coupons}
          newPage={true}
        />
      )}
      <div
        className="powered"
        style={{
          marginBottom: 0,
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        Forms by
        <img src="../assets/extraa_logo.png" alt="" />
      </div>
      <RedirectDailog
        modelOpen={modelOpen}
        onSubmit={onSkipClick}
        toggleOpen={setModelOpen}
      />
    </div>
  );
}

export default SurveySection;
