export const industry=[
    "Advertising and Marketing",
    "Aerospace and Defence",
    "Agriculture",
    "Automotive",
    "Chemicals",
    "Construction","Consumer Goods", "Consulting Services",
    "Education", "Energy",
    "Events", "Finance", "Government and Public Administration",
    "Healthcare", "Hospitality", "Human Resources", "Information Technology",
    "Jewellery", "Legal Services",
    "Manufacturing", "Media and Entertainment", "Mining and Minerals",
    "Non-profit and Social Services", "Pharmaceutiacal and Biotechnology",
    "Professional Services", "Public Relations", "Real Estate", "Retail",
    "Sports and fitness", "Telecommunications", "Transportation", "Textiles",
    "Waste Management and Recycling", "Others" 
]