export const defaultTheme = {
  "--primary": "#4F3084",
  "--nxt-btn-col": "#4F3084",
  "--complete-btn-col": "#4F3084",
  "--btn-text-col": "#FFF",
  "--title-font": "'Gilroy-Bold', sans-serif",
  "--description-font": "'Gilroy-SemiBold', sans-serif",
  "--input-font": "'Gilroy-SemiBold', sans-serif",
  "--question-font": "Gilroy-SemiBold",
  "--btn-radius": "8px",
  "--btn-border": "#9D8FFA",
  "--row-radius": "24px",
  "--frame-radius": "12px",
  "--progress-bar-height": "4px",
  "--background": "#FFF7DF",
  "--background-dim": "transparent",
  "--foreground": "#161616",
  "--primary-foreground": "#4F3084",
  "--background-dim-light": "#fff", // Input background
  "--sd-item-default-background": "#FFF",
  // "--number-display": "none",
  "--errbox-margin": "8px",
  "--box-shadow": "none",
  "--base-unit": "8px",
  "--frame-padding": "12px",
  "--input-border": "#DCDCDC",
  // "--banner": "none",
  "--banner-bg": "#FFF",
  "--overflow-scroll": "hidden",
  "--rating-flex-wrap": "wrap",
  "--rating-flex-dir" : "row",


  // "--input-box-shadow": "none",

};

export const blackOrchidTheme = {
  "--primary": "#4F3084",
  "--nxt-btn-col": "#4F3084",
  "--complete-btn-col": "#4F3084",
  "--btn-text-col": "#FFF",
  "--title-font": "'Gilroy-Bold', sans-serif",
  "--description-font": "'Gilroy-SemiBold', sans-serif",
  "--input-font": "'Gilroy-SemiBold', sans-serif",
  "--question-font": "Gilroy-SemiBold",
  "--btn-radius": "8px",
  "--btn-border": "#9D8FFA",
  "--row-radius": "24px",
  "--frame-radius": "12px",
  "--progress-bar-height": "4px",
  "--background": "#000",
  "--background-dim": "transparent",
  "--foreground": "#fff",
  "--primary-foreground": "#4F3084",
  "--background-dim-light": "#fff", // Input background
  "--sd-item-default-background": "#FFF",
  // "--number-display": "none",
  "--errbox-margin": "8px",
  "--box-shadow": "none",
  "--base-unit": "8px",
  "--frame-padding": "12px",
  "--input-border": "#DCDCDC",
  // "--banner": "none",
  "--banner-bg": "#000",
  "--overflow-scroll": "hidden",
  "--rating-flex-wrap": "wrap",
  "--rating-flex-dir" : "row",


  // "--input-box-shadow": "none",

};
export const saiBabaTheme={
  "--primary": "#4F3084",
  "--nxt-btn-col": "#4F3084",
  "--complete-btn-col": "#BF1C06",
  "--btn-text-col": "black",
  "--title-font": "'Gilroy-Bold', sans-serif",
  "--description-font": "'Gilroy-SemiBold', sans-serif",
  "--input-font": "'Gilroy-SemiBold', sans-serif",
  "--question-font": "Gilroy-SemiBold",
  "--btn-radius": "8px",
  "--btn-border": "#9D8FFA",
  "--row-radius": "24px",
  "--frame-radius": "12px",
  "--progress-bar-height": "4px",
 "--backgroundImageFit": "fill", 
  "--background": "url(https://i.pinimg.com/736x/34/aa/34/34aa349b4495fd85c5af432dd085b329.jpg)",  
  "--background-dim": "transparent",//Form Area
  "--foreground": "black", // question Text color
  "--primary-foreground": "white",
  "--background-dim-light": "white", // Input background
  "--sd-item-default-background": "#FFF",
  "--backgroundImageAttachment": "scroll",
  // "--number-display": "none",""""
  "--errbox-margin": "8px",
  "--box-shadow": "none",
  "--base-unit": "8px",
  "--frame-padding": "12px",
  "--input-border": "none",
  // "--banner": "none",
  "--banner-bg": "none",
  "--overflow-scroll": "hidden",
  "--rating-flex-wrap": "wrap",
  "--rating-flex-dir" : "row",
  // "--input-box-shadow": "none",

}

export const zimsonTheme = {
  "--primary": "#4F3084",
  "--nxt-btn-col": "#4F3084",
  "--complete-btn-col": "#BF1C06",
  "--btn-text-col": "#FFF",
  "--title-font": "'Gilroy-Bold', sans-serif",
  "--description-font": "'Gilroy-SemiBold', sans-serif",
  "--input-font": "'Gilroy-SemiBold', sans-serif",
  "--question-font": "Gilroy-SemiBold",
  "--btn-radius": "8px",
  "--btn-border": "#9D8FFA",
  "--row-radius": "24px",
  "--frame-radius": "12px",
  "--progress-bar-height": "4px",
  "--background": "#FFF",
  "--background-dim": "transparent",
  "--foreground": "#BF1C06", // question Text color
  "--primary-foreground": "#4F3084",
  "--background-dim-light": "#fff", // Input background
  "--sd-item-default-background": "#FFF",
  // "--number-display": "none",
  "--errbox-margin": "8px",
  "--box-shadow": "none",
  "--base-unit": "8px",
  "--frame-padding": "12px",
  "--input-border": "#DCDCDC",
  // "--banner": "none",
  "--banner-bg": "#D22F26",
  "--overflow-scroll": "hidden",
  "--rating-flex-wrap": "wrap",
  "--rating-flex-dir" : "row",
  // "--input-box-shadow": "none",
};

export const lifecellTheme = {
  "--primary": "#4F3084",
  "--nxt-btn-col": "#4F3084",
  "--complete-btn-col": "#4F3084",
  "--btn-text-col": "#FFF",
  "--title-font": "'Gilroy-Bold', sans-serif",
  "--description-font": "'Gilroy-SemiBold', sans-serif",
  "--input-font": "'Gilroy-SemiBold', sans-serif",
  "--question-font": "Gilroy-SemiBold",
  "--btn-radius": "8px",
  "--btn-border": "#9D8FFA",
  "--row-radius": "24px",
  "--frame-radius": "12px",
  "--progress-bar-height": "4px",
  "--background": "#FFF7DF",
  "--background-dim": "transparent",
  "--foreground": "#161616",
  "--primary-foreground": "#4F3084",
  "--background-dim-light": "#fff", // Input background
  "--sd-item-default-background": "#FFF",
  // "--number-display": "none",
  "--errbox-margin": "8px",
  "--box-shadow": "none",
  "--base-unit": "8px",
  "--frame-padding": "12px",

  "--input-border": "#DCDCDC",
  // "--banner": "none",
  "--banner-bg": "#FFF",
  "--overflow-scroll": "scroll",
  "--rating-flex-wrap": "no-wrap",
  "--rating-flex-dir" : "row",
  

  // "--input-box-shadow": "none",

};

export const skodaTheme = {
  "--primary": "#113D2F",
  "--nxt-btn-col": "#91CD9F",
  "--complete-btn-col": "#91CD9F",
  "--btn-text-col": "#113D2F",
  "--title-font": "skoda-bold",
  "--description-font": "'Lexend', sans-serif",
  "--input-font": "skoda-regular",
  "--question-font": "skoda-bold",
  "--btn-radius": "36px",
  "--row-radius": "24px",
  "--frame-radius": "2px",
  "--progress-bar-height": "4px",
  "--background": "#F3F3F3", // frame background
  "--foreground": "#113D2F", // question Text color
  "--input-text-color": "#000",
  "--background-dim": "#F3F3F3", // Body background
  "--background-dim-light": "#FFF", // Input background
  "--sjs-general-dim-forecolor": "#113D2F", // Titles text color
  "--banner": "none",
  "--primary-foreground": "#113D2F",
  "--banner-bg": "#91CD9F",
  "--number-display": "none",
  "--frame-padding": "2px",
  "--errbox-margin": "8px",
  "--box-shadow": "none",
  "--base-unit": "8px",
};

export const rotatryTheme = {
  "--primary": "#4F3084",
  "--nxt-btn-col": "#4F3084",
  "--complete-btn-col": "#4F3084",
  "--btn-text-col": "#FFF",
  "--title-font": "'Lexend', sans-serif",
  "--description-font": "'Lexend', sans-serif",
  "--input-font": "'Lexend', sans-serif",
  "--question-font": "rota-bold",
  "--btn-radius": "24px",
  "--row-radius": "24px",
  "--frame-radius": "12px",
  "--progress-bar-height": "4px",
  "--background": "transparent",
  "--background-dim": "#ceddff",
  "--foreground": "#161616",

  "--background-dim-light": "#FFF", // Input background
  "--banner": "none",
  "--banner-bg": "#1E59C5",
  "--number-display": "none",
  "--frame-padding": "2px",
  "--errbox-margin": "8px",
  "--box-shadow": "none",
  "--base-unit": "8px",
};