const contactCreation = (results) => {
  const contactVar = {
    "objects": [{
      "email": results.pocEmail,
      "phone_number": results.pocMobile,
     "details": { "phone": results.pocMobile, "email": results.pocEmail, "designation":results.pocDesignation},
      "username": results.pocName,
     "role": "merchant", 
      "roles": { "roles": ["merchant"] },
      "password": "123456",
      "merchant_accounts": {
        "data": 
        {
          "merchant_id": results.merchantToAddContact
        }
      }         
    }]
  };
  return contactVar;
};

export default contactCreation;
