export const department = [
    "Administration",
    "Branding",
    "Customer Service",
    "Digital Marketing",
    "Ecommerce",
    "Finance",
    "Human Resources (HR)",
    "Information Technology (IT)",
    "Legal",
    "Marketing",
    "Operations",
    "Production/Manufacturing",
    "Public Relations (PR)",
    "Research and Development (R&D)",
    "Sales",
    "Supply Chain/Logistics"]

export const Designations = [
    "Director",
    "Chief Executive",
    "Vice President",
    "Head of Department",
    "Regional Head",
    "Senior Role",
    "Manager",
    "Specialist",
    "Team Leader",
    "Associate",
    "Representative",
    "Designer",
    "Developer",
    "Analyst",
]